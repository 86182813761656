import { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  checkUserEmail,
  registerSecret,
  emailConfirmation,
  userLogin,
} from '../utils/axiosRequests';
import FormRow from '../components/FormRow';
import { useGlobalContext } from '../context/global_context';
import { useAuthContext } from '../context/auth_context';

const Verify = ({ location, idAndEmailSaved, password }) => {
  const [loading, setLoading] = useState(false);
  const [credentials, setCredentials] = useState({
    secret: '',
    email: idAndEmailSaved.email,
  });
  const [expiration, setExpiration] = useState(true);
  const [counter, setCounter] = useState(0);
  const [firstMsg, setFirstMsg] = useState(true);
  const [submitType, setSubmitType] = useState('newCode');

  const { saveUser } = useAuthContext();

  const { alert, showAlert, hideAlert } = useGlobalContext();
  let navigate = useNavigate();

  const handleChange = (e) => {
    setCredentials((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleNewCode = () => {
    setExpiration(true);
    setSubmitType('newCode');
  };

  const getSecret = async () => {
    setLoading(true);

    // check changed email
    if (idAndEmailSaved.email !== credentials.email) {
      const checkUser = await checkUserEmail({ email: credentials.email });
      if (checkUser.status === 'error') {
        showAlert({ text: checkUser.msg });
        setLoading(false);
        return;
      }
    }

    const registerSecretResult = await registerSecret({
      id: idAndEmailSaved.id,
    });

    if (registerSecretResult.status === 'error') {
      showAlert({ text: registerSecretResult.msg });
      setLoading(false);
      return;
    }

    setExpiration(false);
    setCounter(60 * 5);
    setSubmitType('sendCode');
    setFirstMsg(false);
    setLoading(false);
    return;
  };

  useEffect(() => {
    let timer;
    if (counter > 0) {
      timer = setInterval(() => setCounter(counter - 1), 1000);
    }
    if (counter === 0 && !firstMsg) {
      handleNewCode();
    }
    return () => clearInterval(timer);
  }, [counter]);

  useEffect(() => {
    let cancel = false;
    hideAlert();
    return () => (cancel = true);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (submitType === 'newCode') {
      return getSecret();
    }

    setLoading(true);

    if (submitType === 'sendCode') {
      const { secret, email } = credentials;

      if (!secret) return setLoading(false);

      const resultConfirmation = await emailConfirmation({
        secret,
        email,
      });

      if (resultConfirmation.status === 'error') {
        setLoading(false);
        showAlert({
          text: resultConfirmation.msg,
        });
        return;
      }

      setCredentials((prev) => {
        return {
          ...prev,
          secret: '',
        };
      });

      // automatic login
      const resultLogin = await userLogin({
        email,
        password,
      });

      if (resultLogin.status === 'error') {
        showAlert({ text: resultLogin.msg });
        setLoading(false);
        return;
      }

      saveUser(resultLogin);
      setLoading(false);
      if (location !== 'payment') {
        return navigate(`/private/meusmoldes`);
      }
      return;
    }
  };

  return (
    <Wrapper>
      <form
        className={loading ? 'form form-loading' : 'form'}
        onSubmit={handleSubmit}
      >
        <h2>Confirmar cadastro</h2>
        {!expiration ? (
          <>
            <p>
              O código expira em - <span>0{Math.floor(counter / 60)}</span>:
              <span>
                {Math.floor(counter % 60) < 10 && 0}
                {Math.floor(counter % 60)}
              </span>
            </p>
            <p className="info-cod-email">
              Insira o código que enviamos no seu email.
            </p>
            <FormRow
              type="text"
              name="secret"
              value={credentials.secret}
              handleChange={handleChange}
            />
          </>
        ) : (
          <>
            <p className='text-info'>
              {firstMsg
                ? 'Verifique se o email está correto. Enviaremos um código para confirmar sua conta.'
                : 'Código expirado. Verifique se o email está correto para enviarmos outro código.'}
            </p>
            <FormRow
              type="email"
              name="email"
              value={credentials.email}
              handleChange={handleChange}
            />
          </>
        )}
        <div className='btn-container'>
          <button type="submit" className="btn auth-btn" disabled={loading}>
            {loading ? (
              'Aguarde...'
            ) : (
              <>{submitType === 'sendCode' ? 'Confirmar' : 'Enviar Código'}</>
            )}
          </button>
        </div>
        <p className='auth-options'>
          Não recebeu o código?
          <button
            type="button"
            className="new-code btnResetPassword"
            onClick={() => handleNewCode()}
          >
            Solicitar outro
          </button>
        </p>
        <p className='auth-options'>
          Cancelar?
          <Link to="/">Home</Link>
        </p>
      </form>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  display: grid;
  place-items: center;

  .text-info {
    font-size: 0.9rem;
    padding-top: 1rem;
  }

  .info-cod-email {
    padding: 1rem 0;
  }

  .new-code {
    padding: 0;
    padding-left: 0.5rem;
    margin: 0;
  }
`;

export default Verify;
