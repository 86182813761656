import styled from 'styled-components';
import { FaTimesCircle } from 'react-icons/fa';
import { useState } from 'react';
import { translateStatus } from '../utils/functions';
import { getOrdersByEmail, getOrderById } from '../utils/axiosRequests';

const AdminPaymentModal = ({ setModal, info, getUserOrders }) => {
  const { flag, title } = info;
  const [idStatus, setIdStatus] = useState('');
  const [email, setEmail] = useState('');
  const [msg, setMsg] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    if (flag === 'status') setIdStatus(e.target.value);
    if (flag === 'client') setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (idStatus) {
      const orderByIdResult = await getOrderById(idStatus);

      if (orderByIdResult.status === 'error') {
        setMsg(orderByIdResult.msg);
        setLoading(false);
        return;
      }
      setLoading(false);

      setMsg(translateStatus(orderByIdResult.status));
      return;
    }

    if (email) {
      const ordersByEmailResult = await getOrdersByEmail(email);

      if (ordersByEmailResult.status === 'error') {
        setMsg(ordersByEmailResult.msg);
        setLoading(false);
        return;
      }
      setLoading(false);
      getUserOrders(ordersByEmailResult.orders);
      setMsg(
        `Foram encontrado ${ordersByEmailResult.count} pedidos deste cliente.`
      );

      return;
    }

    setMsg(
      flag === 'status'
        ? 'Digite o id do pedido.'
        : 'Digite o email do cliente.'
    );
    return;
  };

  return (
    <Wrapper className="modal-container">
      <div className="modal-content">
        <div className="title-container">
          <div>
            <h3 >{title}</h3>
          </div>
          <div>
            <button className="btn btn-close" onClick={() => setModal(false)}>
              <FaTimesCircle />
            </button>
          </div>
        </div>
        <div className="modal-main">
          <div className="status-input-container">
            <input
              className="status-input"
              type={flag === 'status' ? 'text' : 'email'}
              value={flag === 'status' ? idStatus : email}
              onChange={handleChange}
              placeholder={flag === 'status' ? 'id' : 'email'}
            />

            {loading ? (
              <p>Aguarde... </p>
            ) : (
              <button
                type="submit"
                className="btn status-btn"
                onClick={handleSubmit}
              >
                Ver
              </button>
            )}
          </div>
          {msg && <p className="msg-status">{msg}</p>}
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .modal-content {
    width: 35%;
    min-height: 30%;
    background: var(--primary-400);
    padding: 2rem;
  }

  .btn-close {
    padding: 0.3rem;
    display: grid;
    place-items: center;
  }

  .title-container {
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
  }

  .modal-main {
    padding: 3rem 0;
  }

  .status-input {
    border: none;
    outline: none;
    background: var(--primary-200);
    padding: 0.6rem;
    width: 18rem;
  }

  .status-input-container {
    display: grid;
    grid-template-columns: 3fr 1fr;
    place-items: center;
  }

  .status-btn {
    padding: 0.5rem 1rem;
    font-size: 1rem;
  }

  .msg-status {
    max-width: 90%;
    text-align: center;
    text-transform: capitalize;
    font-weight: bold;
    background: var(--grey-400);
    padding: 0.5rem 1rem;
    margin: 0 auto;
    margin-top: 1rem;
  }
`;

export default AdminPaymentModal;
