import styled from 'styled-components';
import { useEffect, useState } from 'react';
import Loading from './Loading';
import { HiOutlineIdentification } from 'react-icons/hi';
import {
  FaUserAlt,
  FaCheckCircle,
  FaDotCircle,
  FaTimesCircle,
  FaChevronUp,
  FaChevronDown,
} from 'react-icons/fa';
import AdminPaymentModal from './AdminPaymentModal';
import { translateStatus } from '../utils/functions';
import { getOrdersByDate } from '../utils/axiosRequests';

const AdminOrders = () => {
  const defaultDateState = { firstDate: '', lastDate: '' };
  const defaultBtnControl = {
    approved: false,
    pending: false,
    rejected: false,
  };
  const [dates, setDates] = useState(defaultDateState);
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [quantityApproved, setQuantityApproved] = useState(0);
  const [total, setTotal] = useState(0);
  const [modal, setModal] = useState(false);
  const [info, setInfo] = useState({ flag: '', title: '' });
  const [filter, setFilter] = useState('');
  const [controlBtn, setControlBtn] = useState(defaultBtnControl);
  const [msg, setMsg] = useState('');

  const handleChange = (e) => {
    setDates((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const calculateTotalApproved = (data) => {
    let num = 0;
    const total = data
      .map((order) => {
        if (order.status === 'approved') {
          num += 1;
          return order.total;
        }
        return 0;
      })
      .reduce((acc, cur) => (acc += cur), 0);

    return { total, num };
  };

  const handleModalClick = (flag, title) => {
    setModal(true);
    setInfo({ flag, title });
  };

  const handleDetail = (id) => {
    const newFilteredOrders = filteredOrders.map((order) => {
      if (order._id === id) {
        order.details = !order.details;
      }

      return order;
    });
    setFilteredOrders(newFilteredOrders);
  };

  const handleControlClick = (status) => {
    if (status !== 'approved') {
      setQuantityApproved(0);
      setTotal(0);
    }

    let newState = { ...defaultBtnControl, [status]: !controlBtn[status] };

    setControlBtn(newState);
    if (newState[status]) {
      setFilter(status);
      return;
    } else {
      setFilter('');
      return;
    }
  };

  const getUserOrders = (orders) => {
    setDates(defaultDateState);
    setOrders(orders);
  };

  const fetchOrder = async () => {
    const fetchOrderResult = await getOrdersByDate(
      dates.firstDate,
      dates.lastDate
    );

    if (fetchOrderResult.status === 'error') {
      console.log(fetchOrderResult.msg);
      setMsg(fetchOrderResult.msg);
      setLoading(false);
      return;
    }

    const orderDisplayInfo = fetchOrderResult.orders.map((order) => {
      return { ...order, details: false };
    });

    setFilteredOrders(orderDisplayInfo);
    setOrders(orderDisplayInfo);
    const { total, num } = calculateTotalApproved(fetchOrderResult.orders);
    setQuantityApproved(num);
    setTotal(total);
    setLoading(false);
  };

  useEffect(() => {
    if (dates.firstDate && dates.lastDate) {
      setLoading(true);
      fetchOrder();
    }
  }, [dates]);

  useEffect(() => {
    if (filter) {
      const filtered = orders.filter((order) => {
        if (filter === 'rejected' && order.status === 'cancelled') return order;
        if (order.status === filter) return order;
      });
      setFilteredOrders(filtered);
      const { total, num } = calculateTotalApproved(filtered);
      setQuantityApproved(num);
      setTotal(total);
      return;
    } else {
      const { total, num } = calculateTotalApproved(orders);
      setQuantityApproved(num);
      setTotal(total);
      setFilteredOrders(orders);
      return;
    }
  }, [filter]);

  if (loading) {
    return <Loading />;
  }

  return (
    <Wrapper>
      <div className="nav-container">
        <div className="date-container">
          <div className="first-date">
            <label htmlFor="firstDate">De: </label>
            <div className="input">
              <input
                type="date"
                name="firstDate"
                id="firstDate"
                value={dates.firstDate}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="last-date">
            <label htmlFor="lastDate">Até: </label>
            <div className="input">
              <input
                type="date"
                name="lastDate"
                id="lastDate"
                value={dates.lastDate}
                onChange={handleChange}
              />
            </div>
          </div>

          <p>
            Qtde.: <span>{filteredOrders.length}</span>
          </p>

          <p>
            Qtde. Apr.: <span>{quantityApproved}</span>
          </p>

          <p>
            Total Ap.: <span>{total}</span>
          </p>
        </div>
        <div className="btn-controls">
          <button
            className="btn btn-control get-status-btn"
            onClick={() =>
              handleModalClick('status', 'Conferir status de Pagamento')
            }
          >
            <HiOutlineIdentification />
          </button>
          <button
            className="btn btn-control get-user-pays-btn"
            onClick={() =>
              handleModalClick(
                'client',
                'Buscar pedidos de um cliente especifico'
              )
            }
          >
            <FaUserAlt />
          </button>
          <button
            className={`btn btn-control approved ${controlBtn.approved && 'activeBtnCtrl'
              }`}
            onClick={() => handleControlClick('approved')}
          >
            <FaCheckCircle />
          </button>
          <button
            className={`btn btn-control pending ${controlBtn.pending && 'activeBtnCtrl'
              }`}
            onClick={() => handleControlClick('pending')}
          >
            <FaDotCircle />
          </button>
          <button
            className={`btn btn-control rejected ${controlBtn.rejected && 'activeBtnCtrl'
              }`}
            onClick={() => handleControlClick('rejected')}
          >
            <FaTimesCircle />
          </button>
        </div>
      </div>

      {msg && msg}

      {filteredOrders.length !== 0 && (
        <div className="orders-container">
          {filteredOrders.map((order, i) => {
            const {
              _id: id,
              orderItems,
              paymentId,
              status,
              ticket_url,
              createdAt,
              total,
              user,
              date_approved,
              details,
            } = order;

            const dateCreated = createdAt.split('T');

            return (
              <div
                className={details ? 'order-card' : 'order-card fixed-height'}
                key={i}
              >
                <div className="card-title">
                  <h3>Id: {paymentId}</h3>
                  {ticket_url && <p className="pix-flag">pix</p>}
                </div>
                <p>
                  Status:{' '}
                  <span className="status">{translateStatus(status)}</span>
                </p>
                <p>Nome: {user.name}</p>
                <p>Email: {user.email}</p>
                <div className="dates">
                  <p>Criado: {dateCreated[0]}</p>
                  <p>
                    Aprov.:{' '}
                    {date_approved ? date_approved.split('T')[0] : 'aguardando'}
                  </p>
                </div>
                <div className="external-link">
                  <p className="total-card">Total: {total}</p>
                  <p className="toggle-container">
                    Moldes{' '}
                    <span
                      className="toggle-btn"
                      onClick={() => handleDetail(id)}
                    >
                      {details ? <FaChevronUp /> : <FaChevronDown />}
                    </span>
                  </p>
                </div>
                {details && (
                  <ul className="info">
                    {orderItems.map((item, index) => {
                      const { productName, price, productId } = item;
                      return (
                        <li key={index} className="info-item-container">
                          <p className="id-info">
                            Id: <span>{productId}</span>
                          </p>
                          <div className="li-info">
                            <p className="item-name">Nome: {productName}</p>
                            <p>Preço: {price}</p>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
            );
          })}
        </div>
      )}

      {modal && (
        <AdminPaymentModal
          setModal={setModal}
          info={info}
          getUserOrders={getUserOrders}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.section`
min-height: calc(100vh - 15.7rem);

  .nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .date-container {
    align-items: center;
    width: 70%;
    display: flex;
    justify-content: space-between;
  }

  .first-date,
  .last-date {
    display: flex;
    align-items: center;
  }

  .date-container input {
    outline: none;
    background: var(--primary-3);
    border: none;

    padding: 0.3rem;
    font-size: 0.8rem;
  }

  .input {
    margin: 1rem 0;
  }

  .date-container label {
    margin-right: 1rem;
  }

  .orders-container {
    margin: 2rem 0;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }

  .order-card {
    border: 1px dashed var(--primary-400);
    background: var(--primary-900);
    padding: 1rem;
  }

  .card-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
  }

  .order-card h3 {
    font-size: 1rem;
  }

  .order-card p:not(.total-card) {
    font-size: 0.7rem;
  }

  .pix-flag {
    color: var(--primary-700);
  }

  .status {
    font-weight: bold;
  }

  .btn-controls {
    display: flex;
    align-items: center;
  }

  .btn-control {
    display: grid;
    place-items: center;
    padding: 0.5rem;
    margin-left: 0.3rem;
  }

  .activeBtnCtrl {
    background: green;
  }

  .get-status-btn {
  }

  .external-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .total-card {
    font-size: 0.9rem;
  }

  .fixed-height {
    height: 180px;
  }

  .dates,
  .li-info {
    display: flex;
    justify-content: space-between;
  }

  .info {
    width: 100%;
    background: var(--primary-300);
  }

  .info-item-container {
    margin: 0.5rem;
    padding: 0.3rem;
  }

  .item-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 160px;
  }

  .toggle-container,
  .toggle-btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .toggle-btn {
    width: 15px;
    height: 15px;
    margin-left: 0.5rem;
    cursor: pointer;
  }

  .toggle-btn:hover {
    color: var(--primary-700);
  }

  .id-info {
    font-weight: bold;
  }
`;

export default AdminOrders;
