import styled from 'styled-components';
import { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const FormRow = ({ type, name, value, handleChange }) => {
  const [eye, setEye] = useState(false);

  const translateLabel = (name) => {
    if (name === 'name') {
      return 'nome';
    }
    if (name === 'secret') {
      return 'código';
    }
    if (name === 'password') {
      return 'senha';
    } else {
      return name;
    }
  };

  return (
    <Wrapper>
      <div className={type === 'password' ? 'eye-reference input-container' : 'input-container'}>
        <label htmlFor={name} className="form-label">
          {translateLabel(name)}
        </label>

        <input
          type={type === 'password' ? (eye ? 'text' : 'password') : type}
          value={value}
          name={name}
          id={name}
          required
          onChange={handleChange}
        />
        {type === 'password' && (
          <button className="eye" type="button" onClick={() => setEye(!eye)}>
            {eye ? <FaEyeSlash /> : <FaEye />}
          </button>
        )}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .input-container {
    padding: 1rem 0;
    font-size: 1rem;
  }

  .input-container input {
    width: 80%;
  }

  .eye-reference {
    position: relative;
  }

  .eye {
    position: absolute;
    top: 50%;
    transform: translateY(-60%);
    right: 1rem;
    width: 1.5rem;
    height: 1.5rem;
    display: grid;
    place-items: center;
    background: transparent;
    border: none;
    cursor: pointer;
  }

  @media screen and (min-width: 550px) {
    .login-btn {
      padding: 0.6rem 1.6rem;
      font-size: 1.2rem;
    }

    .eye {
      right: 10%;
    }
  }

  @media screen and (min-width: 790px) {
    .input-container {
      font-size: 1.2rem;
    }

    .eye {
      font-size: 1rem;
    }
  }
`;

export default FormRow;
