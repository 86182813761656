import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { userLogin } from '../utils/axiosRequests';
import styled from 'styled-components';
import { FormRow } from '../components';
import { useGlobalContext } from '../context/global_context';
import { useAuthContext } from '../context/auth_context';
import { Link } from 'react-router-dom';
import Verify from './Verify';

function Login({ location }) {
  const [values, setValues] = useState({
    email: '',
    password: '',
  });
  const [loading, setLoading] = useState(false);
  const { saveUser } = useAuthContext();
  const [notVerified, setNotVerified] = useState(false);
  const [idAndEmailSaved, setIdAndEmailSaved] = useState({ id: '', email: '' });
  const {
    alert,
    showAlert,
    hideAlert,
    resetPasswordInPaymentPage,
  } = useGlobalContext();
  let navigate = useNavigate();

  const navigateAfterLogin = (role) => {
    if (location !== 'payment') {
      if (role === 'superuser') {
        return navigate(`/private/admin`);
      } else {
        return navigate(`/private/meusmoldes`);
      }
    }
    return;
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  async function loginRequest() {
    setLoading(true);
    const { email, password } = values;
    const userCredential = { email, password };

    const loginResult = await userLogin(userCredential);
    if (loginResult.status === 'error') {
      showAlert({ text: loginResult.msg });
      setLoading(false);
      return;
    }

    if (!loginResult.verified) {
      setIdAndEmailSaved({ email: loginResult.email, id: loginResult.userId });
      setNotVerified(true);
      setLoading(false);
      return;
    }
    setLoading(false);

    setValues({ email: '', password: '' });
    showAlert({
      text: `Bem vindo, ${loginResult.name}. Aguarde, redirecionando...`,
      type: 'success',
    });
    saveUser(loginResult);
    navigateAfterLogin(loginResult.role);
    return;
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    await loginRequest();
    return;
  };

  useEffect(() => {
    let cancel = false;
    hideAlert();
    return () => (cancel = true);
  }, []);

  return (
    <Wrapper className={location !== 'payment' ? "page-height" : null}>

      {location !== 'payment' && alert.show && (
        <div className={`alert alert-${alert.type}`}>{alert.text}</div>
      )}

      {notVerified ? (
        <Verify
          idAndEmailSaved={idAndEmailSaved}
          password={values.password}
          location={location}
        />
      ) : (
        <form
          className={loading ? 'form form-loading' : 'form'}
          onSubmit={onSubmit}
        >
          <h2>Logar</h2>
          <FormRow
            type="email"
            name="email"
            value={values.email}
            handleChange={handleChange}
          />
          <FormRow
            type="password"
            name="password"
            value={values.password}
            handleChange={handleChange}
          />
          <div className='btn-container'>
            <button type="submit" className="btn auth-btn" disabled={loading}>
              {loading ? 'Loading...' : 'Login'}
            </button>
          </div>
          {location !== 'payment' && (
            <>
              <p className='auth-options'>
                Ainda não tem uma conta?
                <Link to="/cadastrar">Cadastre-se</Link>
              </p>
              <p className='auth-options'>
                Cancelar?
                <Link to="/">Home</Link>
              </p>
            </>
          )}
          <p className='auth-options'>
            Esqueçeu sua senha?
            {location !== 'payment' ? (

              <Link to="/recuperar-senha">Redefinir senha</Link>
            ) : (
              <button
                type="button"
                className="btnResetPassword"
                onClick={() => resetPasswordInPaymentPage(true)}
              >
                Redefinir senha
              </button>
            )}
          </p>
        </form>
      )}

    </Wrapper>
  );
}

const Wrapper = styled.section`
  display: grid;
  place-items: center;

  .page-fit{
    width: 100%;
  }

  .btn:disabled {
    cursor: not-allowed;
  }

  .btnResetPassword{
    margin-left: 0.5rem
  }
`;

export default Login;
