import styled from 'styled-components';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { AdminOrders, AdminProducts, AdminUsers } from '../components';
import { FaHome } from 'react-icons/fa';

const Admin = () => {
  const displayList = [
    { type: 'products', component: <AdminProducts key="1" /> },
    { type: 'orders', component: <AdminOrders key="2" /> },
    { type: 'users', component: <AdminUsers key="3" /> },
  ];

  const [activeBtn, setActiveBtn] = useState('products');

  return (
    <Wrapper>
      <nav className="nav-container">
        <div className="title">Painel do Administrador</div>
        <div className="controllers">
          <Link to="/" className="btn home-link">
            <FaHome />
          </Link>
          <button
            type="button"
            className={`btn ${activeBtn === 'products' && 'activeBtn'}`}
            onClick={() => setActiveBtn('products')}
          >
            Moldes
          </button>
          <button
            type="button"
            className={`btn ${activeBtn === 'orders' && 'activeBtn'}`}
            onClick={() => setActiveBtn('orders')}
          >
            Pedidos
          </button>
          <button
            type="button"
            className={`btn ${activeBtn === 'users' && 'activeBtn'}`}
            onClick={() => setActiveBtn('users')}
          >
            Usuários
          </button>
        </div>
      </nav>
      <main>
        {displayList.map(({ type, component }) => {
          if (type === activeBtn) {
            return component;
          }
        })}
      </main>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .nav-container {
    margin: 0 auto;
    padding: 2rem 0;
    width: 90vw;
    max-width: 1300px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .home-link {
    margin-right: 0.5rem;
    padding: 0 1rem;
    display: grid;
    place-items: center;
  }

  .title {
    font-size: 1.5rem;
    text-transform: uppercase;
    letter-spacing: var(--letterSpacing)
  }

  .controllers {
    width: 35vw;
    display: flex;
    justify-content: space-between;
  }

  .activeBtn {
    background: var(--primary-700);
  }

  main {
    margin-top: 2rem;
  }
`;

export default Admin;
