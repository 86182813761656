import { BrowserRouter, Routes, Route } from 'react-router-dom';

import {
  SharedLayoutGlobal,
  SharedServicesLayout,
  SharedProductLayout,
  SharedLayoutAdmin,
  Home,
  PrivateRoute,
  Error,
  Login,
  Register,
  Verify,
  ForgotPassword,
  ResetPassword,
  Admin,
  AdminMolde,
  MeusMoldes,
  FreePatterns,
  SingleProduct,
  ServicesMake,
  ServicesDigital,
  Products,
  About,
  CheckOut,
  SharedLayoutMoldes,
  Download,
  UserConfig,
} from './pages';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<SharedLayoutGlobal />}>
          <Route index element={<Home />} />

          <Route path="private" element={<PrivateRoute />}>
            <Route path="meusMoldes" element={<SharedLayoutMoldes />}>
              <Route index element={<MeusMoldes />} />
              <Route path="download" element={<Download />} />
              <Route path="config" element={<UserConfig />} />
            </Route>

            <Route path="admin" element={<SharedLayoutAdmin />}>
              <Route index element={<Admin />} />
              <Route path="molde/:id" element={<AdminMolde />} />
            </Route>
          </Route>

          <Route path="services" element={<SharedServicesLayout />}>
            <Route index element={<FreePatterns />} />
            <Route path="pronta" element={<SharedProductLayout />}>
              <Route index element={<Products />} />
              <Route path=":productId" element={<SingleProduct />} />
            </Route>
            <Route path="encomenda" element={<ServicesMake />} />
            <Route path="digitalizar" element={<ServicesDigital />} />
          </Route>

          <Route path="sacola" element={<CheckOut />} />
          <Route path="sobre" element={<About />} />
          <Route path="logar" element={<Login />} />
          <Route path="cadastrar" element={<Register />} />
          <Route path="recuperar-senha" element={<ForgotPassword />} />
          <Route path="user/verify-email" element={<Verify />} />
          <Route path="user/reset-password" element={<ResetPassword />} />

          <Route path="*" element={<Error />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
