import axios from 'axios';

axios.defaults.withCredentials = true;

const baseApiUrl = 'https://server.moldee.com.br/api/v1';
//const baseApiUrl = 'http://localhost:5000/api/v1';
// const baseApiUrl = `${process.env.BASE_API_URL}`;
const baseClientURL = 'https://moldee.com.br';

// const baseClientURL = 'http://localhost:3000';

// ******** SUBCATEGORIES ********
const createSubCategory = async (subcategory) => {
  try {
    const { data } = await axios.post(`${baseApiUrl}/products/subcategory`, subcategory);
    return data.subCategory;
  } catch (error) {
    return {
      status: 'error',
      msg: 'Não foi possível criar a subcategoria. Recarregue a página para tentar novamente.'
    }
  }
}

const getSubCategories = async () => {
  try {
    const { data } = await axios.get(`${baseApiUrl}/products/subcategory`)
    return data.subCategories
  } catch (error) {
    return {
      status: 'error',
      msg: 'Não foi possível carregar as subcategorias. Recarregue a página.'
    }
  }
}

const updateSubCategory = async (id, content) => {
  try {
    const { data } = await axios.put(`${baseApiUrl}/products/subcategory/${id}`, content)
    return data
  } catch (error) {
    return {
      status: 'error',
      msg: 'Não foi possível editar a subcategoria. Recarregue a página para tentar novamente.'
    }
  }
}

const deleteSubCategory = async (id) => {
  try {
    const { data } = await axios.delete(`${baseApiUrl}/products/subcategory/${id}`)
    return data.success
  } catch (error) {
    return {
      status: 'error',
      msg: 'Não foi possível deletar a subcategoria. Recarregue a página para tentar novamente.'
    }
  }
}

//  *******  PRODUCTS  *********
const getAllProducts = async () => {
  try {
    const { data } = await axios.get(
      `${baseApiUrl}/products`
    );
    return data.products;
  } catch (error) {
    return {
      status: 'error',
      msg: 'Ocorreu um erro. Tente recarregar a página.',
    };
  }
};

const fetchSingleProduct = async (id) => {
  try {
    const { data } = await axios.get(`${baseApiUrl}/products/${id}`);
    return data.product;
  } catch (error) {
    return {
      status: 'error',
      msg: 'Não foi possível carregar o produto.'
    };
  }
};

const fetchFreePatterns = async (id) => {
  try {
    const { data } = await axios.get(`${baseApiUrl}/products/free`);
    return data.products;
  } catch (error) {
    console.log(error);
    return {
      status: 'error'
    };
  }
};

const fetchFeaturedPatterns = async (id) => {
  try {
    const { data } = await axios.get(`${baseApiUrl}/products/featured`);
    return data.products;
  } catch (error) {
    console.log(error);
    return {
      status: 'error'
    };
  }
};

const fetchSingleProductAdmin = async (id) => {
  try {
    const { data } = await axios.get(`${baseApiUrl}/products/admin/${id}`);
    return data;
  } catch (error) {
    return {
      status: 'error',
      msg: 'Não foi possível carregar a produto para atualização. Volte à página anterior para tentar novamente.'
    }
  }
};

const uploadFile = async (formData) => {
  try {
    const { data } = await axios.post(
      `${baseApiUrl}/products/upload`,
      formData
    );

    return data;
  } catch (error) {
    console.log(error);
  }
};

const checkExistProduct = async (productName, category, subCategory, id, action) => {
  try {
    const { data } = await axios.post(`${baseApiUrl}/products/check`, {
      productName,
      category,
      subCategory,
      id,
      action,
    });
    return data;
  } catch (error) {
    return {
      status: 'error',
      msg: error.response.data.msg,
    };
  }
};

const createProduct = async (newProduct) => {
  try {
    const { data } = await axios.post(`${baseApiUrl}/products`, newProduct);
    return data
  } catch (error) {
    return {
      status: 'error',
      msg: error.response.data.msg,
    };
  }
};

const updateProduct = async (id, newProduct) => {
  try {
    const { data } = await axios.patch(
      `${baseApiUrl}/products/${id}`,
      newProduct
    );
    return { status: 'ok', msg: data.msg };
  } catch (error) {
    return {
      status: 'error',
      msg: error.response.data.msg,
    };
  }
};

const deleteProduct = async (id) => {
  try {
    const { data } = await axios.delete(`${baseApiUrl}/products/${id}`);
    return data;
  } catch (error) {
    console.log(error);
  }
};

const offProduct = async (dataOff) => {
  try {
    const { data } = await axios.post(`${baseApiUrl}/products/off`, dataOff);
    return data;
  } catch (error) {
    return {
      status: 'error',
      msg: error.response.data.msg,
    };
  }
};

const sendEmailMarketing = async (emailBody) => {
  try {
    const { data } = await axios.post(
      `${baseApiUrl}/products/emailMarketing`,
      emailBody
    );
    return data;
  } catch (error) {
    return {
      status: 'error',
      msg: error.response.data.msg,
    };
  }
};

//  *******  USER  *********
const fetchUser = async () => {
  try {
    const { data } = await axios.get(`${baseApiUrl}/users/getCurrentUser`);
    return data.user;
  } catch (error) {
    return {
      status: 'error',
      msg: 'Nenhum usuário logado.',
    };
  }
};

const fetchSingleUser = async (id) => {
  try {
    const { data } = await axios.get(`${baseApiUrl}/users/${id}`);
    return data.user;
  } catch (error) {
    return {
      status: 'error',
      msg: error.response.data.msg,
    };
  }
};

const fetchUsers = async () => {
  try {
    const { data } = await axios.get(`${baseApiUrl}/users`);
    return data.users;
  } catch (error) {
    return {
      status: 'error',
      msg: error.response.data.msg,
    };
  }
};

const checkUserEmail = async (email) => {
  try {
    const { data } = await axios.post(
      `${baseApiUrl}/auth/checkUserEmail`,
      email
    );
    return {
      status: 'OK',
      data,
    };
  } catch (error) {
    return {
      status: 'error',
      msg: error.response.data.msg,
    };
  }
};

const registerUser = async (user) => {
  try {
    const { data } = await axios.post(`${baseApiUrl}/auth/register`, user);
    return data;
  } catch (error) {
    return {
      status: 'error',
      msg: error.response.data.msg,
    };
  }
};

const registerSecret = async (user) => {
  try {
    const { data } = await axios.post(
      `${baseApiUrl}/auth/register/reset-secret`,
      user
    );
    return { status: 'ok', data };
  } catch (error) {
    return {
      status: 'error',
      msg: error.response.data.msg,
    };
  }
};

const emailConfirmation = async (user) => {
  try {
    const { data } = await axios.post(
      `${baseApiUrl}/auth/register/confirmation`,
      user
    );
    return { status: 'ok', data };
  } catch (error) {
    return {
      status: 'error',
      msg: error.response.data.msg,
    };
  }
};

const userLogin = async (user) => {
  try {
    const { data } = await axios.post(`${baseApiUrl}/auth/login`, user);
    return data.user;
  } catch (error) {
    return {
      status: 'error',
      msg: error.response.data.msg,
    };
  }
};

const forgotPassword = async (email) => {
  try {
    const { data } = await axios.post(`${baseApiUrl}/auth/forgot-password`, {
      email,
    });
    return data;
  } catch (error) {
    return {
      status: 'error',
      msg: error.response.data.msg,
    };
  }
};

const resetPassword = async (user) => {
  try {
    const { data } = await axios.post(
      `${baseApiUrl}/auth/reset-password`,
      user
    );
    return data;
  } catch (error) {
    return {
      status: 'error',
      msg: error.response.data.msg,
    };
  }
};

const logoutUserAPI = async () => {
  try {
    const { data } = await axios.delete(`${baseApiUrl}/auth/logout`);
    return data;
  } catch (error) {
    return {
      status: 'error',
      msg: error.response.data.msg,
    };
  }
};

const updateUser = async (id, user) => {
  try {
    const { data } = await axios.patch(`${baseApiUrl}/users/${id}`, user);
    return data;
  } catch (error) {
    return {
      status: 'error',
      msg: error.response.data.msg,
    };
  }
};

const getSingleProduct = async (id) => {
  try {
    const { data } = await axios.get(`${baseApiUrl}/products/${id}`);
    return data;
  } catch (error) {
    return {
      status: 'error',
      msg: 'Ocorreu um erro. Tente recarregar a página.',
    };
  }
};

const getCart = async () => {
  try {
    const { data } = await axios.get(`${baseApiUrl}/users/getCart`);
    return data;
  } catch (error) {
    return {
      status: 'error',
      msg: error.response.data.msg,
    };
  }
};

const saveCart = async (newBag) => {
  try {
    const { data } = await axios.post(`${baseApiUrl}/users/saveCart`, {
      cart: newBag,
    });
    return data;
  } catch (error) {
    return {
      status: 'error',
      msg: error.response.data.msg,
    };
  }
};

//  *******  ORDERS  *********
const earnPattern = async (productId) => {
  try {
    const { data } = await axios.post(`${baseApiUrl}/orders/earn`, productId);
    return data;
  } catch (error) {
    return {
      status: 'error',
      msg: error.response.data.msg,
    };
  }
}


const cardOrder = async (formData) => {
  try {
    const { data } = await axios.post(`${baseApiUrl}/orders`, formData);
    return data;
  } catch (error) {
    return {
      status: 'error',
      msg: error.response.data.msg,
    };
  }
};

const pixTransfer = async (newPix) => {
  try {
    const { data } = await axios.post(`${baseApiUrl}/orders/pix`, newPix);
    return { status: 'ok', data };
  } catch (error) {
    return {
      status: 'error',
      msg: error.response.data.msg,
    };
  }
};

const pixCheck = async (checkPixUrl) => {
  try {
    const { data } = await axios.post(`${baseApiUrl}/orders/pix/check`, {
      orderId: checkPixUrl,
    });
    return { status: 'ok', data };
  } catch (error) {
    return {
      status: 'error',
      msg: error.response.data.msg,
    };
  }
};

const getOrdersByDate = async (firstDate, lastDate) => {
  try {
    const { data } = await axios.get(
      `${baseApiUrl}/orders/monthly?firstDate=${firstDate}T00:00:00.000Z&lastDate=${lastDate}T23:59:59.000Z`
    );
    return data;
  } catch (error) {
    return {
      status: 'error',
      msg: error.response.data.msg,
    };
  }
};

const getOrdersByEmail = async (email) => {
  try {
    const { data } = await axios.get(`${baseApiUrl}/orders?email=${email}`);
    return data;
  } catch (error) {
    return {
      status: 'error',
      msg: error.response.data.msg,
    };
  }
};

const getOrderById = async (id) => {
  try {
    const { data } = await axios.get(`${baseApiUrl}/orders/status/${id}`);
    return data;
  } catch (error) {
    return {
      status: 'error',
      msg: error.response.data.msg,
    };
  }
};

//  *******  MY PRODUCTS  *********
const fetchMyProducts = async (id) => {
  try {
    const { data } = await axios.get(`${baseApiUrl}/users/myProducts/${id}`);
    return data;
  } catch (error) {
    return {
      status: 'error',
      msg: error.response.data.msg,
    };
  }
};

const fetchPatterns = async (patterns) => {
  try {
    const { data } = await axios.post(`${baseApiUrl}/users/myPatterns`, {
      patterns,
    });
    return data;
  } catch (error) {
    return {
      status: 'error',
      msg: error.response.data.msg,
    };
  }
};

export {
  baseClientURL,
  createSubCategory,
  getSubCategories,
  updateSubCategory,
  deleteSubCategory,
  getAllProducts,
  fetchSingleUser,
  fetchUsers,
  fetchFreePatterns,
  fetchFeaturedPatterns,
  deleteProduct,
  offProduct,
  fetchSingleProduct,
  fetchSingleProductAdmin,
  uploadFile,
  checkExistProduct,
  updateProduct,
  createProduct,
  sendEmailMarketing,
  fetchUser,
  logoutUserAPI,
  registerUser,
  checkUserEmail,
  registerSecret,
  emailConfirmation,
  forgotPassword,
  resetPassword,
  userLogin,
  updateUser,
  getSingleProduct,
  getCart,
  saveCart,
  earnPattern,
  cardOrder,
  pixTransfer,
  pixCheck,
  getOrdersByDate,
  getOrdersByEmail,
  getOrderById,
  fetchMyProducts,
  fetchPatterns,
};
