import React, { useEffect, useContext, useReducer } from 'react';
import reducer from '../reducers/products_reducer';
import {
  UPDATE_FILTER,
  IS_OPEN,
  CLEAR_FILTER,
  SEARCH_PRODUCT,
  ADD_TO_BAG,
  REMOVE_FROM_BAG,
  CLEAN_BAG,
  SET_USER_BAG,
} from '../actions';
import {
  saveCart,
  getCart,
  getAllProducts,
  getSingleProduct,
} from '../utils/axiosRequests';

const initialState = {
  products: [],
  filtered_products: [],
  isOpen: false,
  filter: '',
  search: '',
  bag: [],
};

const ProductsContext = React.createContext();

const ProductsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setOpen = () => {
    dispatch({ type: IS_OPEN });
  }

  const setFilter = (key) => {
    dispatch({ type: UPDATE_FILTER, payload: key });
  };

  const searchProduct = (text) => {
    dispatch({ type: SEARCH_PRODUCT, payload: text });
  };

  const clearFilterSearch = () => {
    dispatch({ type: CLEAR_FILTER });
  };

  const addToBag = async (productId) => {
    dispatch({ type: ADD_TO_BAG, payload: productId });
    await saveCart([...state.bag, productId]);
  };

  const removeFromBag = async (productId) => {
    const newBag = state.bag.filter((item) => item !== productId);
    await saveCart(newBag);
    dispatch({ type: REMOVE_FROM_BAG, payload: newBag });
  };

  const cleanBag = async () => {
    await saveCart([]);
    dispatch({ type: CLEAN_BAG });
  };

  const fetchProducts = async (page, search) => {
    const allProductsResult = await getAllProducts(page, search);
    return allProductsResult;
  };

  const fetchSingleProduct = async (id) => {
    const singleProductResult = await getSingleProduct(id);
    return singleProductResult;
  };

  const setCartDb = async () => {
    const resultCartGet = await getCart();

    if (resultCartGet) {
      dispatch({
        type: SET_USER_BAG,
        payload: resultCartGet.cart,
      });
    }
  };

  // useEffect(async () => {
  //   const result = await fetchProducts();
  //   dispatch({ type: SET_PRODUCTS, payload: result.products });
  //   dispatch({ type: SET_USER_PRODUCTS, payload: myProducts });
  // }, []);

  // useEffect(() => {

  //     saveCartDb(state.bag);
  //     dispatch({ type: MARK_PRODUCTS });

  // }, [state.bag]);

  useEffect(() => {
    setCartDb();
  }, []);

  return (
    <ProductsContext.Provider
      value={{
        ...state,
        setOpen,
        setFilter,
        fetchProducts,
        fetchSingleProduct,
        clearFilterSearch,
        searchProduct,
        addToBag,
        removeFromBag,
        cleanBag,
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
};

export const useProductsContext = () => {
  return useContext(ProductsContext);
};

export { ProductsProvider };
