import styled from 'styled-components';
import { useState } from 'react';
import { useAuthContext } from '../context/auth_context';
import { useProductsContext } from '../context/products_context';
import { useNavigate } from 'react-router-dom';
import Loading from './Loading';
import { FaRegCopy } from 'react-icons/fa';
import { pixTransfer, pixCheck } from '../utils/axiosRequests';

const Pix = () => {
  const [payerInfo, setPayerInfo] = useState({
    first: '',
    last: '',
    email: '',
    identificationType: 'CPF',
    identificationNumber: '',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [pending, setPending] = useState(false);
  const [pendingMsg, setPendingMsg] = useState('');
  const [showQrCode, setShowQrCode] = useState({
    qr_code: '',
    qr_code_base64: '',
  });
  const [checkPixUrl, setCheckPixUrl] = useState('');
  const [copy, setCopy] = useState(false);
  const {
    user: { userId },
  } = useAuthContext();
  const { bag, cleanBag } = useProductsContext();
  let navigate = useNavigate();

  const handleChange = (e) => {
    setPayerInfo((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });

    if (e.target.name === 'identificationNumber') {
      if (payerInfo.identificationType === 'CPF') {
        e.target.value = cpf(e.target.value)
      } else {
        e.target.value = cnpj(e.target.value)
      }
    }
  };

  const handleCopy = () => {
    setCopy(true);

    navigator.clipboard.writeText(showQrCode.qr_code);
  };

  const handleCheckPix = async (e) => {
    e.preventDefault();

    setLoading(true);
    const pixCheckResult = await pixCheck(checkPixUrl);
    setLoading(false);

    if (pixCheckResult.status === 'error') {
      setPendingMsg(pixCheckResult.msg);
      return;
    }

    if (pixCheckResult.data.status === 'approved') {
      cleanBag();
      navigate(`/private/meusmoldes`);
    }
    return;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const {
      first,
      last,
      email,
      identificationType,
      identificationNumber,
    } = payerInfo;

    const newPix = {
      userId,
      bag,
      first,
      last,
      email,
      identificationType,
      identificationNumber,
    };

    setLoading(true);
    const pixResult = await pixTransfer(newPix);

    setLoading(false);

    if (pixResult.status === 'error') {
      setError(pixResult.msg);
      return;
    }

    setPending(true);
    setCheckPixUrl(pixResult.data.orderId);
    setShowQrCode({
      qr_code: pixResult.data.qr_code,
      qr_code_base64: pixResult.data.qr_code_base64,
    });
  };

  function cnpj(v) {
    v = v.replace(/\D/g, "")                           //Remove tudo o que não é dígito
    v = v.replace(/^(\d{2})(\d)/, "$1.$2")             //Coloca ponto entre o segundo e o terceiro dígitos
    v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3") //Coloca ponto entre o quinto e o sexto dígitos
    v = v.replace(/\.(\d{3})(\d)/, ".$1/$2")           //Coloca uma barra entre o oitavo e o nono dígitos
    v = v.replace(/(\d{4})(\d)/, "$1-$2")              //Coloca um hífen depois do bloco de quatro dígitos
    return v
  }

  function cpf(v) {
    v = v.replace(/\D/g, "")                    //Remove tudo o que não é dígito
    v = v.replace(/(\d{3})(\d)/, "$1.$2")       //Coloca um ponto entre o terceiro e o quarto dígitos
    v = v.replace(/(\d{3})(\d)/, "$1.$2")       //Coloca um ponto entre o terceiro e o quarto dígitos
    //de novo (para o segundo bloco de números)
    v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2") //Coloca um hífen entre o terceiro e o quarto dígitos
    return v
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <Wrapper>
      <div className='main-container-pix'>
        {!error && !pending ? (
          <div >
            <h2>
              Preencha com os dados do pagante para gerar a chave Pix/QrCode
            </h2>
            <form>
              <div className="first">
                <label htmlFor="first" className="form-label">
                  Primeiro nome:
                  <input
                    type="text"
                    name="first"
                    value={payerInfo.first}
                    onChange={handleChange}
                    required
                  /></label>
              </div>
              <div className="last">
                <label htmlFor="last" className="form-label">
                  Último nome:
                  <input
                    type="text"
                    name="last"
                    value={payerInfo.last}
                    onChange={handleChange}
                    required
                  />
                </label>
              </div>
              <div className="email">
                <label htmlFor="email" className="form-label">
                  Email:
                  <input
                    type="email"
                    name="email"
                    value={payerInfo.email}
                    onChange={handleChange}
                    required
                  />
                </label>
              </div>

              <div className="select-container">
                <select
                  className="select"
                  name="identificationType"
                  onChange={handleChange}
                >
                  <option value="CPF">CPF</option>
                  <option value="CNPJ">CNPJ</option>
                </select>
                <input
                  className='cpf-input'
                  name="identificationNumber"
                  type="text"
                  value={payerInfo.identificationNumber}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className='btn-container'>
                {!pending && (
                  <button
                    type="submit"
                    className="btn btn-pix"
                    onClick={handleSubmit}
                    disabled={
                      !payerInfo.first ||
                      !payerInfo.last ||
                      !payerInfo.email ||
                      !payerInfo.identificationNumber
                    }
                  >
                    Gerar chave pix
                  </button>
                )}
              </div>
            </form>
          </div>
        ) : error ? (
          <div className="error">{error}</div>
        ) : (
          <div className="qr-code-container">
            <h2>Escaneie o Qr-code para completar o pagamento:</h2>
            <div className="qr-code-img-container">
              <img
                src={`data:image/jpeg;base64,${showQrCode.qr_code_base64}`}
                className="qr-code-img"
              />
            </div>
            <h2>Ou copie e cole a chave</h2>
            {!copy ? (
              <div className="copy-container" onClick={handleCopy}>
                <p className="qr-code-row">{showQrCode.qr_code}</p>
                <span>
                  <FaRegCopy className="copy-icon" />
                </span>
              </div>
            ) : (
              <p className="copied">Copiado</p>
            )}

            <p>
              Após efetuar o pagamento, solicite a conferência clicando no botão
              abaixo.
            </p>

            <div className='btn-container'>
              {pendingMsg && <p className="pending-msg">{pendingMsg}</p>}
              <button
                type="submit"
                className="btn check-pix-btn"
                onClick={handleCheckPix}
              >
                Conferir Pix
              </button>
            </div>
          </div>
        )}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
.main-container-pix {
  padding: 0.5rem;
  padding-top: 0;
  width: 100%;
  max-width: 32rem;
}

  h2 {
    font-size: 1rem;
    text-transform: none;
    padding: 1rem 0;
  }

  .pending {
    width: 50%;
    margin: 0 auto;
    text-align: justify;
    text-justify: inter-word;
    padding: 2rem;
  }

  .pending p {
    font-size: 1.1rem;
    padding-bottom: 2rem;
  }

  .btn-pending {
    margin: 0 auto;
  }

  .btn-pix {
    margin: 0 auto;
    padding: 0.75rem 1.5rem;
  }

  label {
    padding-right: 0.5rem;
    font-size: 0.75rem;
  }

  .select-container {
    display: flex;
  }
  
  .select {
    width: 5rem;
    height: 1.8rem;
    padding: 0;
    padding-bottom: 0.2rem;
    margin-top: 0.5rem;
    margin-right: 1rem;
    margin-left:0;
  }

  .select:hover {
    cursor: pointer;
  }

  .select,
  input {
    border: none;
    border-bottom: 1px solid var(--primary-main);
    border-radius: 0;
    outline: none;
    margin-bottom: 1.5rem;
  }
  
  input {
    width: 17rem;
  }
  
  .cpf-input {
    width: 11rem;
  }

  .btn-container {
    width: 100%;
    display: grid;
    place-items:center;
  }
  
  .btn-pix {
    font-size: 1rem;
  }
  
  .qr-code-img-container {
    display: grid;
    place-items: center;
  }

  .qr-code-img {
    width: 200px;
    height: 200px;
    margin-bottom: 1rem;
  }

  .copy-container {
    width: 100%;
    margin: 1rem 0;
    display: flex;
    justify-content: space-between;
  }

  .qr-code-row {
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.8rem;
  }

  .qr-code-row span {
    padding-left: 1rem;
  }

  .copy-container:hover {
    cursor: pointer;
  }

  .copy-icon {
    font-size: 1.4rem;
  }

  .copied {
    padding: 0.5rem 0;
    color: green;
    font-weight: bold;
    text-align: center;
    letter-spacing: var(--letterSpacing);
  }

  .check-pix-btn {
    font-size: 1rem;
    margin: 1rem auto;
    padding: 1rem;
  }

  .pending-msg {
    margin: 1rem 0;
    font-size: 1rem;
    color: var(--primary-700);
    font-weight: bold;
  }

  @media screen and (min-width: 790px) {
    h2 {
      font-size: 1.2rem;
    }

    .pending p {
      font-size: 1.1rem;
      padding-bottom: 2rem;
    }

    label {
      font-size: 0.9rem;
    }
  
    input {
      width: 17rem;
    }

    .check-pix-btn,
    .btn-pix {
      font-size: 1.2rem;
    }

    .copy-container {
      width: 90%;
    }

    .qr-code-img {
      width: 250px;
      height: 250px;
    }

    .pending-msg {
      font-size: 1.2rem;
    }
  }
`;

export default Pix;
