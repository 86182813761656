import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useProductsContext } from '../context/products_context';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../context/auth_context';
import { cardOrder } from '../utils/axiosRequests';
import Loading from './Loading';

//import { initMercadoPago, Payment } from '@mercadopago/sdk-react';
import { Payment, initMercadoPago } from '@mercadopago/sdk-react'

const customization = {
  paymentMethods: {
    creditCard: "all",
  }
}

const MercadoPagoComponent = ({ initialization }) => {
  const [loadind, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { bag, cleanBag } = useProductsContext();
  const { user } = useAuthContext();
  let navigate = useNavigate();

  useEffect(() => {
    initMercadoPago('APP_USR-4f64c6d5-9a98-4939-bfbf-a9df478cb6de');
  }, [])


  const onSubmit = async ({ formData }) => {
    // callback chamado ao clicar no botão de submissão dos dados
    formData = { ...formData, bag, userId: user.userId };

    setLoading(true);
    const cardOrderResult = await cardOrder(formData);

    setLoading(false);

    if (cardOrderResult.status === 'error') {
      setError(cardOrderResult.msg);
      return;
    }

    if (cardOrderResult.success) {
      cleanBag();
      navigate(`/private/meusmoldes`);
    }
    return;
  };

  const onError = async (error) => {
    // callback chamado para todos os casos de erro do Brick
    setError('Algo deu errado: recarregue a página.');
    console.log(error);
  };

  const onReady = async () => {

    /*
        Callback chamado quando o Brick estiver pronto.
        Aqui você pode ocultar loadings do seu site, por exemplo.
      */
  };

  if (loadind) return <Loading />

  return (
    <Wrapper>
      {error ? <div className="error">{error}</div> :
        <div className='card-method'>
          <Payment
            initialization={initialization}
            customization={customization}
            onSubmit={onSubmit}
            onReady={onReady}
            onError={onError}
          />
        </div>
      }
    </Wrapper>
  )
};

const Wrapper = styled.form`

.card-method {
width: 100%;
max-width: 25rem;
margin: 1rem auto;
}
`;

export default MercadoPagoComponent;
