import { NavLink, useLocation, Link } from 'react-router-dom';
import styled from 'styled-components';
import logo from '../assets/logoTransparent.png';
import { useAuthContext } from '../context/auth_context';
import { useProductsContext } from '../context/products_context';
import { FaShoppingBag } from 'react-icons/fa';
import { Fade as Hamburger } from 'hamburger-react'

const Navbar = () => {
  const { user } = useAuthContext();
  const { bag, isOpen, setOpen } = useProductsContext();
  let location = useLocation().pathname;


  const isHomePage = () => {
    if (location === '/') {
      return true
    }
    return false
  }

  const path = () => {
    if (location >= '/services/pronta') {
      return 'moldes pronta entrega';
    }
    if (location === '/services/encomenda') {
      return 'moldes sob encomenda';
    }
    if (location === '/services/digitalizar') {
      return 'digitalização de moldes';
    }
    else {
      return '';
    }
  };

  const linksBtn = (sidebar) => {
    return <div className={`${sidebar ? 'sidebar-content' : 'navbar-container'} ${isHomePage() ? 'margin-links' : ''}`}>
      <div className={sidebar ? "link" : "home-link"} onClick={() => setOpen(false)}>
        <NavLink to="/" >
          Home
        </NavLink>
      </div>
      {user ? (
        user.role === 'superuser' ?
          <div className={sidebar ? "link" : "home-link"} onClick={() => setOpen(false)}>
            <NavLink to="/private/admin" >
              Admin
            </NavLink>
          </div> :
          <div className={sidebar ? "link" : "home-link"} onClick={() => setOpen(false)}>
            <NavLink to="/private/meusMoldes" >
              Meus Moldes
            </NavLink>
          </div>
      ) : (
        <div className={sidebar ? "link" : "home-link"} onClick={() => setOpen(false)}>
          <NavLink to="/logar" >
            Logar
          </NavLink>
        </div>
      )}
    </div>
  }

  return (
    <Wrapper>
      <div className={!isHomePage() ? "nav-out-home" : "nav-home"}>

        {!isHomePage() ?
          <div className="nav-header-container">
            <div className="nav-header">
              <Link to='/'>
                <img src={logo} alt="logo" className='logo-img' />
              </Link>
              <h3 className='logo-text'>
                {path()}
              </h3>
            </div>

            <div className='large-screen-content'>{linksBtn(false)}</div>

            <div className="checkout-container">
              <NavLink to="/sacola" className='bag'>
                <div className="count">
                  <span>{bag.length !== 0 ? bag.length : 0}</span>
                </div>
                <FaShoppingBag />
              </NavLink>
            </div>

            <div className='small-screen-content'>
              <div className='hamburger-container'>
                <Hamburger toggled={isOpen} size={20} toggle={() => setOpen(!isOpen)} />
              </div>
            </div>
          </div>
          : null
        }

        {isHomePage() ?
          linksBtn(false) :
          <div className={isOpen ? "sidebar-container show-sidebar " : "sidebar-container"}>

            {linksBtn(true)}

            <div className='close-sidebar' onClick={() => setOpen(false)}></div>
          </div>
        }
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.nav`
width: 100vw;
z-index: 3;
position: relative;
max-width: 1300px;
margin: 0 auto;

.small-screen-content {
  display:block;
}

.large-screen-content {
  display: none;
}

.nav-home {
  font-size: 0.8rem;
  position: absolute;
  right: 0;
  top: 0;
  padding: 1.5rem;
  text-align: right;
}

.link a, .link a:visited,
.home-link a:visited,
.home-link a {
  display: inline-block;
  color:  color: var(--grey-main);
  margin: 0.2rem 0;
}

.sidebar-content a {
  width: 100%;
}

.nav-out-home {
  background: var(--grey-104);
  width: 100%;
  padding: 1.2rem;
  position: fixed;
  top: 0;
  left: 0;
}

.nav-header-container {
  display: grid;
  grid-template-columns: 1fr 45px 40px;
  place-items:center;
}

.nav-header {
  justify-self: left;
  display: flex;
  align-items: center;
}

.logo-img {
  width: 1.8rem;
}

.logo-text {
  color: var(--grey-main);
  font-size: 0.9rem;
  margin-left: 0.7rem;
}

.checkout-container {
  font-size: 1rem;
  position: relative;
  top: 0.2rem;
}

.bag:visited,
.bag {
  color: var(--primary-main);
}

.hamburger-container {
  width: 
  z-index:10;
  transition: var(--transition);
}

.sidebar-container {
  z-index: 9;
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  transform: translateX(-100vw);
  overflow: hidden;
  transition: var(--transition);
  display:grid;
  grid-template-columns: 60vw 1fr;
}

.show-sidebar {
  transform: translateX(0);
} 

.close-sidebar {
  background: var(--grey-100);
  opacity: 0.4;
  width: 60vw;
}

.sidebar-content {
  background: var(--grey-104);
  width: 60vw;
  height: 100vh;
  padding-top: 3rem;
}

.link {
  background: var(--primary-main); 
  color: var(--grey-100);
  text-transform: uppercase;
  margin-top: 0.2rem;
  padding: 0.4rem 1.5rem;
  font-size: 1rem;
}

  .active {
    color: var(--primary-500);
    border-bottom: 1px solid var(--primary-500);
  }

  .count {
    position: absolute;
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 50%;
    background: var(--primary-800);
    opacity: 0.5;
    left: 0.5rem;
    top: -0.5rem;
    display: grid;
    place-items: center;
  }

  .count span {
    color: var(--primary-700);
    opacity: 1;
    font-size: 0.9rem;
    font-weight: 700;
  }

  @media screen and (min-width: 550px) {
    .nav-out-home {
      padding: 1.3rem 2rem;
    }

    .logo-img {
      width: 3rem;
    }

    .logo-text {
      font-size: 1.3rem;
      margin-left: 1.3rem;
    }

    .margin-links{
      margin: 2rem;
    }
  }

  @media screen and (orientation: landscape) {
    .nav-home {
      padding: 0 1rem;
    }
  }

  @media screen and (min-width: 790px) {
    .sidebar-container,
    .small-screen-content{
      display: none;
    }

    .large-screen-content{
      display: block;
    }

    .margin-links{
      font-size: 1rem;
    }

    .nav-out-home {
      padding: 1rem;
    }

    .nav-header-container {
      width: 90vw; 
      margin: 0 auto;
      grid-template-columns: 70% 20vw 1fr;
    }

    .nav-header {
      justify-self: left;
    }

    .navbar-container {
      width: 20vw;
      display:flex;
      justify-content: space-between;
      align-items: center;
    }

    .logo-container {
      width: 50%;
      display:flex;
      align-items: center;
    }
    
    .logo-img {
      width: 3rem;
    }
    
    .logo-text {
      color: var(--grey-main);
      font-size: 2rem;
      margin-left: 1.3rem;
    }
    
    .nav-links {
      width: 16rem;
      display: flex;
      justify-content: space-around;
      text-transform: capitalize;
      justify-self: right;
    }
  }

  @media screen and (min-width: 980px) {
    .navbar-container{
      width: 20vw;
      max-width: 13rem;
    }

    .logo-text {
      font-size: 1.3rem;
      margin-left: 1.3rem;
    }

    .logo-img {
      width: 4rem;
    }
  }
`;

export default Navbar;
