// global actions
export const SHOW_ALERT = 'SHOW_ALERT';
export const HIDE_ALERT = 'HIDE_ALERT';
export const RESET_PASSWORD_PAYMENT_PAGE = 'RESET_PASSWORD_PAYMENT_PAGE';

// users actions
export const SET_USER = 'SET_USER';
export const USER_VERIFICATION = 'USER_VERIFICATION';
export const REMOVE_USER = 'REMOVE_USER';

// products actions
export const UPDATE_FILTER = 'UPDATE_FILTER';
export const IS_OPEN = 'IS_OPEN';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const SEARCH_PRODUCT = 'SEARCH_PRODUCT';
export const ADD_TO_BAG = 'ADD_TO_BAG';
export const REMOVE_FROM_BAG = 'REMOVE_FROM_BAG';
export const CLEAN_BAG = 'CLEAN_BAG';
export const SET_USER_PRODUCTS = 'SET_USER_PRODUCTS';
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const MARK_PRODUCTS = 'MARK_PRODUCTS';
export const SET_USER_BAG = '  SET_USER_BAG';
