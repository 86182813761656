import styled from 'styled-components';
import Navbar from '../components/Navbar';
import { FaCheck, FaFilePdf, FaPrint } from 'react-icons/fa';
import { BsFile, BsFiles, BsGrid3X3, BsImages, BsPostcard } from 'react-icons/bs';
import { GiAlarmClock, GiLargeDress } from 'react-icons/gi';
import { MdOutlineAttachEmail } from 'react-icons/md';
import { Helmet } from 'react-helmet-async';

const ServicesDigital = () => {
  const screenWidth = window.innerWidth;

  return (
    <Wrapper>

      <Helmet>
        <title>MOLDEE - Gestão Em Modelagem Digital - Sob Encomenda</title>
        <meta name="description" content="No serviço sob encomenda da Moldee você envia uma foto do modelo que
        deseja para fazermos um orçamento. Após a confirmação do pagamento nós criamos seu molde com um pré teste
        realizado em um programa digital e em até quinze dias enviamos seu molde por email nos 
        formatos A4 e Plotter para você imprimir na sua cidade. Pequenos ajustes estão inclusos."
          data-rh="true" />
        <link rel='canonical' href='/services/encomenda' />
      </Helmet>

      <Navbar />

      <div className='make-container'>
        {screenWidth < 790 ?
          <div className='split-in-rows-container'>

            <div className='card-row'>
              <div className='space-column'></div>
              <div className='context'>
                <div className='info-card-container'>
                  <div className='info-card-icon'><BsImages /></div>
                  <div className='info-text'>
                    <p>
                      Você envia uma foto do seu molde de papel
                    </p>
                  </div>
                </div>
              </div>
              <div className='space-column'>
                <div className='border-50'></div>
                <div className='border-50 border-top border-right corner-top-right'></div>
              </div>
            </div>
            <div className='space-row'>
              <div className='space-column'></div>
              <div className=''></div>
              <div className='space-column'>
                <div className='border-100 border-right'></div>
              </div>
            </div>

            <div className='card-row'>
              <div className='space-column'>
                <div className='border-50'></div>
                <div className='border-50 border-left border-top corner-top-left'></div>
              </div>
              <div className='context'>

                <div className='info-card-container'>
                  <div className='info-card-icon'><FaFilePdf /></div>
                  <div className='info-text'>
                    <p>
                      Nós digitalizamos e enviamos em arquivos no formato PDF
                    </p>
                  </div>
                </div>
              </div>
              <div className='space-column'>
                <div className='border-50 border-right border-bottom corner-bottom-right'></div>
                <div className='border-50'></div>
              </div>
            </div>
            <div className='space-row'>
              <div className='space-column'>
                <div className='border-100 border-left'></div>
              </div>
              <div className=''></div>
              <div className='space-column'></div>
            </div>

            <div className='card-row'>
              <div className='space-column'>
                <div className='border-50 border-left border-bottom corner-bottom-left'></div>
                <div className='border-50'></div>
              </div>
              <div className='context'>
                <div className='info-card-container'>
                  <div className='info-card-icon'><MdOutlineAttachEmail /> <FaPrint /></div>
                  <div className='info-text'>
                    <p>
                      Enviamos em seu email pronto
                      para impressão em A4 ou Plotter (+90cm)
                    </p>
                  </div>
                </div>
              </div>
              <div className='space-column'>
                <div className='border-50'></div>
                <div className='border-50 border-right border-top corner-top-right'></div>
              </div>
            </div>
            <div className='space-row'>
              <div className='space-column'>

              </div>
              <div className=''></div>
              <div className='space-column'>
                <div className='border-100 border-right'></div>
              </div>
            </div>

            <div className='card-row'>
              <div className='space-column'>
                <div className='border-50'></div>
                <div className='border-50 border-left border-top corner-top-left'></div>
              </div>
              <div className='context'>
                <div className='info-card-container'>
                  <div className='info-card-icon'><GiAlarmClock /></div>
                  <div className='info-text'>
                    <p>
                      Entregamos em até 4 dias uteis após a confirmação do pagamento
                    </p>
                  </div>
                </div>
              </div>
              <div className='space-column'>
                <div className='border-50 border-right border-bottom corner-bottom-right'></div>
                <div className='border-50'></div>
              </div>
            </div>
            <div className='space-row'>
              <div className='space-column'>
                <div className='border-100 border-left'></div>
              </div>
              <div className=''></div>
              <div className='space-column'></div>
            </div>

            <div className='card-row'>
              <div className='space-column'>
                <div className='border-50 border-left border-bottom corner-bottom-left'></div>
                <div className='border-50'></div>
              </div>
              <div className='context'>
                <div className='info-card-container'>
                  <div className='info-card-icon'><BsGrid3X3 /></div>
                  <div className='info-text'>
                    <p>
                      Caso queira outros tamanhos, consulte sobre a graduação
                    </p>
                  </div>
                </div>
              </div>
              <div className='space-column'>
              </div>
            </div>
          </div> :

          // Big screen layout
          <div className='split-in-rows-container-big'>

            <div className='row-card-container-big'>
              <div className='space-column'>
              </div>

              <div className='context'>
                <div className='info-card-container'>
                  <div className='info-card-icon'><BsImages /></div>
                  <div className='info-text'>
                    <p>
                      Você envia uma foto do seu molde de papel
                    </p>
                  </div>
                </div>
              </div>

              <div className='space-column'>
                <div className='border-50 border-bottom'></div>
                <div className='border-50'></div>
              </div>

              <div className='context'>
                <div className='info-card-container'>
                  <div className='info-card-icon'><FaFilePdf /></div>
                  <div className='info-text'>
                    <p>
                      Nós digitalizamos e enviamos em arquivos no formato PDF
                    </p>
                  </div>
                </div>
              </div>

              <div className='space-column'>
                <div className='border-50'></div>
                <div className='border-50 border-top border-right corner-top-right'></div>
              </div>
            </div>

            <div className='space-row-big'>
              <div className='border-100 border-right'></div>
            </div>

            <div className='row-card-container-big'>
              <div className='space-column'>
                <div className='border-50'></div>
                <div className='border-50 border-top border-left corner-top-left'></div>
              </div>

              <div className='context'>
                <div className='info-card-container'>
                  <div className='info-card-icon'><MdOutlineAttachEmail /> <FaPrint /></div>
                  <div className='info-text'>
                    <p>
                      Enviamos em seu email pronto
                      para impressão em A4 ou Plotter (+90cm)
                    </p>
                  </div>
                </div>
              </div>

              <div className='space-column'>
                <div className='border-50'></div>
                <div className='border-50 border-top'></div>
              </div>

              <div className='context'>
                <div className='info-card-container'>
                  <div className='info-card-icon'><GiAlarmClock /></div>
                  <div className='info-text'>
                    <p>
                      Entregamos em até 4 dias uteis após a confirmação do pagamento
                    </p>
                  </div>
                </div>
              </div>

              <div className='space-column'>
                <div className='border-50 border-right border-bottom corner-bottom-right'></div>
                <div className='border-50'></div>
              </div>
            </div>

            <div className='space-row-big'>
              <div className='border-100 border-left'></div>
            </div>

            <div className='row-card-container-big'>
              <div className='space-column'>
                <div className='border-50 border-left border-bottom corner-bottom-left'></div>
                <div className='border-50'></div>
              </div>

              <div className='context'>
                <div className='info-card-container'>
                  <div className='info-card-icon'><BsGrid3X3 /></div>
                  <div className='info-text'>
                    <p>
                      Caso queira outros tamanhos, consulte sobre a graduação
                    </p>
                  </div>
                </div>
              </div>

              <div className='space-column'>
                <div className='border-50'></div>
                <div className='border-50 border-top corner-top-right' style={{ display: 'flex' }}>
                  <div className='space-btn-50'></div>
                  <div className='space-btn-50 border-right border-bottom corner-bottom-left corner-bottom-rigth'></div>
                </div>
              </div>
            </div>
          </div>
        }

        <div className='space-btn'>
          <div className='space-btn-50 border-right'></div>
          <div className='space-btn-50'></div>
        </div>

        <div className='whats-container'>

          <div className="whats-link-container">
            <a
              href="https://web.whatsapp.com/send?phone=5543999951135"
              target="_blank"
              rel="noopener noreferrer"
              className="whats-link"
            >
              Solicitar orçamento
            </a>
          </div>

        </div>


      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
.make-container {
  width: 90vw;
  max-width: 30rem;
  margin: 2rem auto;
  margin-top: 8rem;
}

.split-in-rows-container {
  width: 90%;
  margin: 0 auto;
  display: grid;
  grid-template-rows: 1fr 50px 1fr 50px 1fr 50px 1fr 50px 1fr;
}

.card-row, .space-row {
  display: grid;
  grid-template-columns: 40px 1fr 40px;
}

.border-50 {
  height: 50%;
}

.border-100 {
  height: 100%;
}

.corner-top-left {
  border-radius: 15% 0 0 0;
}
.corner-top-right {
  border-radius: 0 15% 0 0;
}
.corner-bottom-right {
  border-radius: 0 0 15% 0;
}
.corner-bottom-left {
  border-radius: 0 0 0 15%;
}

.border-right {
  border-right: 2px dashed var(--primary-main);
}
.border-left {
  border-left: 2px dashed var(--primary-main);
}
.border-top {
  border-top: 2px dashed var(--primary-main);
}
.border-bottom {
  border-bottom: 2px dashed var(--primary-main);
}

.space-btn {
  display:flex;
  width: 100%;
  height: 3rem;
  
}

.space-btn-50{
  width: 50%;
}

.context {
  background: var(--grey-103);
  border-radius: 5%;
  padding: 1rem;
}

.context p {
  padding-top: 1rem;
  font-size: 0.9rem;
  text-align: center;
}

.whats-container {
  width: 100%;
  margin: 0 auto;
}

.whats-container {
  width: 100%;
}

.whats-link-container {
  width: 80%;
  margin: 0 auto;
  max-width: 20rem;
  background: var(--grey-103);
  text-align: center;
  padding: 1rem;
  border-radius: 1rem;
  font-size: 1rem;
  text-transform:uppercase;
  padding: 1rem;
}

.whats-link-container a:visited,
.whats-link-container a {
  color: var(--grey-200);
}

@media screen and (min-width: 790px) {
  .make-container {
    width: 90vw;
    max-width: 1000px;
    margin: 2rem auto;
    margin-top: 8rem;
  }

  .split-in-rows-container-big {
    width: 90%;
    margin: 0 auto;
    display: grid;
    grid-template-rows: 1fr 50px 1fr 50px 1fr;
  }

  .space-row-big {
    width: 100%;
  }

  .row-card-container-big {
    display: grid;
    grid-template-columns: 40px 1fr 40px 1fr 40px;
  }

  
}
`;

export default ServicesDigital;
