import { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FaPenAlt, FaTimes } from 'react-icons/fa';

const AdminProductDisplay = ({
  products,
  handleEditProduct,
  handleProductDelete,
}) => {
  const [modal, setModal] = useState(false);
  const [delProduct, setDelProduct] = useState({});

  const handleDelete = (id, productName, category, subCategory) => {
    setDelProduct({ id, productName, category, subCategory });
    setModal(true);
  };

  return (
    <Wrapper>
      {products.map((product, index) => {
        const {
          _id: id,
          productName,
          price,
          off,
          category,
          subCategory,
          description,
          featured,
          sendEmail,
          tissueSuggestion,
          tableKey,
          imgKeyList,
        } = product;

        const igmHero = imgKeyList.filter(item => item.hero)[0];

        return (
          <article className="product-box" key={index}>

            <div className="img-hero-container">
              <img
                src={igmHero.url || igmHero.preview}
                alt={productName}
              />
            </div>

            <div className="size-table-container">
              <img
                src={tableKey.preview || tableKey}
                alt='tabela de medidas'
              />
            </div>

            <div className="info-1">
              <h6 >Nome: <span>{productName}</span></h6>
              <div className='short-flex-container'>
                <h6>preço:<span>{price}</span></h6>
                <h6 className="off">off:<span>{off}%</span></h6>
              </div>
              <div className='short-flex-container'>
                <h6 >Categoria: <span>{category}</span></h6>
                <h6 className="sub-category">Subcategoria:<span>{subCategory}</span> </h6>
              </div>
              <div className='short-flex-container'>
                <h6>enviar email: {sendEmail ? <span>sim</span> : <span>não</span>}</h6>
                <h6 className='featured'>destaque: {featured ? <span>sim</span> : <span>não</span>}</h6>
              </div>
            </div>

            <div className='info-2'>
              <h6>Descrição: </h6>
              <p className="description text-exceed">{description}</p>
              <h6>Sugestão de Tecidos:</h6>
              <div className="tissueSuggestions text-exceed">
                {tissueSuggestion.map((tissue, i) => (
                  <span className='span-tissue' key={i}>{tissueSuggestion.length - 1 !== i ? `${tissue}, ` : `${tissue}.`} </span>
                ))}
              </div>
            </div>

            <div className="btns-container">
              {id ? (
                <Link
                  to={`/private/admin/molde/${id}`}
                  className="btn btn-icon"
                >
                  {/* component: pages/adminMolde */}
                  <FaPenAlt />
                </Link>
              ) : (
                <button type="button" className="btn btn-icon" onClick={() => handleEditProduct(index)}>
                  <FaPenAlt />
                </button>
              )}
              <button
                type="buttom"
                className="btn btn-icon"
                onClick={() => {
                  let ident;
                  if (id) {
                    ident = id;
                  } else {
                    ident = index;
                  }
                  handleDelete(ident, productName, category, subCategory);
                }}
              >
                <FaTimes />
              </button>
            </div>

          </article>
        );
      })}
      {modal && (
        <div className="modal-container">
          <div className="modal-msg-container">
            <h4>Deseja excluir este produto?</h4>
            <div className="modal-product-description">
              <p>{delProduct.productName} <span>{`${delProduct.subCategory}-${delProduct.category}`}</span></p>
            </div>
            <div className="modal-buttons">
              <button
                type="buttom"
                className='btn'
                onClick={() => {
                  handleProductDelete(delProduct.id);
                  setModal(false);
                  setDelProduct({});
                }}
              >
                excluir
              </button>
              <button
                type="buttom"
                className='btn'
                onClick={() => {
                  setModal(false);
                  setDelProduct({});
                }}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .product-box {
    background: var(--grey-600);
    display:grid;
    grid-template-columns: 8rem 8rem 1fr 45% 50px;
    padding: 1rem;
    margin: 0.5rem 0;
  }

  .size-table-container,
  .img-hero-container {
    display:grid;
    place-items:center;
  }
  
  .size-table-container img,
  .img-hero-container img {
    width: 7rem;
    height: 7rem;
  }

  .info-1,
  .info-2 {
    padding: 0.5rem 1rem;
  }

  h6 {
    font-size: 0.7rem;
    text-transform: uppercase;
    margin-top: 0.4rem
  }

  .text-exceed {
    max-width: 100%;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }

  .modal-product-description{
    padding: 1rem 0;
    text-transform: capitalize;
  }
  
  p {
    font-size: 0.8rem;
  }
  
  span {
    margin-left: 0.5rem;
    font-size: 0.8rem;
  }
  
  .span-tissue {
    margin-left: 0;
  }

  .short-flex-container {
    display: flex;
    width: 100%;
  }

  .off,
  .sub-category,
  .featured {
    margin-left: 1rem;
  }

  .btns-container {
    display:block;
    margin: auto 0;
    margin-left: calc(50px - 1.4rem);
  }

  .btn-icon {
    margin: 0.7rem 0;
    width: 1.4rem;
    height: 1.4rem;
    padding: 0.3rem;
    font-size: 0.8rem;
    display: grid;
    place-items: center;
  }

  .no-product-msg {
    font-size: 1.3rem;
    padding: 2rem 0;
  }
  
  .tissueSuggestions {
    max-height: 180px;
    overflow: auto;
  }
  
  .modal-msg-container {
    padding: 3rem;
    line-height: 1.3rem;
    max-width: 70vw;
    font-size: 1rem;
    background: var(--grey-103);
  }

  .modal-msg-container p {
    padding: 2rem 0;
    font-size: 1rem;
  }

  .modal-buttons {
    display:flex;
    justify-content: space-between;
  }

`;

export default AdminProductDisplay;
