export function formatPrice(number) {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(number);
}

export function translateStatus(status) {
  if (status === 'approved') return 'aprovado';
  if (status === 'pending') return 'aguardando';
  if (status === 'rejected') return 'não aprovado';
  if (status === 'cancelled') return 'cancelado';
  return status;
}
