import styled from 'styled-components';
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaWhatsapp,
  FaInfo,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Footer = () => {
  let year = new Date().getFullYear();
  return (
    <Wrapper>
      <section className="footer-container">
        <Link to="sobre" className="footer-icon">
          <FaInfo />
        </Link>
        <a
          target="_blank"
          href="https://www.facebook.com/moldee.modelagem/"
          className="footer-icon"
        >
          <FaFacebook />
        </a>
        <a
          href="https://www.instagram.com/moldee___/"
          target="_blank"
          className="footer-icon"
        >
          <FaInstagram />
        </a>
        <a href="#" className="footer-icon">
          <FaYoutube />
        </a>
        <a
          href="https://web.whatsapp.com/send?phone=5543999951135"
          target="_blank"
          rel="noopener noreferrer"
          className="footer-icon"
        >
          <FaWhatsapp />
        </a>
      </section>
      <section className="rights">
        <span>@{year}</span> Todos os direitos reservados.
      </section>
    </Wrapper>
  );
};

const Wrapper = styled.footer`
  background: var(--grey-100);
  color: var(--grey-400);
  font-size: 1.7rem;
  width: 100vw;
  height: 7rem;
  display: grid;
  grid-template-rows: 2fr 1fr;
  place-items: center;
  padding-top: 1rem;

  .footer-container {
    width: 40%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .rights {
    font-size: 0.8rem;
    margin-bottom: 1rem;

    & span {
      color: var(--primary-main);
    }
  }

  @media screen and (min-width: 550px){
    .rights {
      font-size: 1rem;
    }
  }
`;

export default Footer;
