import { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import styled from 'styled-components';
import { resetPassword, userLogin } from '../utils/axiosRequests';
import FormRow from '../components/FormRow';
import { useGlobalContext } from '../context/global_context';
import { useAuthContext } from '../context/auth_context';

const ResetPassword = ({ location, email }) => {
  const [loading, setLoading] = useState(false);
  const [credentials, setCredentials] = useState({
    password: '',
    secret: '',
  });

  const { saveUser } = useAuthContext();

  const {
    alert,
    showAlert,
    hideAlert,
    resetPasswordInPaymentPage,
  } = useGlobalContext();
  let navigate = useNavigate();

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setCredentials({ ...credentials, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const { password, secret } = credentials;

    if (!password || !secret) {
      setLoading(false);
      return;
    }

    const ResetPasswordResult = await resetPassword({
      password,
      secret,
      email,
    });

    if (ResetPasswordResult.status === 'error') {
      setLoading(false);
      showAlert({ text: ResetPasswordResult.msg });
      return;
    }

    // automatic login
    const loginResult = await userLogin({
      email,
      password,
    });

    if (loginResult.status === 'error') {
      setLoading(false);
      showAlert({ text: loginResult.msg });
      return;
    }

    setLoading(false);
    saveUser(loginResult);
    setCredentials({
      password: '',
      secret: '',
    });

    if (location === 'payment') {
      return;
    }

    return navigate(`/private/meusmoldes`);
  };

  useEffect(() => {
    let cancel = false;
    hideAlert();
    return () => (cancel = true);
  }, []);

  return (
    <Wrapper>
      <div className='reset-password-container'>


        <form
          className={loading ? 'form form-loading' : 'form'}
          onSubmit={handleSubmit}
        >
          <h2>nova senha</h2>

          <FormRow
            type="password"
            name="password"
            value={credentials.password}
            handleChange={handleChange}
          />

          <FormRow
            type="text"
            name="secret"
            value={credentials.secret}
            handleChange={handleChange}
          />

          <div className={location === 'payment' ? 'btn-container payment-page-btn' : 'btn-container'}>
            {location === 'payment' && (

              <button
                type="button"
                className="btn auth-btn"
                onClick={() => resetPasswordInPaymentPage(false)}
              >
                Voltar
              </button>

            )}

            <button type="submit" className="btn auth-btn" disabled={loading}>
              {loading ? 'Aguarde...' : 'Enviar'}
            </button>
          </div>

          <p className="auth-options">
            Cancelar?
            <Link to="/">Home</Link>
          </p>
        </form>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  display: grid;
  place-items: center;

  .payment-page-btn{
    display: flex;
    justify-content: space-between;
  }
`;

export default ResetPassword;
