const Loading = ({ loadingMsg }) => {
  return (
    <section className="loading-container">
      <p>{loadingMsg}</p>
      <div className="loading"></div>
    </section>
  );
};

export default Loading;
