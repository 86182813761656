
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { fetchFreePatterns } from '../utils/axiosRequests'
import { Card, Carousel } from '../components';

const FreePatterns = () => {
  const [freePatterns, setFreePatterns] = useState([]);
  const [error, setError] = useState(false);
  const [show, setShow] = useState(0)

  const [currentIndex, setCurrentIndex] = useState(0)
  const [indicator, setIndicator] = useState(false)

  const getFreePatterns = async () => {
    const getFreePatternResponse = await fetchFreePatterns();
    if (!getFreePatternResponse || getFreePatternResponse.status === 'error') {
      setError(true);
      return;
    }

    setFreePatterns(getFreePatternResponse)
    return;
  }

  const showNum = () => {
    const screenWidth = window.innerWidth

    if (screenWidth < 550) {
      setShow(2)
    } else if (screenWidth >= 550 && screenWidth < 990) {
      setShow(3)
    } else if (screenWidth >= 990 && screenWidth < 1300) {
      setShow(4)
    } else {
      setShow(5)
    }
  }

  useEffect(() => {
    showNum()
    getFreePatterns();
  }, [])

  return (
    <Wrapper>
      {!error ?
        <Carousel show={show} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex}
          indicator={indicator} setIndicator={setIndicator}>
          {freePatterns.map((product, index) => {
            const hero = product.imgKeyList.filter(img => img.hero)[0]

            const { productName, price, off, _id: id } = product;
            const link = `/services/pronta/${id}`;
            const data = { heroUrl: hero.url, productName, price, off, link }
            return (
              <Card key={index} data={data} width={show} />
            );
          })}
        </Carousel>
        : <p className='error-msg'>Desculpe, nenhum molde disponível nesta seção.</p>}
    </Wrapper>
  );
};

const Wrapper = styled.section`
display: grid;
place-items: center;

.error-msg{
  padding: 3rem;
} 
`;

export default FreePatterns;
