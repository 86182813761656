import { useParams, Link, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import defaultImg from '../assets/productImageDefault.png';
import React, { useState, useEffect } from 'react';
import { FaAngleRight, FaAngleLeft, FaArrowLeft } from 'react-icons/fa';
import { formatPrice } from '../utils/functions';
import { Loading } from '../components';
import { fetchSingleProduct, earnPattern } from '../utils/axiosRequests';
import { useProductsContext } from '../context/products_context';
import { useAuthContext } from '../context/auth_context';

function useQuery() {
  let query = new URLSearchParams(useLocation().search);
  return query.get('mark');
}

const SingleProduct = () => {
  const navigate = useNavigate();
  const { productId } = useParams();
  const mark = useQuery();

  const [error, setError] = useState('')
  const [currentIndex, setCurrentIndex] = useState(0);
  const [product, setProduct] = useState({});
  const [addToCart, setAddToCart] = useState(false);
  const [zoomIn, setZoomIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const { addToBag } = useProductsContext();
  const { user } = useAuthContext();


  const imgSortHero = (imgs) => {
    const hero = imgs.filter(i => i.hero)
    const notHero = imgs.filter(i => !i.hero)

    return [...hero, ...notHero]
  }


  const fetchData = async () => {
    setLoading(true);
    const productFetch = await fetchSingleProduct(productId);

    if (productFetch.status === 'error') {
      setError(productFetch.msg)
      setLoading(false)
      return
    }

    setProduct(productFetch);
    setLoading(false);
    return;
  };

  useEffect(() => {
    fetchData();
  }, []);

  const previusBtn = () => {
    let i = currentIndex - 1;
    if (i < 0) {
      i = product.imgKeyList.length - 1;
    }
    setCurrentIndex(i);
  };

  const nextBtn = () => {
    let i = currentIndex + 1;
    if (i > product.imgKeyList.length - 1) {
      i = 0;
    }

    setCurrentIndex(i);
  };

  const handleBuyClick = async (price) => {
    if (Number(price) === 0) {
      if (user && user.role === 'user') {
        setLoading(true)
        const earnPatternResponde = await earnPattern({ productId })

        if (earnPatternResponde.status === 'error') {
          setError(earnPatternResponde.msg)
          setLoading(false)
          return;
        }

        setLoading(false)
        return navigate(`/private/meusmoldes`);;
      } else {
        alert('Você precisa logar para ganhar este molde.')
        return navigate('/logar');
      }
    }
    addToBag(productId);
    setAddToCart(true);
  };

  const showBtns = (price) => {
    if (!addToCart) {
      return (
        <button
          type="button"
          className="btn btn-box btn-buy"
          onClick={() => handleBuyClick(price)}
        >
          {price > 0 ? 'Comprar' : 'Ganhar'}
        </button>
      );
    }
    return (
      <div className='btn-checkout-container'>
        <Link to="/services/pronta" className="btn btn-box">
          Adicionar outro molde
        </Link>
        <Link to="/sacola" className="btn btn-box">
          Finalizar compra
        </Link>
      </div>
    );
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Wrapper>
      <article className="title-container">
        <h1 className="product-name">
          {product.productName}
          <span> - {product.category}/</span>
          <span>{product.subCategory}</span>
        </h1>
        <div className='back-link-container'>
          <Link to="/services/pronta" className="btn back-btn">
            {window.innerWidth > 790 ? 'Voltar' : <FaArrowLeft />}
          </Link>
        </div>
      </article>
      {error ? <div className='error-container'><p>{error}</p></div> : <div>

        <main className="main-container">
          <div className='img-table-container'>
            <div>
              {product.imgKeyList.length !== 0 ?
                <article className="img-container">
                  <div>
                    {/* <div className={zoomIn ? 'img-zoom-in-full' : null}
                      style={!zoomIn ? { cursor: 'zoom-in' } : { cursor: 'zoom-out' }}
                      onClick={() => setZoomIn(!zoomIn)}>
                        <div className={zoomIn ? 'img-zoom-in' : 'img-main'}> */}
                    <div className='img-main'>
                      {<img src={
                        imgSortHero(product.imgKeyList)[currentIndex].url
                          ? imgSortHero(product.imgKeyList)[currentIndex].url
                          : defaultImg
                      }
                      />}
                    </div>
                    {/* </div>
                    </div> */}
                  </div>

                  <div className="img-selectors">
                    {imgSortHero(product.imgKeyList).map((item, i) => (
                      <div
                        key={i}
                        className={currentIndex === i ? 'img-small active' : 'img-small'}
                        onClick={() => setCurrentIndex(i)}
                      >
                        <img
                          src={
                            item.url
                              ? item.url
                              : defaultImg
                          }
                          alt={`Imagem-${currentIndex}`}
                        />

                      </div>
                    ))}
                  </div>


                  {product.imgKeyList.length > 1 && <>
                    <button type="button" className="np-btn np-left" onClick={previusBtn}>
                      <FaAngleLeft />
                    </button>
                    <button type="button" className="np-btn np-right" onClick={nextBtn}>
                      <FaAngleRight />
                    </button>

                  </>
                  }

                </article>

                :
                <div className='img-small'>
                  <img
                    src={
                      defaultImg
                    }
                    alt={`Imagem padrão`}
                  />
                </div>
              }
            </div>

            <div className="table-container" id="table">
              <img
                src={product.tableKey ? product.tableKey : defaultImg}
                alt="Tabela de medidas"
              />
            </div>
          </div>

          <article className="content">
            <div className="text-content">
              <h4>
                descrição: <span className='text'>{product.description}</span>
              </h4>
            </div>
            <div className="text-content">
              <h4>
                sugestões de tecidos: { }
                {product.tissueSuggestion.map((item, index) => (
                  <span key={item} className='text'>
                    {item}
                    {product.tissueSuggestion.length - 1 === index ? '.' : ', '}
                  </span>
                ))}
              </h4>
            </div>
            <div className="text-content">
              <h4>
                preço: <span className='text'>{product.price === 0 ? "Gratuito" : formatPrice(product.price * (1 - product.off / 100))}</span>
              </h4>
            </div>
          </article>
          <div className='btn-buy-container'>
            {mark === 'true' ?
              <button type='bottom' className='btn btn-box btn-buy' disabled>Já tenho</button> :
              showBtns(product.price)
            }
          </div>
        </main>
      </div>}
    </Wrapper>
  );
};

const Wrapper = styled.section`
width: 90vw;
max-width: 1300px;
margin: 0 auto;
margin-top: 4rem;

.error-container {
  height: calc(100vh - 19.5rem);
  display: grid;
  place-items: center;
}

.error-container p {
  font-size: 1.1rem;
}


.title-container {
  margin-top: 7rem;
  display: grid;
  grid-template-columns: 1fr 25px;
  padding-bottom: 1rem;
}

h1 {
  font-size: 1rem;
}

h1 span {
  font-size: 0.8rem;
}

.back-link-container {
  width: 25px;
  height: 25px;
}

.back-btn {
  display: grid;
  place-items: center;
  padding: 0.3rem;
  border-radius: var(--borderRadius);
  font-size: 0.9rem;
}

.img-container {
  width: 90%;
  margin: 1.5rem auto;
  position: relative;
}

.img-main {
  background: var(--grey-103);
  height: 25rem;
  border-radius: var(--borderRadius);
}

.img-main img {
  object-fit: scale-down;
  height: 25rem;
  padding: 1rem;
}

// .img-zoom-in-full {
//   background: var(--grey-200);
//   position: fixed;
//   z-index:100;
//   top: 0;
//   left: 0;
//   bottom: 0;
//   right: 0;
// }

// .img-zoom-in {
//   position: absolute;
//   top: 2rem;
//   left: 50%;
//   transform: translateX(-50%);
//   height: calc(100vh - 5.3rem);
//   background-color: var(--grey-103);
// }

// .img-zoom-in img {
//   height: calc(100vh - 5.3rem);
//   padding: 2rem;
//   object-fit: scale-down;
// }


.img-selectors {
  margin-top: 0.5rem;
  display: flex;
  height: 5rem;
  overflow: hidden;
}

.img-small {
  width: 5rem;
}

.img-small img {
  height: 5rem;
  padding: 0.5rem;
}

.active {
  background: var(--grey-103);
}

.np-btn {
  position: absolute;
  top: 12.5rem;
  transform: translateY(-50%);
  z-index: 1;
  outline: none;
  border: none;
  opacity: 0.2;
  width: 1.7rem;
  height: 2.3rem;
  font-size: 1.5rem;
  cursor: pointer;
  transition: var(--transition-1);
  display: grid;
  place-items: center;
}

.np-left {
  left: -1.7rem
}

.np-right {
  right: -1.7rem
}

.np-btn:hover {
  opacity: 1;
}

.content {
  margin-top: 1.5rem;
}

.table-container {
  width: 90%;
  height: 100%;
  margin: 2.5rem auto;
}

.text-content {
  padding: 0.3rem 0;
}

.text {
  font-size: 0.8rem;
  text-transform: none;
}

.btn-buy-container {
  margin: 2rem 0;
  display:grid;
  place-items: center;
}

.btn-checkout-container {
  display: block;
}

.btn-box {
  margin: 0.5rem 0;
  display:block;
  font-size: 1rem;
  width: 100%;
  text-align: center;
}

.btn-buy {
  width: 10rem;
  text-transform: uppercase;
  padding: 1rem 0;
}


@media screen and (min-width: 550px) {
  h1 {
    font-size: 1.5rem;
  }
  
  h1 span {
    font-size: 0.9rem;
  }

}

@media screen and (min-width: 790px) {
  h1 {
    font-size: 2rem;
  }
  
  h1 span {
    font-size: 1rem;
  }

  .title-container {
    grid-template-columns: 1fr 7rem;
    margin-top: 8rem;
  }

  .back-link-container{
    width: 7rem;
    height: 2.5rem;
  }

  .back-btn {
    padding: 0.3rem 0.6rem;
    font-size: 1rem;
    text-transform: uppercase;
  }

  .img-table-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem
  }
  
  .img-container {
    margin: 0 auto;
    width: 90%;
  }
  
  .img-main {
    height: 25rem;
  }
  
  .img-main img {
    height: 100%;
  }
  
  .table-container {
    width: 80%;
    margin: 0 auto;
  }

  .table-container img {
    width: 100%;
  }

  .content {
    margin-top: 2rem;
    width: 50%;
  }
 

}
`;

export default SingleProduct;
