import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Loading, AdminProductDisplay } from '../components';
import {
  FaPlus,
  FaMinus,
  FaFilePdf,
  FaTable,
  FaAddressBook,
  FaTimes,
  FaPen,
  FaAngleDown,
  FaImages,
  FaAngleUp,
} from 'react-icons/fa';
import { createEmailMarketing } from '../utils/emailMarketingBody';
import { categories } from '../utils/productsCategories'
import {
  fetchSingleProductAdmin,
  uploadFile,
  sendEmailMarketing,
  checkExistProduct,
  updateProduct,
  createProduct,
  getSubCategories,
  createSubCategory,
  deleteSubCategory,
  updateSubCategory
} from '../utils/axiosRequests';

let initialStateStd = {
  productName: '',
  price: 0,
  off: 0,
  category: '',
  subCategory: '',
  featured: false,
  sendEmail: false,
  description: '',
  tableKey: '',
  tissueSuggestion: [],
  imgKeyList: [],
  patterns: [],
};

const AdminMolde = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState('');
  const [showMsg, setShowMsg] = useState([]);
  const [msgModal, setMsgModal] = useState(false);
  const [modalSubCategory, setModalSubCategory] = useState(false);
  const [subCategoryId, setSubCategoryId] = useState('');
  const [toggleSelect, setToggleSelect] = useState(false);
  const [selectValue, setSelectValue] = useState('');
  const [createEditSubCategory, setCreateEditSubCategory] = useState('');
  const [editProduct, setEditProduct] = useState(null);

  const [products, setProducts] = useState([]);
  const [productState, setProductState] = useState(initialStateStd);
  const [tissue, setTissue] = useState('');
  const [subCategoriesOptions, setSubCategoriesOptions] = useState([]);
  const [previewTable, setPreviewTable] = useState({
    preview: '',
    file: null,
  });
  const [previewImgs, setPreviewImgs] = useState([]);
  const [previewPdf, setPreviewPdf] = useState([]);
  const [sendEmail, setSendEmail] = useState(true);
  const [featured, setFeatured] = useState(false);
  const inputReference = useRef(null);
  const subCategoryBtnRef = useRef(null);
  const pdfTypes = ['A4', 'Plotter', 'Ficha Técnica', 'Erro no nome do arquivo']

  const updataInitialState = async () => {
    setLoading(true);
    const fetchResult = await fetchSingleProductAdmin(id);

    if (fetchResult.status === 'error') {
      console.log('err')
      setShowMsg([...showMsg, { status: 'error', msg: fetchResult.msg }]);
      setMsgModal(true);
      setLoading(false);
      return;
    }

    let {
      productName,
      price,
      off,
      description,
      category,
      subCategory,
      featured,
      sendEmail,
      tissueSuggestion,
      tableKey,
      imgKeyList,
      patterns,
    } = fetchResult.product;

    const updateInitialState = {
      productName,
      price,
      off,
      description,
      category,
      subCategory,
      featured,
      sendEmail,
      tissueSuggestion: [...tissueSuggestion],
      tableKey,
      imgKeyList: [...imgKeyList],
      patterns: [...patterns],
    };

    const tableKeyStd = {
      preview: tableKey,
      file: null,
    };
    const imgKeyListStd = imgKeyList.map((img) => {
      return {
        preview: img.url,
        file: null,
        hero: img.hero,
      };
    });
    const patternsPreview = patterns.map((fileName) => {
      return { preview: fileName, file: null };
    });

    setProductState(updateInitialState);
    setSelectValue(subCategory)
    setPreviewTable(tableKeyStd);
    setPreviewImgs([...imgKeyListStd]);
    setPreviewPdf([...patternsPreview]);
    setLoading(false);
    return;
  };

  const fetchSubCategories = async () => {
    const subCategoriesResult = await getSubCategories();

    if (subCategoriesResult.status === 'error') {
      setShowMsg([...showMsg, { status: 'error', msg: subCategoriesResult.msg }]);
      setMsgModal(true);
      return;
    }

    setSubCategoriesOptions([...subCategoriesResult])
  }

  const setPDFCategories = (patterns) => {
    const filesSplited = patterns.map((item) => {
      if (item.preview.toLowerCase().includes('ficha tecnica') ||
        item.preview.toLowerCase().includes('ficha técnica')
      ) {
        return { type: 'Ficha Técnica', item }
      } else {

        const size = item.preview.split('-').map(i => i.trim())[2];

        if (item.preview.toLowerCase().includes('ploter') ||
          item.preview.toLowerCase().includes('ploter')
        ) {
          return { type: 'Plotter', size, item }
        }

        if (item.preview.toLowerCase().includes('a4')) {
          return { type: 'A4', size, item }
        }

        return { type: 'Erro no nome do arquivo', size: item.preview, item }
      }
    });

    return filesSplited;
  }

  useEffect(() => {
    if (id !== 'new') {
      updataInitialState();
    }

    fetchSubCategories();
  }, []);

  const handleCategoryChange = (e) => {
    setProductState(prev => {
      return { ...prev, category: e.target.value }
    })
    return;
  }

  const handleCreateEditChange = (e) => {
    setCreateEditSubCategory(e.target.value.toLowerCase());
    return;
  }

  const handleSubCategorySubmit = async () => {
    setLoadingModal(true);

    let subCategoryApiResponse;

    const checkExistingSubCategory = () => {
      const checkResult = subCategoriesOptions.filter(item =>
        item.subCategory.toLowerCase() === createEditSubCategory.toLowerCase())

      if (checkResult.length !== 0) {
        setLoadingModal(false);
        setModalSubCategory(false);
        setSelectValue(createEditSubCategory);
        return true;
      };
      return false;
    }

    if (subCategoryBtnRef.current === 'add') {
      if (checkExistingSubCategory()) return;

      subCategoryApiResponse = await createSubCategory({ subCategory: createEditSubCategory.toLowerCase() })

      if (subCategoryApiResponse.status === 'error') {
        setShowMsg([...showMsg, { status: 'error', msg: subCategoryApiResponse.msg }]);
        setModalSubCategory(false);
        setMsgModal(true);
        setLoadingModal(false);
        return;
      }
      setSelectValue(subCategoryApiResponse.subCategory);
      setSubCategoriesOptions([...subCategoriesOptions, subCategoryApiResponse])
    }

    if (subCategoryBtnRef.current === 'edit' && subCategoryId) {
      if (checkExistingSubCategory()) return;

      subCategoryApiResponse = await updateSubCategory(subCategoryId, { subCategory: createEditSubCategory.toLowerCase() })

      if (subCategoryApiResponse.status === 'error') {
        setShowMsg([...showMsg, { status: 'error', msg: subCategoryApiResponse.msg }]);
        setModalSubCategory(false);
        setMsgModal(true);
        setLoadingModal(false);
        return;
      }

      setSelectValue(subCategoryApiResponse.subCategory);
      const newSubCategories = subCategoriesOptions.filter(item => item._id !== subCategoryId)
      setSubCategoriesOptions([...newSubCategories, subCategoryApiResponse])
    }

    if (subCategoryBtnRef.current === 'delete' && subCategoryId) {
      subCategoryApiResponse = await deleteSubCategory(subCategoryId)

      if (subCategoryApiResponse.status === 'error') {
        setModalSubCategory(false);
        setShowMsg([...showMsg, { status: 'error', msg: subCategoryApiResponse.msg }]);
        setModalSubCategory(false);
        setLoadingModal(false);
        return;
      }

      const newSubCategories = subCategoriesOptions.filter(item => item._id !== subCategoryId)
      setSubCategoriesOptions([...newSubCategories])
      setSelectValue();
    }

    setCreateEditSubCategory('');
    subCategoryBtnRef.current = ''
    setLoadingModal(false)
    setModalSubCategory(false)
    return;
  }

  const handleEditSubCategory = (item) => {
    setCreateEditSubCategory(item.subCategory);
    setSubCategoryId(item._id)
    setModalSubCategory(true)
  }

  const subCategoryModalTitle = () => {
    if (subCategoryBtnRef.current === 'add') return 'Criar sub categoria';
    if (subCategoryBtnRef.current === 'edit') return 'Editar sub categoria';
    if (subCategoryBtnRef.current === 'delete') return 'Excluir sub categoria';
    return '';
  }

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'price' && Number(value) === 0) {
      setFeatured(false);
      setProductState({ ...productState, [name]: value, off: 0, featured: false });
      return
    }

    setProductState({ ...productState, [name]: value });
    return;
  };

  const handleTissueClick = (newTissue) => {

    if (!newTissue) return;
    const tissueList = new Set([...productState.tissueSuggestion, newTissue]);

    setProductState({ ...productState, tissueSuggestion: [...tissueList] });
    setTissue('');
    return;
  };

  const handleMinusClick = (r) => {
    let deleteTissueSuggestion = productState.tissueSuggestion.filter(
      (item) => item !== r
    );
    setProductState({
      ...productState,
      tissueSuggestion: deleteTissueSuggestion,
    });
    return;
  };

  const handleTableChange = (e) => {
    setPreviewTable({
      preview: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0],
    });
    return;
  };

  const handleImgsChange = (e) => {
    const imgs = Array.from(e.target.files).map((file, i) => {
      let hero;
      if (i === 0 && previewImgs.length === 0) {
        hero = true
      } else {
        hero = false
      };
      return { preview: URL.createObjectURL(file), file, hero };
    });

    setPreviewImgs((prev) => [...prev, ...imgs]);
    return;
  };

  const removeImg = (imgUrl) => {
    let newPreviewArray = previewImgs.filter((item) => item.preview !== imgUrl);
    if (newPreviewArray.length !== 0) newPreviewArray[0].hero = true;
    setPreviewImgs(newPreviewArray);
    return;
  };

  const handleHeroImg = (imgUrl) => {
    let newPreviewArray = previewImgs.map((item) => {
      if (item.preview === imgUrl) {
        return { ...item, hero: true };
      } else {
        return { ...item, hero: false };
      }
    });
    setPreviewImgs(newPreviewArray);
    return;
  };

  const handlePdfChange = (e) => {
    const files = Array.from(e.target.files).map((file) => {
      return { preview: file.name, file };
    });
    setPreviewPdf((prev) => [...prev, ...files]);
    return;
  };

  const removePdf = (pdfName) => {
    const newPreviewArray = previewPdf.filter(
      (item) => item.preview !== pdfName
    );

    setPreviewPdf(newPreviewArray);
    return;
  };

  const handleEditProduct = (i) => {
    setEditProduct(i);
    setProductState(products[i]);
    setSelectValue(products[i].subCategory);
    setPreviewTable(products[i].tableKey);
    setPreviewImgs(products[i].imgKeyList);
    setPreviewPdf(products[i].patterns);
    setSendEmail(products[i].sendEmail);
    setFeatured(products[i].featured);
    const filteredProducts = products.filter((_, index) => index !== i);
    setProducts(filteredProducts);
    return;
  };

  const handleProductDelete = (i) => {
    const filteredProducts = products.filter((_, index) => index !== i);
    setProducts(filteredProducts);
    return;
  };

  // generate folder name to save files
  const folderName = (productName, category, subCategory) => {
    return `${productName.toLowerCase().split(' ').join('-')}-${category.toLowerCase()}-${subCategory.toLowerCase()}`;
  };

  // select new files and append to form data
  const newFilesFormData = (field, fileArray, formData) => {
    let allNewFiles = [];

    // separating new files
    fileArray.forEach((img) => {
      if (img.file) allNewFiles.push(img);
    });

    // if there is some new file
    if (allNewFiles.length !== 0) {
      allNewFiles.forEach((file) => {
        formData.append(`${field}`, file.file);
        //if (field === 'imgs') formData.append(`hero`, file.hero);
      });
      return formData;
    }

    return formData;
  };

  // create msg
  const handleMsg = (status, msg) => {
    setShowMsg((prev) => [
      ...prev,
      {
        status,
        msg,
      },
    ]);
    setLoading(false);
    setMsgModal(true);
    return;
  };

  // check fields form
  const checkFieldsForm = () => {
    const { productName, price, off, category, subCategory, description, tissueSuggestion } = productState;

    if (!productName) { handleMsg('error-fill', 'O nome do produto é obrigatório.'); return false; }
    if (price < 0) { handleMsg('error-fill', 'O preço é obrigatório e o valor deve ser positivo.'); return false; }
    if (off < 0 || off > 100) { handleMsg('error-fill', 'O desconto é obrigatório e o valor deve ser maior que zero e menor que 100.'); return false; }
    if (!description) { handleMsg('error-fill', 'A descrição do produto é obrigatória.'); return false; }
    if (!category) { handleMsg('error-fill', 'Selecione uma categoria.'); return false; }
    if (!subCategory) { handleMsg('error-fill', 'Selecione uma sub categoria.'); return false; }
    if (tissueSuggestion.length === 0) { handleMsg('error-fill', 'Insira pelo menos uma sugestão de tecido.'); return false; }
    if (!previewTable.preview) { handleMsg('error-fill', 'A tabela de medidas é obrigatória.'); return false; }
    if (previewImgs.length === 0) { handleMsg('error-fill', 'Insira pelo menos uma imagem.'); return false; }
    if (previewPdf.length === 0) { handleMsg('error-fill', 'Insira pelo menos um arquivo.'); return false; }

    return true;
  }

  const handleAddPattern = () => {
    const { productName, price, off, category, subCategory, description, tissueSuggestion } = productState;

    if (!checkFieldsForm()) return;

    const newProduct = {
      productName: productName.toLowerCase(),
      price,
      off,
      description: description.toLowerCase(),
      category: category.toLowerCase(),
      subCategory: subCategory.toLowerCase(),
      featured,
      sendEmail,
      tissueSuggestion,
      tableKey: previewTable,
      imgKeyList: previewImgs,
      patterns: previewPdf,
    };

    // edit new product before create
    if (editProduct) {
      handleProductDelete(editProduct);
      setEditProduct(null);
    }

    setProducts((prev) => [...prev, newProduct]);
    setProductState(initialStateStd);
    setSelectValue('');
    setSendEmail(true);
    setFeatured(false);
    setPreviewTable({});
    setPreviewImgs([]);
    setPreviewPdf([]);
    return window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  // Submit form
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // ++++++++++  Update  +++++++++++
    if (id !== 'new') {
      const { productName, price, off, category, subCategory, description, tissueSuggestion } = productState;
      if (!checkFieldsForm()) return;

      // check if name and ref changed
      if (
        initialStateStd.productName !== productName ||
        initialStateStd.category !== category ||
        initialStateStd.subCategory !== subCategory
      ) {
        // check for existing name and ref before update
        const checkResult = await checkExistProduct(
          productName,
          category,
          subCategory,
          id,
          'update'
        );
        if (checkResult.status === 'error') {
          handleMsg('error', checkResult.msg);
          setMsgModal(true);
          return;
        }
      }

      let newFilesUpdateProduct = new FormData();
      const imgs = previewImgs.map(item => {
        return { url: item.preview, hero: item.hero };
      })
      let newFilesUpdated = { tableKey: previewTable.preview, imgKeyList: imgs, patterns: [] };

      newFilesUpdateProduct.append(
        'folderName',
        folderName(productName, category, subCategory)
      );
      newFilesUpdateProduct = newFilesFormData(
        'tableKey',
        [previewTable],
        newFilesUpdateProduct
      );
      newFilesUpdateProduct = newFilesFormData(
        'imgs',
        previewImgs,
        newFilesUpdateProduct
      );
      newFilesUpdateProduct = newFilesFormData(
        'patterns',
        previewPdf,
        newFilesUpdateProduct
      );

      if (
        newFilesUpdateProduct.has('tableKey') ||
        newFilesUpdateProduct.has('imgs') ||
        newFilesUpdateProduct.has('patterns')
      ) {
        // Saving new files
        const uploadFilesResult = await uploadFile(newFilesUpdateProduct);

        const { tableId, imgsId, pdfId, errorMsg } = uploadFilesResult;

        const table = tableId.length === 0 ? previewTable.preview : tableId[0];

        let newImgs = [...imgsId];
        let i = 0;

        const imgs = previewImgs.map(item => {
          if (item.file) {
            item.preview = newImgs[i];
            i++
          }
          return { url: item.preview, hero: item.hero };
        })

        newFilesUpdated = {
          tableKey: table,
          imgKeyList: imgs,
          patterns: pdfId,
        };

        if (errorMsg.length !== 0) {
          setShowMsg((prev) => [...prev, ...errorMsg]);
        }
      }

      const tableKeyToDelete = previewTable.file
        ? [{ type: 'table', string: initialStateStd.tableKey }]
        : [];

      const imgsToDelete = initialStateStd.imgKeyList
        .filter(
          (item) =>
            !previewImgs
              .map((img) => !img.file && img.preview)
              .includes(item.url)
        )
        .map((i) => {
          return { type: 'imgs', string: i.url };
        });
      const pdfToDelete = initialStateStd.patterns
        .filter(
          (item) =>
            !previewPdf.map((pdf) => !pdf.file && pdf.preview).includes(item)
        )
        .map((i) => {
          return { type: 'pdf', string: i };
        });

      const filesToDelete = [
        ...tableKeyToDelete,
        ...imgsToDelete,
        ...pdfToDelete,
      ];

      const newProductUpdate = {
        productName: productName.toLowerCase(),
        price,
        off,
        description: description.toLowerCase(),
        category: category.toLowerCase(),
        subCategory: subCategory.toLowerCase(),
        featured,
        sendEmail,
        tissueSuggestion,
        tableKey: newFilesUpdated.tableKey,
        imgKeyList: newFilesUpdated.imgKeyList,
        patterns: newFilesUpdated.patterns,
        filesToDelete,
      };

      const updateProductResult = await updateProduct(id, newProductUpdate);

      if (updateProductResult.status === 'error') {
        handleMsg('error', updateProductResult.msg);
        setLoading(false)
        setMsgModal(true);
        return;
      }

      setProductState(initialStateStd);
      setProducts([]);
      for (let msg of updateProductResult.msg) {
        setShowMsg((prev) => [
          ...prev,
          {
            status: msg.status,
            msg: msg.msg,
          },
        ]);
      }
      setMsgModal(true);
      setLoading(false);
      return;
    }

    // ++++++++++  Create  +++++++++++
    if (id === 'new') {
      let emailMarketingList = [];
      let troubleProducts = [...products]
      let n = 1;

      if (products.length === 0) return;

      // check for uniqueness when create new product
      for (let newProduct of products) {
        setLoadingMsg(
          `Conferindo unicidade do produto ${n}/${products.length}`
        );

        const checkResult = await checkExistProduct(
          newProduct.productName,
          newProduct.category,
          newProduct.subCategory,
          '',
          'create'
        );

        if (checkResult.status === 'error') {
          setShowMsg((prev) => [...prev, checkResult]);
          setMsgModal(true);
          setLoading(false);
          return;
        }
        n++
      }

      n = 1;
      for (let newProduct of products) {
        setLoadingMsg(`Salvando arquivos na nuvem - ${n}/${products.length}`);
        // saving files
        let newProductFilesFormData = new FormData();

        newProductFilesFormData.append(
          'folderName',
          folderName(newProduct.productName, newProduct.category, newProduct.subCategory)
        );

        newProductFilesFormData = newFilesFormData(
          'tableKey',
          [newProduct.tableKey],
          newProductFilesFormData
        );

        newProductFilesFormData = newFilesFormData(
          'imgs',
          newProduct.imgKeyList,
          newProductFilesFormData
        );
        newProductFilesFormData = newFilesFormData(
          'patterns',
          newProduct.patterns,
          newProductFilesFormData
        );

        const uploadFilesResult = await uploadFile(newProductFilesFormData);

        const {
          tableId,
          imgsId,
          pdfId,
          errorMsg,
        } = uploadFilesResult;

        if (errorMsg.length !== 0) {
          setShowMsg((prev) => [...prev, ...errorMsg]);
        }

        let newImgs = [...imgsId];
        let i = 0;

        const imgs = newProduct.imgKeyList.map(item => {
          if (item.file) {
            item.preview = newImgs[i];
            i++
          }
          return { url: item.preview, hero: item.hero };
        })

        newProduct = {
          ...newProduct,
          tableKey: tableId[0],
          imgKeyList: imgs,
          patterns: pdfId,
        };

        setLoadingMsg(`Criando produto ${n}/${products.length}`);
        const createResult = await createProduct(newProduct);

        if (createResult.status === 'error') {
          setShowMsg((prev) => [
            ...prev,
            {
              status: 'error',
              msg: createResult.msg,
            },
          ]);
        } else {
          const {
            id,
            sendEmail,
            productName,
            category,
            subCategory,
            price,
            off
          } = createResult;

          const heroImgLocation = imgs.filter(item => item.hero)[0].url;

          if (sendEmail)
            emailMarketingList.push({
              id,
              productName,
              price,
              off,
              heroImgLocation,
            });

          setShowMsg((prev) => [
            ...prev,
            {
              status: 'ok',
              msg: `${productName}-${category}-${subCategory} criado com sucesso.`,
            },
          ]);

          const productNameCategory = `${productName}-${category}-${subCategory}`

          troubleProducts = troubleProducts.filter(
            (item) => `${item.productName}-${item.category}-${item.subCategory}` !== productNameCategory
          );

        }
        n++;
      }


      if (emailMarketingList.length !== 0) {
        setLoadingMsg(`Enviando email marketing`);
        const emailBody = createEmailMarketing(emailMarketingList);
        const emailResult = await sendEmailMarketing(emailBody);

        setShowMsg((prev) => [...prev, emailResult]);
      }

      setProducts(troubleProducts)
    }


    setLoading(false);
    setMsgModal(true);
  };

  if (loading) {
    return <Loading loadingMsg={loadingMsg} />;
  }

  return (
    <Wrapper className="page-width page-height">
      <nav>
        <div className="title-add-pattern">
          {id !== 'new' ? 'Atualizar' : 'Adicionar'} Molde
        </div>

        <div className="controllers" onClick={() => setProductState(initialStateStd)}>
          <Link className="btn" to="/private/admin">
            Voltar
          </Link>
        </div>
      </nav>

      {msgModal ? (
        <div className='modal-container'>
          <div className='subcategory-modal-box'>
            <div className='modal-display-flex'>
              <h2>Informes</h2>
              <button type='button' className='modal-btn'
                onClick={() => {
                  const showMsgEmpty = showMsg.length !== 0 && showMsg[0];
                  setShowMsg([]);
                  setMsgModal(false);
                  // required fields empty msg
                  if (showMsgEmpty.status === 'error-fill') {
                    return;
                  }
                  if (products.length === 0) {
                    // All products created or updated successfully
                    return navigate('/private/admin');
                  }
                  // Some product with error
                  return;
                }}
              ><FaTimes /></button>
            </div>

            <div className='modal-msg-container'>
              {showMsg.map((item, i) => {
                return (
                  <p
                    className={item.status === 'ok' ? 'success-msg' : 'error-msg'}
                    key={i}
                  >
                    {item.msg}
                  </p>
                );
              })}
            </div>
          </div>
        </div>
      ) : null}

      {id === 'new' && (
        <section className="pattern-list">
          <div className='pattern-list-main'>
            <h3>Lista de moldes gerados</h3>
            <div
              className="add-focus-btn"
              onClick={() => {
                inputReference.current.focus();
              }}
            ><FaPlus />
            </div>
          </div>

          <AdminProductDisplay
            products={products}
            handleEditProduct={handleEditProduct}
            handleProductDelete={handleProductDelete}
          />

          {products.length > 0 && (
            <div className="save-all-btn-container">
              <button
                form="create-form"
                type="submit"
                className="btn save-btn-all"
                onClick={handleSubmit}
              >
                Adicionar ({products.length}) moldes
              </button>
            </div>
          )}
        </section>
      )}

      {modalSubCategory ? <div className='modal-container'>
        <div className='subcategory-modal-box'>
          {loadingModal ? <Loading /> : <>
            <div className='modal-display-flex'>
              <h2>{subCategoryModalTitle()}</h2>
              <button type='button' className='modal-btn' onClick={() => {
                setModalSubCategory(false);
                setCreateEditSubCategory('');
                subCategoryBtnRef.current = '';
              }}><FaTimes /></button>
            </div>

            {subCategoryBtnRef.current === 'delete' ? <p className='modal-sub-category-main'>Deseja excluir a sub categoria {createEditSubCategory}</p> :
              <div className='modal-sub-category-main'>
                <label>Nome da sub categoria</label>
                <input type='text' onChange={handleCreateEditChange} value={createEditSubCategory} />
              </div>}
            <button type='button' className='modal-btn modal-submit-btn' onClick={() => handleSubCategorySubmit()}>
              {subCategoryBtnRef.current === 'delete' ? 'Excluir' :
                'Salvar'}
            </button>
          </>
          }</div>
      </div> : null}

      <form className="form-container" >
        <div className="form-first-container">
          <div className="space-height">
            <label htmlFor="productName">Nome: </label>
            <input
              style={{ width: '70%' }}
              type="text"
              id="productName"
              name="productName"
              required
              ref={inputReference}
              value={productState.productName}
              onChange={handleChange}
            />
          </div>
          <div className='space-height price-off-container'>
            <div className="price">
              <label htmlFor="price">Preço: </label>
              <input
                type="number"
                id="price"
                name="price"
                min="0"
                required
                value={productState.price}
                onChange={handleChange}
              />
            </div>
            <div className="off">
              <label htmlFor="off">Off(%): </label>
              <input
                type="number"
                id="off"
                name="off"
                min="0"
                max='100'
                required
                disabled={Number(productState.price) === 0 ? true : false}
                value={productState.off}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="space-height">
            <label>Categoria</label>
            <select onChange={handleCategoryChange} value={productState.category}>
              <option></option>
              {categories.map((item, index) => <option key={index}>{item.category}</option>)}
            </select>
          </div>

          <div className="space-height subcategory-container">
            <label>Sub Categoria</label>
            <div className='select' onClick={() => setToggleSelect(!toggleSelect)}>
              <div style={{ textTransform: 'capitalize' }}>{selectValue}</div>
              <div >{toggleSelect ? <FaAngleUp /> : <FaAngleDown />}</div>
              {toggleSelect ?
                <div className='select-option-container'>
                  {subCategoriesOptions.length !== 0 ? subCategoriesOptions
                    .sort((a, b) => a.subCategory.localeCompare(b.subCategory))
                    .map((item, index) =>
                      <div className='select-option select-option-display' onClick={() => {
                        setToggleSelect(false);
                        setSelectValue(item.subCategory);
                        setProductState(prev => { return { ...prev, subCategory: item.subCategory } })
                      }} key={index}><span style={{ textTransform: 'capitalize' }}>{item.subCategory}</span>
                        <button type='button' className='btn-controller'
                          onClick={() => {
                            handleEditSubCategory(item);
                            subCategoryBtnRef.current = 'edit';
                          }}><FaPen />
                        </button>
                        <button type='button' className='btn-controller'
                          onClick={() => {
                            handleEditSubCategory(item);
                            subCategoryBtnRef.current = 'delete';
                          }}><FaTimes />
                        </button>
                      </div>
                    ) : <div className='select-option' onClick={() => setToggleSelect(false)}></div>}
                </div>
                : null}
            </div>
            <button type='button' className='btn-controller' onClick={() => {
              setModalSubCategory(true);
              subCategoryBtnRef.current = 'add'
            }
            }><FaPlus /></button>
          </div>

          <div className="space-height">
            <label>Enviar email marketing</label>
            <input
              type="checkbox"
              name="send-email"
              id="send-email"
              disabled={id !== 'new' ? true : false}
              checked={sendEmail}
              onChange={() => setSendEmail(!sendEmail)}
            />
          </div>

          <div className="space-height">
            <label>Produto em destaque</label>
            <input
              type="checkbox"
              name="featured"
              id="featured"
              disabled={Number(productState.price) === 0 ? true : false}
              checked={featured}
              onChange={() => setFeatured(!featured)}
            />
          </div>
        </div>

        <div className="form-second-container">
          <div className='tissue-input-container'>
            <label htmlFor="tissue">Sugestão de tecidos: </label>
            <input
              type="text"
              name="tissue"
              id="tissue"
              value={tissue}
              onChange={(e) => setTissue(e.target.value)}
            />
            <button
              className="btn-controller"
              type="button"
              onClick={() => handleTissueClick(tissue)}
            >
              <FaPlus />
            </button>
          </div>
          <div className="tissues-display">
            {productState.tissueSuggestion.map((tissue, index) => (
              <p key={index} className='tissue-display-item'>{tissue}
                <button
                  className="btn-controller"
                  type="button"
                  onClick={() => handleMinusClick(tissue)}
                >
                  <FaMinus />
                </button>
              </p>

            ))}
          </div>
          <div className='description-table-container'>
            <div className='description-container'>
              <p htmlFor="description">Descrição: </p>
              <textarea
                className="description-textarea"
                name="description"
                rows="3"
                lang="pt"
                required
                value={productState.description}
                onChange={handleChange}
              ></textarea>
            </div>

            <div className="table">
              <label >Tabela de medidas: </label>
              <label htmlFor="table-file" className="input-file-label input-file-label-table">
                {previewTable.preview ? (
                  <img
                    src={previewTable.preview}
                    className="selected-file"
                    alt="Tabela de Medidas"
                  />
                ) : (
                  <FaTable />
                )}
              </label>

              <input
                type="file"
                id="table-file"
                accept="image/*"
                onChange={handleTableChange}
              />
            </div>
          </div>
        </div>

        <div className="form-third-container">
          <div className="files-label-container">
            <label>Selecione as imagens:</label>
            <label htmlFor="imgs" className="input-file-label input-file-label-img-pdf">
              <FaImages />
            </label>
            <input

              type="file"
              multiple
              name="imgs"
              id="imgs"
              accept="image/*"
              onChange={handleImgsChange}
            />
          </div>
          <div className="files-container">
            {previewImgs.map((img, i) => (
              <div key={i} className="file-container file-container-img">
                <img className="file" src={img.preview} alt={`Imagem ${i + 1}`} />
                <div className='file-button-container'>
                  <button
                    className="btn-controller"
                    type="button"
                    onClick={() => removeImg(img.preview)}
                  >
                    <FaMinus />
                  </button>
                  <button
                    className={img.hero ? 'btn-controller active' : 'btn-controller'}
                    type="button"
                    onClick={() => handleHeroImg(img.preview)}
                  >
                    <FaAddressBook />
                  </button>

                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="form-fourth-container">
          <div className="files-label-container">
            <label>Selecione os moldes:</label>
            <label htmlFor="pdf" className="input-file-label input-file-label-img-pdf">
              <FaImages />
            </label>
            <input
              className="input-file"
              type="file"
              multiple
              name="pdf"
              id="pdf"
              accept="pdf"
              onChange={handlePdfChange}
            />
          </div>
          <div className="pdf-container">
            {
              pdfTypes.map((type, j) => {
                return <div key={j} className='pdf-container-type'>
                  <div className='pdf-title'>
                    <h2>{type}</h2>
                  </div>
                  <div className='pdf-files-container'>
                    {setPDFCategories(previewPdf).map((pdf, i) => {
                      if (type === pdf.type) {
                        return <div key={i} className="file-container">
                          <div className="pdf-icon-container">
                            <FaFilePdf className="pdf-icon" />
                            <p className='pdf-p'>{pdf.size}</p>
                          </div>
                          <div className='file-button-container'>
                            <button
                              className="btn-controller"
                              type="button"
                              onClick={() => removePdf(pdf.item.preview)}
                            >
                              <FaMinus />
                            </button>
                          </div>
                        </div>
                      } else return
                    }
                    )}
                  </div>
                </div>
              })
            }
          </div>
        </div>

        <div className="save-btn-container">
          {id === 'new' ? (
            <button
              type="button"
              className="btn save-btn"
              onClick={handleAddPattern}
            >
              adicionar
            </button>
          ) : (
            <button type="submit" className="btn save-btn" onClick={handleSubmit}>
              atualizar
            </button>
          )}
        </div>
      </form>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  width: 90vw;
  max-width: 1300px;
  margin: 0 auto;

  nav {
    display: flex;
    justify-content: space-between;
    align-items:center;
    padding: 2rem 0;
  }

  .title-add-pattern{
    text-transform: uppercase;
    font-size: 1.5rem;
    color: var(--grey-main);
  }

  .pattern-list {
    background: var(--grey-700);
  }
  
  .pattern-list-main {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--grey-600);
  }

  .pattern-list-main h3 {
    font-size: 1.2rem;
  }

  .add-focus-btn {
    background: var(--grey-104);
    width: 2rem;
    height: 2rem;
    display:grid;
    place-items:center;
    cursor: pointer;
  }

  .form-container {
    margin: 3rem 0;
    display: grid;
    grid-gap: 2rem;
    grid-template-areas:
        'a b'
        'a b'
        'c c'
        'd d'
        'e e';
  }

  .form-first-container {
    grid-area: a;
  }

  .space-height {
    padding: 0.5rem 0;
  }

  .price-off-container{
    display:flex;
    justify-content:space-between;
  }

  .off,
  .price {
    width: 45%;
  }

  .off input,
  .price input {
    width: 7rem;
  }

  .subcategory-container {
    display: flex;
  }
  
  .select-option-container {
    z-index: 15;
  }

  .sub-category-option-btn {
    z-index: 15;
    cursor: pointer;
    width: 1.2rem;
    height: 1.2rem;
    font-size: 0.7rem;
  }

  .form-second-container {
    grid-area: b;
    display: grid;
  }

  .tissue-input-container{
    display: flex;
    align-items: center;
  }

  .tissues-display {
    display:grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    margin: 1rem 0;
    padding: 0.5rem;
    height: 5rem;
    overflow-y: scroll;
    max-width: 100%;
    background: var(--grey-103);
  }

  .btn-controller {
    margin-left: 1rem;
    width: 20px;
    height: 20px;
    display: grid;
    place-items: center;
    cursor: pointer;
    color: var(--white);
    background: var(--primary-main);
    border: transparent;
    border-radius: 50%;
    font-size: 0.5rem;
  }

  .btn-controller:hover {
    opacity: 0.7;
  }

  .tissue-display-item {
    display:flex;
  }
  
  .description-table-container {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 1fr 11rem;
    column-gap: 1rem;
  }

  .description-textarea {
    width: 100%;
    height: 8rem;
    margin-top:0.5rem;
  }

  .table {
    display:grid;
  }

  .input-file-label-table {
    width: 8rem;
    height: 8rem;
    justify-self: center;
    display:grid;
    place-items: center;
    color: var(--primary-main);
    font-size: 2rem;
  }

  .selected-file {
    width: 8rem;
    height: 8rem;
    object-fit: cover;
  }

  .form-third-container {
    grid-area: c;
  }

  .files-label-container {
    display:flex;
  }

  .input-file-label-img-pdf {
    width: 100px;
    margin-left: 1rem;
    display: grid;
    place-items: center;
    color: var(--primary-main);
    font-size: 1.1rem;
  }

  .files-container {
    margin: 1rem 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-gap: 1rem;
  }
  
  .pdf-container {
    margin-top: 1rem;
  }
  
  .pdf-container-type {
    margin-top: 1rem;
  }
  
  .pdf-title h2{
    font-size: 1.1rem;
    text-align: start;
  }

  .pdf-files-container {
    display:grid;
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    grid-gap: 1rem;
    padding: 1rem 0;
  }
  
  .file-container {
    position: relative;
  }

  .file-container-img {
    padding: 0.5rem;
  }
  
  .file-button-container {
    padding: 0.5rem;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 5;
    display: flex;
  }

  .active {
    background: green;
  }

  .file {
    height: 150px;
    object-fit: cover;
  }

  .form-fourth-container {
    grid-area: d;
  }

  .pdf-icon-container {
    position: relative;
    height: 150px;
    background: var(--grey-103);
  }

  .pdf-icon {
    position: absolute;
    width: 50px;
    height: 50px;
    top:35%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--primary-main);
  }

  .pdf-p {
    width: 100%;
    position: absolute;
    padding: 0.5rem;
    bottom: 0;
    font-size: 0.65rem;
    overflow: hidden;
  }

  .save-btn-container {
    grid-area: e;
    margin: 0 auto;
  }

  .save-all-btn-container {
    display:flex;
    justify-content: right;
  }

  .save-btn {
    padding: 1rem 2rem;
  }
  
  .save-btn-all {
    margin: 1rem;
    padding: 0.8rem 1.5rem;
  }

  .subcategory-modal-box {
    min-width: 30%;
    background: var(--grey-700);
    padding: 2.5rem;
  }

  .modal-display-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .modal-sub-category-main {
    margin: 2rem 0;
  }

  .subcategory-modal-box h2 {
    font-size: 1.3rem;
  }

  .modal-msg-container {
    margin: 2rem 0;
    line-height: 1.3rem;
    max-width: 70vw;
    font-size: 1rem;
  }

  .modal-msg-container p {
    margin: 0.2rem;
    padding: 0.3rem;
    font-size: 0.9rem;
  }

  .success-msg {
    background: var(--success-color);
  }
  
  .error-msg {
    background: var(--error-color);
  }
`;

export default AdminMolde;
