import styled from 'styled-components';

const Designers = ({ name, img, education, description }) => {
  return (
    <Wrapper>
      <div className="img-container">
        <img className="hero-img" src={img} alt={name} />
      </div>
      <div className="info">
        <h3>{name}</h3>
        <h5>{education}</h5>
        <p>{description}</p>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 5rem;
  background: var(--primary-300);
  margin-bottom: 2rem;

  .info {
    padding-left: 2rem;
  }

  .img-container {
    width: 100%;
    height: 100%;
  }

  .hero-img {
    width: 25rem;
    height: 25rem;
    object-fit: cover;
  }
`;

export default Designers;
