import styled from 'styled-components';
import { Register, Login, ForgotPassword } from '../pages';
import { useGlobalContext } from '../context/global_context';
import { useEffect, useState } from 'react';

const RegisterLoginModal = () => {
  const { alert, paymentPage, resetPasswordInPaymentPage } = useGlobalContext();
  const [registerLogin, setRegisterLogin] = useState(true)

  useEffect(() => {
    resetPasswordInPaymentPage(false)
  }, [])

  console.log(paymentPage)

  return (
    <Wrapper>
      {alert.show && (
        <div className={`alert alert-${alert.type}`}>{alert.text}</div>
      )}
      <div className="payment-user">
        <div>
          <button
            type='button'
            className='btn-register-login'
            onClick={() => setRegisterLogin(!registerLogin)}>
            {!registerLogin ? 'Cadastrar' : 'Logar'}
          </button>
        </div>
        <div className='register-login-container'>

          {registerLogin ? <Register location="payment" /> :
            !paymentPage ? <Login location="payment" /> : <ForgotPassword location="payment" />}
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
.register-login-container{
  display: grid;
}

.btn-register-login {
  outline: none;
  border: none;
  padding: 0.6rem 1.2rem;
  border-radius: var(--borderRadius);
  text-transform: uppercase;
  background: ;
  cursor: pointer;
}

.btn-register-login:hover {
  border-bottom: 1px solid var(--primary-main);
}


.payment-user {
  margin-top: 1rem;
  }
`;

export default RegisterLoginModal;
