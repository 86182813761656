import styled from 'styled-components';

const Filter = ({ filterState, handleCheckInput }) => {
    return <Wrapper>
        <div className='filter-header'>
            <h2>Filtrar</h2>
        </div>
        {filterState.length !== 0 && filterState.map((item, i) =>
            <div key={i}>
                <h3>{item.category}</h3>
                {item.subCategory.sort((a, b) => a.sub.localeCompare(b.sub)).map((subCategory, j) =>
                    <div key={j}>
                        <input type='checkbox' onChange={() => handleCheckInput(item.category, subCategory.sub)} checked={subCategory.checked} />
                        <label className='checkbox-label'>{subCategory.sub}</label>
                    </div>
                )}
            </div>)}
    </Wrapper>

};

const Wrapper = styled.section`
.filter-header {
    display: flex;
    justify-content: left;
}

.checkbox-label {
    margin-left: 0.5rem;
    text-transform: capitalize
  }
}
`

export default Filter;
