import { SET_USER, USER_VERIFICATION, REMOVE_USER } from '../actions';

const auth_reducer = (state, action) => {
  if (action.type === SET_USER) {
    return {
      ...state,
      user: action.payload,
    };
  }
  if (action.type === USER_VERIFICATION) {
    return {
      ...state,
      verification: action.payload,
    };
  }
  if (action.type === REMOVE_USER) {
    return {
      ...state,
      user: null,
    };
  }

  throw new Error(`No matching ${action.type} - action type`);
};

export default auth_reducer;
