import { baseClientURL } from './axiosRequests';
import { formatPrice } from './functions';

export function createEmailMarketing(array) {
  let carts = '';

  for (let item of array) {
    const { id,
      productName,
      price,
      off,
      heroImgLocation,
    } = item;

    const main = `<article
        style="
          max-width: 300px;
          padding: 1rem;
          border: 1px solid #ccc;
          background: #efefef;
          border-radius: 0.5rem;
          box-shadow: 2px 2px 10px 1px #ccc;
        "
      >
        <a
          style="text-decoration: none; color: #1a1a1a"
          href="${baseClientURL}/services/pronta/${id}"
        >
         <img
            style="
              object-fit: cover;
              display: block;
              width: 100%;
              height: 350px;
              "
            src=${heroImgLocation}
            alt="${productName}"
          />
          <div
            style="
              padding-top: 1rem;
            "
          >

            <p
              style="
              font-size: 1rem;
              margin-right: 0.5rem;
              text-transform: capitalize;
              "
            >
              ${productName}</p>
            <p style="font-size: 1rem">
              <span>${formatPrice(price * (1 - off / 100))}</span>
              ${off !== 0 ?
        `<span
                  style="
                font-size: 0.8rem;
                margin-left: 0.7rem;
                text-decoration: line-through;
                "
                >
                  ${formatPrice(price)}
                </span>`
        : ''}
              </p>
            </div>
        </a>
      </article>
      `;

    carts += main;
  }

  const body = `<section
      style="
        width: 85%;
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 2rem;
      "
    >
      ${carts}
    </section>`;
  return { emailBody: body };
}
