import { useState, useEffect } from 'react';
import { useProductsContext } from '../context/products_context';
import Loading from './Loading';
import { FaTimes } from 'react-icons/fa';
import styled from 'styled-components';
import defaultImg from '../assets/productImageDefault.png'
import { Payment } from '../components';
import { formatPrice } from '../utils/functions';

const ProductRowList = () => {
  const { bag, fetchSingleProduct, removeFromBag } = useProductsContext();
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [total, setTotal] = useState(0)

  const fetchProductsBag = async () => {
    setLoading(true);
    let newBag = [];

    if (bag.length !== 0) {
      for (let id of bag) {
        const fetchResult = await fetchSingleProduct(id);

        if (fetchResult.status === 'error') {
          const erroProduct = {
            _id: id,
            productName: 'Este produto está indisponível no momento.',
            price: 0,
            off: 0,
            category: '',
            subCategory: '',
            description: '',
            imgKeyList: defaultImg,
          }
          newBag = [...newBag, erroProduct]
        } else {
          newBag = [...newBag, fetchResult.product]
        }
      }

      const totalPrice = newBag.reduce((prev, curr) => prev + curr.price * (1 - curr.off / 100), 0);
      setTotal(totalPrice);
    }

    setProducts(newBag);
    setLoading(false);
  }


  // Loading Products
  useEffect(() => {
    fetchProductsBag()
  }, [bag]);

  if (loading) {
    return <Loading />;
  }

  return (
    <Wrapper>
      <div className='main-container'>
        <div className="products-container">
          {products.map((product, index) => {
            const {
              _id: id,
              productName,
              price,
              off,
              category,
              subCategory,
              imgKeyList,
            } = product;

            const imgHeroUrl = imgKeyList.filter(img => img.hero)[0].url

            return (
              <div key={index} className="product-container" >
                <div className="img-hero-container">
                  <img
                    className="img-hero"
                    src={imgHeroUrl}
                    alt={productName}
                  />
                </div>
                <div className="title-btn-container">
                  <div className='title-container'>
                    <h4 className="name">{productName}</h4>
                    <p><span>{category}</span> - <span>{subCategory}</span></p>
                    <div className="price-container">
                      <p>Total:  {formatPrice(price)} - {off}% = <span>{formatPrice(price * (1 - off / 100))}</span></p>
                    </div>
                  </div>
                  <button
                    type="buttom"
                    className="btn btn-icon"
                    onClick={() => {
                      removeFromBag(id);
                    }}
                  >
                    <FaTimes />
                  </button>
                </div>
              </div>

            );
          })}
        </div>

        <div className="checkout-container">
          <h4>
            Total: <span> {formatPrice(total)}</span>
          </h4>
          {total !== 0 && <div>
            <Payment total={total} />
          </div>}
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .error-msg-container {
    height: calc(100vh - 15rem);
    width: 90vw;
    display: grid;
    place-items: center;
  }

  .main-container {
    display: block;
    margin-top: 2rem;
  }
  
  .product-container {
    border-radius: var(--borderRadius);
    margin-bottom: 0.5rem;
    padding: 0.7rem;
    display: grid;
    grid-template-columns: 3rem 1fr;
    grid-gap: 0.5rem;
    background: var(--grey-103);
  }
  
  .checkout-container {
    width: 100%;
    border-radius: var(--borderRadius);
    padding: 0.7rem;
    background: var(--grey-103);    
  }
  
  .img-hero-container {
    width: 3rem;
  }

  .title-btn-container {
    display: flex;
    justify-content: space-between;
    padding-left: 1rem;
  }

  .name {
    font-size: 0.9rem;
  }

  .title-container p {
    font-size: 0.7rem;
  }

  .btn-icon {
    width: 20px;
    height: 20px;
    padding: 0;
    display: grid;
    place-items: center;
    font-size: 0.9rem;
  }

  @media screen and (min-width: 790px) {
    .main-container {
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: 55% auto;
      align-items: flex-start;
    }

    .product-container {
      grid-template-columns: 5rem 1fr;
    }
    
    .img-hero-container {
      width: 5rem;
    }

    .name {
      font-size: 1.2rem;
    }
  
    .title-container p {
      font-size: 1rem;
    }

    .checkout-container {
      font-size: 1.2rem
    }
  
    
}
`;

export default ProductRowList;
