import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { fetchPatterns } from '../utils/axiosRequests';
import { Loading } from '../components';
import { FaFilePdf } from 'react-icons/fa';

const Download = () => {
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState('');
  const [fileTypes, setFileTypes] = useState([]);
  const [printTypes, setPrintTypes] = useState([]);
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();

  const location = useLocation();
  const { from } = location.state;

  const fetch = async (from) => {
    setLoading(true);

    const fetchPatternsResult = await fetchPatterns(from);

    if (fetchPatternsResult.status === 'error') {
      setMsg(fetchPatternsResult.msg);
      setLoading(false);
      return;
    }

    const filesSplited = fetchPatternsResult.patterns.map((item) => {
      if (item.originalname.toLowerCase().includes('ficha tecnica') ||
        item.originalname.toLowerCase().includes('ficha técnica')
      ) {
        return { type: 'Ficha Técnica', location: item.location }
      }

      const size = item.originalname.split('-').map(i => i.trim())[2]

      if (item.originalname.toLowerCase().includes('ploter') ||
        item.originalname.toLowerCase().includes('plotter')
      ) {
        return { type: 'Plotter', size, location: item.location }
      }

      if (item.originalname.toLowerCase().includes('a4')) {
        return { type: 'A4', size, location: item.location }
      }
    });

    const fileTypesList = [...new Set([...filesSplited.map(j => j.type)])];

    setFileTypes(fileTypesList);
    setPrintTypes(filesSplited);
    setLoading(false);
    return;
  };

  useEffect(() => {
    fetch(from);
  }, []);

  if (loading) return <Loading />;

  return (
    <Wrapper>
      <div className='download-container'>
        <div className="title-container">
          <h1 className='product-name'>{query.get('name')}</h1>
          <div className='back-btn-container'>
            <Link className="btn back-btn" to="/private/meusMoldes">
              Voltar
            </Link>
          </div>
        </div>

        <div className="download-container">

          {msg ? <div className='error-msg'><p>{msg}</p></div> : <>
            <h2>Clique para baixar o arquivo</h2>
            {fileTypes.length !== 0 &&
              fileTypes.map((title, index) => {
                return (
                  <div key={index}>
                    <h2 className="format-title">Formato: <span>{title}</span></h2>

                    <div className='files-container'>
                      {printTypes.map(
                        (file, i) =>
                          file.type === title && (
                            <div key={i} className="download-product-container">
                              <a
                                href={`${file.location}`}
                                key={i}
                                download={file.location}
                                target="_blank"
                              >
                                <FaFilePdf className="pdf-icon" />
                                <p>{file.size}</p>
                              </a>
                            </div>
                          )
                      )}
                    </div>
                  </div>
                )
              })}</>
          }
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .error-msg {
    display: grid;
    place-items: center;
    min-height: calc(100vh - 15.1rem);
  }

  .error-msg p {
    background: red;
    padding: 1rem;
  }

  .download-container {
    width: 90vw;
    max-width: 1200px;
    margin: 2rem auto;
    min-height: calc(100vh - 15.1rem);
  }

  .title-container {
    margin: 2rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .product-name {
    font-size: 1.2rem;
    text-transform: capitalize;
  }

  .download-container h2 {
    padding-bottom: 1rem;
    font-size: 1rem;
    text-transform: none;
  }
  
  .back-btn {
    font-size: 0.8rem;
    padding: 0.3rem 0.6rem;
  }
  
  .format-title {
    font-size: 1.1rem; 
  }

  .format-title span{
    font-size: 1rem; 
  }

  .files-container {
    padding: 1rem 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    gap: 0.5rem;
  }

  .download-product-container {
    padding: 1rem;
    display: grid;
    place-items: center;
    background: var(--primary-400);
    cursor: pointer;
  }

  .download-product-container:hover {
    opacity: 0.8;
  }

  .download-product-container p {
    font-size: 0.8rem;
    text-align: center;
    padding-top: 1rem;
  }

  .pdf-icon {
    width: 80px;
    height: 80px;
    color: var(--primary-300);
  }

  @media screen and (min-width: 790px) {
    .download-container h2 {
      font-size: 1.7rem;
    }
    
    .product-name {
      font-size: 1.8rem;
    }

    .back-btn {
      font-size: 1.2rem;
      padding: 0.6rem 1.2rem;
    }

    .format-title {
      font-size: 1.3rem; 
    }
  
    .format-title span{
      font-size: 1.1rem; 
    }
  
    .files-container {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      gap: 1rem;
    }

    .pdf-icon {
      width: 120px;
      height: 120px;
    }

    .download-product-container p {
      font-size: 1rem;
      padding-top: 1rem;
    }
  }
`;

export default Download;
