import styled from 'styled-components';
import { FreePatterns, FeaturedPatterns } from './';
import imgHero from '../assets/moldeeImgHero.jpeg'
import logo from '../assets/logoText.png'
import { Navbar } from '../components';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const Home = () => {
  return (
    <Wrapper className='container'>
      <Helmet>
        {/* <title>MOLDEE - Gestão Em Modelagem Digital</title> */}
        <meta name="description" content="Na Moldee você encontra moldes digitais para roupas femininas, masculinas e 
    infantil em diversos tamanhos seguindo as tabelas padronizadas. Ofereçemos serviços de modelagem à pronta 
    entrega onde você pode baixar os moldes após a confirmação do pagamento, serviço de modelagem sob encomenda 
    onde você escolhe um modelo e o molde é feito personalizado e enviado por email e serviço de digitalização de 
    modelagem impressa onde você envia uma foto da sua modelagem impressa e nós enviamos o arquivo digital por 
    email. Os moldes são disponibilizados nos formatos A4 e plotter para você imprimir na sua cidade."
          data-rh="true" />
      </Helmet>

      <Navbar />
      <main className='hero-container'>
        <div className='slogan'>
          <img src={logo} alt='logo' className='logo' />
          <div className='hero-line'></div>
          <h3 className='hero-text'>Gestão em Modelagem</h3>
        </div>
        <img className='hero-img' src={imgHero} alt='molde' />
        <div className='services-link-container'>
          <Link to='/services/pronta' className='services-link services-link-color'>Pronta entrega</Link>
          <Link to='/services/encomenda' className='services-link services-link-color'>Sob encomenda</Link>
          <Link to='/services/digitalizar' className='services-link services-link-color'>digitalização</Link>
        </div>
      </main>
      <div className='section-home'>
        <h2>Aproveite nossos moldes gratuitos</h2>
        <FreePatterns />
      </div>
      <div className='section-home section-home-1'>
        <h2>Moldes em destaque</h2>
        <FeaturedPatterns />
        <div className='all-btn-container'>
          <Link to='/services/pronta' className='services-link all-btn'>ver todos</Link>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
width: 100vw;

.hero-container {
  height: 85vh;
  display: grid;
  place-items: center;
  position: relative;
}

.logo {
  width: 8rem;
}

.slogan {
  margin-top: 4.5rem;
  display: grid;
  place-items: center;
  z-index: 1;
}
  
.hero-text {
  margin-top: 0.5rem;
  font-family: var(--bodyFont);
  font-size: 0.8rem;
  text-transform: uppercase;
  color: var(--grey-main);
}

.hero-line {
  margin: 0 auto;
  margin-top: 2rem;
  background: var(--grey-main);
  width: 70vw;
  height: 1px;
}
  
.hero-img {
  height: 85vh;
  position: absolute;
  right: 0;
  top:0;
}

.services-link-container {
  width: 100vw;
  padding: 1.5rem;
  display: grid;
  place-items:center;
  z-index: 2;
}

.services-link {
  display:block;
  width: 70%;
  padding: 0.5rem 0;
  margin: 0.7rem 0;
  text-align: center;
  border-radius: var(--borderRadius);
  font-size: 1rem;
  text-transform: uppercase;
  transition: var(--transition);
}

.services-link-color {
  background: tranparent;
  color: var(--grey-main);
  border: 1px solid var(--grey-main);
}

.services-link:hover{
  background: var(--grey-101);
  border: 1px solid var(--grey-102);
}

.services-link-color:visited{
  color: var(--grey-main);
}

.section-home {
  padding: 1rem 0;
  text-align: center;
  background: var(--primary-901);
  background: var(--grey-800);
}

.section-home-1 {
  padding-top: 0;
}
  
.section-home h2 {
  padding-top: 2rem;
  padding-bottom: 1rem;
  font-size: 1rem;
  text-transform: uppercase;
}

.all-btn-container {
  display: grid;
  place-items:center;
  padding: 2rem 0;
}

.all-btn {
  background: var(--primary-main);
  color: var(--grey-500);
  border: none;
  cursor: pointer;
  width: 50%;
  max-width: 18rem;
}
  
.all-btn:hover{
  background: var(--primary-main);
  color: var(--grey-500);
  box-shadow: 2px 2px 7px 1px white;
  opacity: 0.8;
  border: none;
}
  
.all-btn:visited {
  color: var(--grey-500);
}

@media screen and (min-width: 550px) {
  .slogan {
    margin-top: 4rem;
  }

  .logo {
    width: 15rem;
  }

  .hero-text {
    font-size: 1.5rem;
  }

  .hero-line {
    height: 1px;
    width: 50vw;
  }

  .services-link-container {
    width: 100vw;
    max-width: 1100px;
    padding: 1.5rem;
  }
  
  .services-link {
    width: 25vw;
    padding: 0.5rem;
    font-size: 0.75rem;
  }

  .section-home h2 {
    font-size: 1.3rem;
  }

  .all-btn {
    font-size: 1.5rem;
  }
}

@media screen and (orientation: landscape) {
  .slogan {
    margin-top: 2rem;
  }

  .logo {
    width: 8rem;
  }

  .hero-text {
    font-size: 1rem;
  }

  .hero-line {
    height: 1px;
    width: 40vw;
  }

  .services-link-container {
    display: flex;
    justify-content: space-between;
    width: 90vw;
    padding: 0 1.5rem;
  }

  .section-home h2 {
    font-size: 1rem;
  }

  .all-btn {
    font-size: 1.1rem;
    width: 40vw;
  }
}

@media screen and (min-width: 790px) {
  .logo {
    width: 14rem;
  }

  .hero-text {
    font-size: 1.2rem;
    padding-top: 0.5rem;
  }

  .hero-line {
    height: 1px;
    width: 50vw;
  }

  .services-link {
    width: 25vw;
    padding: 1rem;
    font-size: 1.05rem;
  }

  .section-home h2 {
    font-size: 1.3rem;
  }
}

@media screen and (min-width: 990px) {
  .img-hero-container {
    width: 100%;
    height: 80vh;
    display: grid;
    place-items: center;
    position: relative;
  }

  .logo {
    width: 13rem;
  }

  .slogan {
    display: grid;
    place-items: center;
    z-index: 1;
  }

  .services-link-container {
    width: 90%;
    display: flex;
    justify-content: space-between;
  }

  .services-link {
    width: 17rem;
    padding: 1rem 0;
    font-size: 1.2rem;
  }

  .all-btn {
    font-size: 1.3rem;
    width: 20rem;
  }
}
`;

export default Home;
