import styled from 'styled-components';
import { FormRow } from '../components';
import { registerUser } from '../utils/axiosRequests';
import { useState, useEffect } from 'react';
import { useGlobalContext } from '../context/global_context';
import { Link } from 'react-router-dom';
import Verify from './Verify';

const Register = ({ location }) => {
  const [values, setValues] = useState({ name: '', email: '', password: '' });
  const [loading, setLoading] = useState(false);
  const [news, setNews] = useState(true);
  const [verify, setVerify] = useState(false);
  const [idAndEmailSaved, setIdAndEmailSaved] = useState({ id: '', email: '' });

  const { alert, showAlert, hideAlert } = useGlobalContext();

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const { name, email, password } = values;

    if (!name || !email || !password) return;

    const registerResult = await registerUser({
      name,
      email,
      password,
      news,
    });

    if (registerResult.status === 'error') {
      showAlert({
        text: registerResult.msg,
      });
      setLoading(false);
      return;
    }
    setIdAndEmailSaved({ id: registerResult.id, email: registerResult.email });
    setVerify(true);
    setLoading(false);
    return;
  };

  useEffect(() => hideAlert(), []);

  return (
    <Wrapper className={location !== 'payment' ? "page-height" : null}>
      {location !== 'payment' && alert.show && (
        <div className={`alert alert-${alert.type}`}>{alert.text}</div>
      )}
      {verify ? (
        <Verify
          idAndEmailSaved={idAndEmailSaved}
          password={values.password}
          location={location}
        />
      ) : (
        <form
          className={loading ? 'form form-loading' : 'form'}
          onSubmit={onSubmit}
        >
          <h2>Cadastrar</h2>
          <FormRow
            type="name"
            name="name"
            value={values.name}
            handleChange={handleChange}
          />
          <FormRow
            type="email"
            name="email"
            value={values.email}
            handleChange={handleChange}
          />
          <FormRow
            type="password"
            name="password"
            value={values.password}
            handleChange={handleChange}
          />
          <div className="news-container">
            <label htmlFor="news" className="news-label">
              Quero saber as novidades
              <input
                type="checkbox"
                id="news"
                className="news-input"
                checked={news}
                onChange={() => setNews(!news)}
              />

            </label>
            <p className="news-info">
              * Você poderá desabilitar esta opção
              após o cadastro em "meus moldes/configurações".
            </p>
          </div>
          <div className='btn-container'>
            <button type="submit" className="btn auth-btn" disabled={loading}>
              {loading ? 'Aguarde...' : 'Cadastrar'}
            </button></div>
          {location !== 'payment' && (
            <>
              <p className='auth-options'>
                Já tem uma conta? <span> </span>
                <Link to="/logar">Logar</Link>
              </p>
              <p className='auth-options'>
                Cancelar? <span> </span>
                <Link to="/">Home</Link>
              </p>
            </>
          )}
        </form>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.section`
  display: grid;
  place-items: center;

  .login-link {
    display: inline-block;
    margin-left: 0.25rem;
    text-transform: capitalize;
    color: var(--primary-500);
    cursor: pointer;
  }

  .btn:disabled {
    cursor: not-allowed;
  }

  .news-container {
    padding: 0.5rem 0;
  }

  .news-input {
    margin-left: 0.7rem;
  }

  .news-info {
    width: 100%;
    font-size: 0.7rem;
    margin-top:0.3rem;
  }
`;

export default Register;
