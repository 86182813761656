import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';

const Carousel = ({ children, show, currentIndex, setCurrentIndex, indicator, setIndicator
}) => {
  const [start, setStart] = useState(false)
  // const [indicator, setIndicator] = useState(true)

  const [length, setLength] = useState(0)
  const [touchPosition, setTouchPosition] = useState(null)

  const handleTouchStart = (e) => {
    const touchDown = e.touches[0].clientX
    setTouchPosition(touchDown)
  }

  const handleTouchMove = (e) => {
    const touchDown = touchPosition

    if (touchDown === null) {
      return
    }

    const currentTouch = e.touches[0].clientX
    const diff = touchDown - currentTouch

    if (diff > 5) {
      handleRightCarouselClick()
    }

    if (diff < -5) {
      handleLeftCarouselClick()
    }

    setTouchPosition(null)
  }

  const handleLeftCarouselClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex(prevState => prevState - 1)
    }
  }

  const handleRightCarouselClick = () => {
    if (currentIndex < (length - show)) {
      setCurrentIndex(prevState => prevState + 1)

    }
  }

  useEffect(() => {
    if (children.length !== 0) {
      setLength(children.length)
      setStart(true)
      const timer = setTimeout(() => {
        if (indicator) {
          if (currentIndex < length - show) {
            handleRightCarouselClick()
          }
          if (currentIndex === length - show) {
            setIndicator(false)
            handleLeftCarouselClick()
          }
        }

        if (!indicator) {
          if (currentIndex > 0) {
            handleLeftCarouselClick()
          }
          if (currentIndex === 0) {
            setIndicator(true)
            handleRightCarouselClick()
          }
        }

      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [start, children, currentIndex, indicator]);

  return (<Wrapper>
    <div className="carousel-wrapper">
      <div className='carousel-content-wrapper' onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>
        {currentIndex > 0 &&
          <button type='button' className='carousel-button carousel-button-left' onClick={handleLeftCarouselClick}>
            <FaAngleLeft className='carousel-button-icon' />
          </button>
        }
        <div
          className={`carousel-content show-${show}`}
          style={{ transform: `translateX(-${currentIndex * (100 / show)}%)` }}
        >
          {children}
        </div>
        {
          currentIndex < (length - show) &&
          <button type='button' className='carousel-button carousel-button-right' onClick={handleRightCarouselClick}>
            <FaAngleRight className='carousel-button-icon' />
          </button>
        }
      </div>

    </div>

  </Wrapper>)
}

const Wrapper = styled.div`
width: 100vw;
display: flex;
flex-direction: columns;

  .carousel-wrapper {
   display: flex;
   width: 100%;
  }
  
  .carousel-content-wrapper {
    position: relative;
    overflow: hidden;
    width: 90%;
    margin: 0 auto;
    height: 100%;
    
    box-shadow:0 0 22px 10px white;
  }

  .carousel-content {
    display: flex;
    transition: all 250ms linear;
    -ms-overflow-style: none;  /* hide scrollbar in IE and Edge */
    scrollbar-width: none;  /* hide scrollbar in Firefox */
}

.carousel-content.show-2 > * {
  padding: 0.5rem;
  width: 50%;
}

.carousel-content.show-3 > * {
  padding: 0.5rem;
  width: calc(100% / 3);
}

.carousel-content.show-4 > * {
  padding: 0.7rem;
  width: calc(100% / 4);
}
.carousel-content.show-5 > * {
  padding: 1rem;
  width: calc(100% / 5);
}

/* hide scrollbar in webkit browser */
.carousel-content::-webkit-scrollbar, .carousel-content::-webkit-scrollbar {
    display: none;
}

.carousel-content > * {
  width: 100%;
  flex-shrink: 0;
  flex-grow: 1;
}

  .carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border:none;
    cursor: pointer;
    z-index:2;
    width: 20px;
    height: 35px;
    display: grid;
    place-items: center;
    background: white;
    opacity: 0.5;
  }
  
  .carousel-button-icon {
    opacity: 1;
    font-size: 1rem;
    color: var(--primary-main);
    color: black;
  }
  
  .carousel-button-left {
    border-radius: 0 50% 50% 0;
    left: 0;
  }
  
  .carousel-button-right {
    border-radius: 50% 0 0 50%;
    right: 0;
    
  }
  
  @media (hover: none) and (pointer: coarse) {
    .left-arrow, .right-arrow {
        display: none;
    }
}

  @media screen and (min-width: 550px){
    .carousel-container {
      width: 85vw;
    }
  }
`;

export default Carousel;