import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../context/auth_context';
import { Loading } from '../components';
import { FiSettings } from 'react-icons/fi';
import { useState, useEffect, useRef } from 'react';
import { fetchMyProducts } from '../utils/axiosRequests';

const MeusMoldes = () => {
  const { logoutUser, user } = useAuthContext();
  // const { myProducts, loading, downloadProductId } = useUserContext();
  const [msg, setMsg] = useState('');
  const [zoomImg, setZoomImg] = useState({ zoom: false, i: 0, type: '' });
  const topPosition = useRef(null)

  const [loading, setLoading] = useState(true);
  const [myProducts, setMyProducts] = useState([]);
  let navigate = useNavigate();

  const handleLogout = () => {
    logoutUser();
    navigate('/');
  };

  const handleZoom = (i, type) => {
    topPosition.current = window.scrollY;
    setZoomImg({ zoom: !zoomImg.zoom, i, type })
    return;
  }

  const fetch = async () => {
    const fetchMyProductsResult = await fetchMyProducts(user.userId);

    if (fetchMyProductsResult.status === 'error') {
      setMsg(fetchMyProductsResult.msg);
    }

    setMyProducts(fetchMyProductsResult.myProducts);
    setLoading(false);
    return;
  };

  useEffect(() => {
    fetch();
  }, []);

  if (loading) return <Loading />;

  return (
    <Wrapper >
      <div className='main-container'>
        <div className="title-container">
          <div className="title">
            <h2 >
              Meus Moldes
            </h2>
          </div>
          <div className="user-name">
            <p >Olá, {user.name}</p>
          </div>
          <div className="navLinks">
            <div className="link">
              <Link to='/' >
                Home
              </Link>
            </div>
            <button
              className="logout-btn"
              type="button"
              onClick={() => handleLogout()}
            >
              Sair
            </button>
            <Link className="config-btn" to="config">
              <FiSettings />
            </Link>
          </div>
        </div>

        {msg && <div>{msg}</div>}

        {myProducts.length !== 0 && !loading ? (
          <div className="products-container">
            {myProducts.map((product, index) => {
              const {
                productName,
                category, subCategory,
                description,
                tissueSuggestion,
                tableKey,
                imgKeyList,
              } = product;

              const imgHeroUrl = imgKeyList.filter(img => img.hero)[0].url

              return (
                <div className="product-container" key={index}>
                  <div className="title-description">
                    <h3 className='name'>{productName}</h3>
                    <p className='cat-subcat'><span>{category}/</span><span>{subCategory}</span></p>
                  </div>

                  <div className={zoomImg.i === index &&
                    zoomImg.type === 'img' &&
                    zoomImg.zoom
                    ? "img-zoom-in" : "img-hero"} onClick={() => handleZoom(index, 'img')}
                    style={
                      zoomImg.i === index &&
                        zoomImg.type === 'img' &&
                        zoomImg.zoom
                        ? { top: topPosition.current, cursor: 'zoom-out' } : null
                    }
                  >
                    <img
                      src={imgHeroUrl}
                      alt={productName}
                    />
                  </div>

                  <div className={zoomImg.i === index &&
                    zoomImg.type === 'table' &&
                    zoomImg.zoom
                    ? "img-zoom-in" : "size-table"} onClick={() => handleZoom(index, 'table')}
                    style={
                      zoomImg.i === index &&
                        zoomImg.type === 'table' &&
                        zoomImg.zoom ? { top: topPosition.current, cursor: 'zoom-out' } : null
                    }
                  >
                    <img
                      src={`${tableKey}`}
                      alt={productName}
                    />
                  </div>

                  <div className="download-btn-container">
                    <Link
                      to={`/private/meusMoldes/download?name=${productName}`}
                      className="btn btn-download"
                      state={{ from: product.patterns }}
                    >
                      Baixar
                    </Link>
                  </div>

                  <div className='info'>
                    {/* <p className="description"><strong>Descrição:</strong><span>{description}</span></p> */}
                    <p className="tissueSuggestions">
                      <strong>Sugestão de Tecidos:</strong>
                      {tissueSuggestion.map((tissue, i) => (
                        <span key={i}>{i !== tissueSuggestion.length - 1 ? `${tissue}, ` : `${tissue}.`}</span>
                      ))}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="no-products-container">
            <p className="no-product-msg">Você ainda não tem nenhum molde.</p>
            <Link to="/services/pronta" className="btn">
              Comprar
            </Link>
          </div>
        )}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .main-container {
    width: 95vw;
    max-width: 1200px;
    min-height: calc(100vh - 8rem);
    margin: 0 auto;
  }

  .title-container {   
    margin-top: 1rem;
    display: grid;
    grid-template-areas:
      'w r'
      's s';
    grid-template-columns: 55% auto;
    grid-template-rows: 1fr 1fr;
    grid-gap: 0.5rem;
  }

  .title {
    font-size: 1rem;
    grid-area: w;
  }
  
  .user-name {
    grid-area: s;
    font-size: 0.9rem;
  }
  
  .navLinks {
    grid-area: r;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.9rem;
  }

  .link a {
    display: block;
    padding: 0.4rem 0.6rem;
    font-size: 1rem;
    color: var(--grey-main);
  }
  
  .logout-btn {
    cursor: pointer;
    outline: none;
    border: none;
    background: transparent;
    color: var(--grey-main);
    letter-spacing: var(--letterSpacing);
    font-size: 0.9rem;
    font-family: var(--bodyFont);
    padding: 0.6rem;
  }
  
  .config-btn:hover,
  .logout-btn:hover{
    border-bottom: 1px solid var(--primary-500);
  }
  
  .config-btn {
    color: var(--grey-main);
    font-size: 0.9rem;
    padding: 0.6rem;
    display: grid;
    place-items: center;
  }
  
  .config-btn:visited {
    color: var(--grey-main);
  }

  .products-container {
    margin-bottom: 2rem;
    display: grid;
    place-items: center;
  }

  .product-container {
    margin-top: 0.7rem;
    background: var(--grey-103);
    padding: 1rem;
    width: 100%;
    display: grid;
    grid-template-areas:
      'a a a'
      'b c d'
      'e e e';
    grid-template-columns: 1fr 1fr 4rem;
    grid-template-rows: 2rem 8rem 2rem;
    grid-gap: 1rem;
  }

  .title-description {
    grid-area: a;
    display: flex;
    align-items: center;
  }

  .img-hero {
    grid-area: b;
    padding: 0.3rem;
    cursor: zoom-in;
  }
  
  .size-table {
    grid-area: c;
    cursor: zoom-in;
  }

  .img-zoom-in {
    position: absolute;
    left: 0;
    right: 0;
    background-color: var(--grey-103);
    z-index:1
  }
  
  .img-zoom-in img {
    padding: 3rem;
    object-fit: scale-down;
    height: 100vh;
  }
  
  .download-btn-container{
    grid-area: d;
  }
  
  .info {
    grid-area: e;
  }

  .btn-download {
    padding: 0.2rem 0;
    display: grid;
    place-items: center;
    font-size: 0.9rem;
  }

  .name {
    max-width: 60%;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    font-size: 0.9rem;
  }

  .cat-subcat {
   font-size: 0.7rem;
   text-transform: capitalize;
   margin-left: 1rem;
  }

  .size-table img,
  .img-hero img{
    height: 8rem;
  }

  .info p {
    font-size: 0.8rem;
  }
  
  .info span {
    padding-left: 0.5rem;
    font-size: 0.7rem;
  }
  
  .no-products-container {
    height: calc(100vh - 16.6rem);
    display: grid;
    place-items: center;
    margin-top: 3rem;
  }

  .no-product-msg {
    font-size: 1rem;
    padding: 2rem 0;
  }

  @media screen and (min-width: 550px) {
    .product-container {
      margin-top: 0.7rem;
      grid-template-columns: 1fr 1fr 7rem;
      grid-template-rows: 2rem 12rem 3rem;
    }

    .link a {
      padding: 0.45rem 0.9rem;
    }

    .size-table img,
    .img-hero img {
      height: 12rem;
      width: 12rem;
    }

    .name {
      font-size: 1.2rem;
    }
    
    .cat-subcat {
      font-size: 1rem;
    }

    .info p {
      font-size: 0.9rem;
    }
    
    .info span {
      padding-left: 0.5rem;
      font-size: 0.8rem;
    }
  }

  @media screen and (min-width: 790px) {
    .main-container {
      width: 90vw;
    }

    .title-container {
      grid-template-columns: 70% auto;
    }
  
    .title {
      font-size: 1.7rem;
    }
  
    .user-name {
      font-size: 1rem;
    }
    
    .navLinks {
      font-size: 1rem;
    }
  
    .logout-btn {
      padding: 0.75rem 1.5rem;
    }
  
    .config-btn {
      font-size: 1.4rem;
    }
  
    .product-container {
      grid-template-areas:
      'b c a d'
      'b c e d';
      grid-template-columns: 8rem 8rem auto 4rem;
      grid-template-rows: 1fr 1fr;
      grid-gap: 1.5rem;
    }

    .size-table,
    .img-hero {
      place-self: center;
    }

    .size-table img,
    .img-hero img {
      width: 8rem;
      height: 8rem;
    }

    .info {
      align-self: end;
    }

    .title-description {
      padding: 0;
      margin:0;
      align-self: start;
      display:block;
    }

    .name {
      font-size: 1rem;
    }
    
    .cat-subcat {
      font-size: 0.8rem;
      margin: 0;
    }

    .info p {
      font-size: 0.8rem;
    }
    
    .info span {
      padding-left: 0.5rem;
      font-size: 0.7rem;
    }
  }

  @media screen and (min-width: 990px) {
    .title-container {
      margin: 2rem 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .navLinks {
      width: 25%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1rem;
    }

    .product-container {
      grid-template-columns: 12rem 12rem auto 6rem;
      grid-template-rows: 1fr 1fr;
    }

    .size-table img,
    .img-hero img {
      width: 12rem;
      height: 12rem;
    }

    .info,
    .title-description {
      margin-left: 2rem;
    }

    .name {
      font-size: 1.3rem;
    }
    
    .cat-subcat {
      font-size: 1.1rem;
      margin: 0;
    }

    .info p {
      font-size: 1.1rem;
    }
    
    .info span {
      padding-left: 0.5rem;
      font-size: 1rem;
    }

    .btn-download {
      padding: 0.3rem;
      font-size: 1.1rem;
    }
  }
`;

export default MeusMoldes;
