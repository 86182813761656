import { useState } from 'react';
import styled from 'styled-components';
import { FormRow } from '../components';
import { forgotPassword } from '../utils/axiosRequests';
import { useGlobalContext } from '../context/global_context';
import { useAuthContext } from '../context/auth_context';
import ResetPassword from './ResetPassword';
import { Link } from 'react-router-dom';

const ForgotPassword = ({ location }) => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(true);

  const {
    alert,
    showAlert,
    hideAlert,
    resetPasswordInPaymentPage,
  } = useGlobalContext();
  const { setVerification } = useAuthContext();

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setVerification(false);
    hideAlert();

    const forgotPasswordResult = await forgotPassword(email);

    console.log(forgotPasswordResult);

    if (forgotPasswordResult.status === 'error') {
      showAlert({ text: forgotPasswordResult.msg });
      setShowForm(true);
      setLoading(false);
      return;
    }

    setVerification(true);
    setShowForm(false);
    setLoading(false);
  };

  return (
    <Wrapper className={location !== 'payment' ? "page-height" : null}>
      <div className='forgot-password-container'>
        {location !== 'payment' && alert.show && (
          <div className={`alert alert-${alert.type}`}>{alert.text}</div>
        )}
        {showForm ? (
          <form
            className={loading ? 'form form-loading' : 'form'}
            onSubmit={handleSubmit}
          >
            <h2>Esqueçeu sua senha?</h2>
            <p className='text-info'>
              Enviaremos um código no seu email para validar sua nova senha. Não
              feche esta página.
            </p>
            <FormRow
              type="email"
              name="email"
              value={email}
              handleChange={handleChange}
            />
            <div className={location === 'payment' ? 'btn-container payment-page-container-btn' : 'btn-container'}>
              {location === 'payment' && (

                <button
                  type="button"
                  className="btn auth-btn"
                  onClick={() => resetPasswordInPaymentPage(false)}
                >
                  Voltar
                </button>
              )}

              <button type="submit" className="btn auth-btn" disabled={loading}>
                {loading ? 'Aguarde...' : 'Redefinir senha'}
              </button>

            </div>
            <div>

              <p className='auth-options'>
                Cancelar?
                <Link to="/">Home</Link>
              </p>
            </div>
          </form>
        ) : (
          <ResetPassword location={location} email={email} />
        )}

      </div>
    </Wrapper>
  );
};

const Wrapper = styled.main`
  display: grid;
  place-items: center;

  .text-info {
    font-size: 0.9rem;
    margin: 1.3rem 0;
    text-transform: initial;
  }

  .payment-page-container-btn {
    display: flex;
    justify-content: space-between;
  }

  @media screen and (min-width: 790px) {
    .text-info {
      font-size: 1.1rem;
    }
  }

`;

export default ForgotPassword;
