import styled from 'styled-components';
import Navbar from '../components/Navbar';
import { FaCheck, FaPrint } from 'react-icons/fa';
import { BsFile, BsFiles, BsImages, BsPostcard } from 'react-icons/bs';
import { GiAlarmClock, GiLargeDress } from 'react-icons/gi';
import { FiTarget } from 'react-icons/fi';
import { MdOutlineAttachEmail } from 'react-icons/md';
import { Helmet } from 'react-helmet-async';

const ServicesMake = () => {
  const screenWidth = window.innerWidth;

  return (
    <Wrapper>

      <Helmet>
        <title>MOLDEE - Gestão Em Modelagem Digital - Digitalização</title>
        <meta name="description" content="No serviço de digitalização da Moldee você envia uma foto do seu molde
        impresso para digitalizarmos. Após a confirmação do pagamento nós digitalizamos o seu molde e enviamos
        por email nos formatos A4 e Plotter para você imprimir na sua cidade."
          data-rh="true" />
        <link rel='canonical' href='/services/digitalizar' />
      </Helmet>

      <Navbar />
      <div className='make-container'>
        {screenWidth < 790 ?
          <div className='split-in-rows-container'>

            <div className='card-row'>
              <div className='space-column'></div>
              <div className='context'>
                <div className='info-card-container'>
                  <div className='info-card-icon'><BsImages /></div>
                  <div className='info-text'>
                    <p>
                      Você envia uma imagem (desenho ou foto) do modelo que deseja, de frente e de costas.
                    </p>
                  </div>
                </div>
              </div>
              <div className='space-column'>
                <div className='border-50'></div>
                <div className='border-50 border-top border-right corner-top-right'></div>
              </div>
            </div>
            <div className='space-row'>
              <div className='space-column'></div>
              <div className=''></div>
              <div className='space-column'>
                <div className='border-100 border-right'></div>
              </div>
            </div>

            <div className='card-row'>
              <div className='space-column'>
                <div className='border-50'></div>
                <div className='border-50 border-left border-top corner-top-left'></div>
              </div>
              <div className='context'>

                <div className='info-card-container'>
                  <div className='info-card-icon'><GiLargeDress /></div>
                  <div className='info-text'>
                    <p>
                      Fazemos sua modelagem exclusiva testando em protótipo digital 3D antes de te enviar
                    </p>
                  </div>
                </div>
              </div>
              <div className='space-column'>
                <div className='border-50 border-right border-bottom corner-bottom-right'></div>
                <div className='border-50'></div>
              </div>
            </div>
            <div className='space-row'>
              <div className='space-column'>
                <div className='border-100 border-left'></div>
              </div>
              <div className=''></div>
              <div className='space-column'></div>
            </div>

            <div className='card-row'>
              <div className='space-column'>
                <div className='border-50 border-left border-bottom corner-bottom-left'></div>
                <div className='border-50'></div>
              </div>
              <div className='context'>
                <div className='info-card-container'>
                  <div className='info-card-icon'><BsPostcard /><BsFiles /><BsFile /></div>
                  <div className='info-text'>

                    <ul>Inclui
                      <li><FaCheck /> Ficha técnica simplificada</li>
                      <li><FaCheck /> Um arquivo separado para cada tamanho</li>
                      <li><FaCheck /> Um arquivos com todos os tamanhos juntos</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className='space-column'>
                <div className='border-50'></div>
                <div className='border-50 border-right border-top corner-top-right'></div>
              </div>
            </div>
            <div className='space-row'>
              <div className='space-column'>

              </div>
              <div className=''></div>
              <div className='space-column'>
                <div className='border-100 border-right'></div>
              </div>
            </div>

            <div className='card-row'>
              <div className='space-column'>
                <div className='border-50'></div>
                <div className='border-50 border-left border-top corner-top-left'></div>
              </div>
              <div className='context'>
                <div className='info-card-container'>
                  <div className='info-card-icon'><GiAlarmClock /></div>
                  <div className='info-text'>
                    <p>
                      Entregamos em até 15 dias após a confirmação do pagamento
                    </p>
                  </div>
                </div>
              </div>
              <div className='space-column'>
                <div className='border-50 border-right border-bottom corner-bottom-right'></div>
                <div className='border-50'></div>
              </div>
            </div>
            <div className='space-row'>
              <div className='space-column'>
                <div className='border-100 border-left'></div>
              </div>
              <div className=''></div>
              <div className='space-column'></div>
            </div>

            <div className='card-row'>
              <div className='space-column'>
                <div className='border-50 border-left border-bottom corner-bottom-left'></div>
                <div className='border-50'></div>
              </div>
              <div className='context'>
                <div className='info-card-container'>
                  <div className='info-card-icon'><FiTarget /></div>
                  <div className='info-text'>
                    <p>
                      Já inclui alguns ajustes, case precise
                    </p>
                    <p style={{ fontSize: '0.7rem' }}>
                      Fale conosco para saber mais detalhes deste serviço
                    </p>
                  </div>
                </div>
              </div>
              <div className='space-column'>
                <div className='border-50'></div>
                <div className='border-50 border-right border-top corner-top-right'></div>
              </div>
            </div>
            <div className='space-row'>
              <div className='space-column'>
              </div>
              <div className=''></div>
              <div className='space-column'>
                <div className='border-100 border-right'></div>
              </div>
            </div>

            <div className='card-row'>
              <div className='space-column'>
              </div>
              <div className='context'>
                <div className='info-card-container'>
                  <div className='info-card-icon'><MdOutlineAttachEmail /> <FaPrint /></div>
                  <div className='info-text'>
                    <p>
                      Os arquivos vão em PDF em seu email pronto para impressão em A4 ou Plotter (+90cm)
                    </p>
                  </div>
                </div>
              </div>
              <div className='space-column'>
                <div className='border-50 border-right border-bottom corner-bottom-right'></div>
                <div className='border-50'></div>
              </div>
            </div>
          </div> :

          <div className='split-in-rows-container-big'>

            <div className='row-card-container-big'>
              <div className='space-column'>
              </div>

              <div className='context'>
                <div className='info-card-container'>
                  <div className='info-card-icon'><BsImages /></div>
                  <div className='info-text'>
                    <p>
                      Você envia uma imagem (desenho ou foto) do modelo que deseja, de frente e de costas.
                    </p>
                  </div>
                </div>
              </div>

              <div className='space-column'>
                <div className='border-50 border-bottom'></div>
                <div className='border-50'></div>
              </div>

              <div className='context'>
                <div className='info-card-container'>
                  <div className='info-card-icon'><GiLargeDress /></div>
                  <div className='info-text'>
                    <p>
                      Fazemos sua modelagem exclusiva testando em protótipo digital 3D antes de te enviar
                    </p>
                  </div>
                </div>
              </div>

              <div className='space-column'>
                <div className='border-50'></div>
                <div className='border-50 border-top border-right corner-top-right'></div>
              </div>
            </div>

            <div className='space-row-big'>
              <div className='border-100 border-right'></div>
            </div>

            <div className='row-card-container-big'>
              <div className='space-column'>
                <div className='border-50'></div>
                <div className='border-50 border-top border-left corner-top-left'></div>
              </div>

              <div className='context'>
                <div className='info-card-container'>
                  <div className='info-card-icon'><BsPostcard /><BsFiles /><BsFile /></div>
                  <div className='info-text'>

                    <ul>Inclui
                      <li><FaCheck /> Ficha técnica simplificada</li>
                      <li><FaCheck /> Um arquivo separado para cada tamanho</li>
                      <li><FaCheck /> Um arquivos com todos os tamanhos juntos</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className='space-column'>
                <div className='border-50'></div>
                <div className='border-50 border-top'></div>
              </div>

              <div className='context'>
                <div className='info-card-container'>
                  <div className='info-card-icon'><GiAlarmClock /></div>
                  <div className='info-text'>
                    <p>
                      Entregamos em até 15 dias após a confirmação do pagamento
                    </p>
                  </div>
                </div>
              </div>

              <div className='space-column'>
                <div className='border-50 border-right border-bottom corner-bottom-right'></div>
                <div className='border-50'></div>
              </div>
            </div>

            <div className='space-row-big'>
              <div className='border-100 border-left'></div>
            </div>

            <div className='row-card-container-big'>
              <div className='space-column'>
                <div className='border-50 border-left border-bottom corner-bottom-left'></div>
                <div className='border-50'></div>
              </div>

              <div className='context'>
                <div className='info-card-container'>
                  <div className='info-card-icon'><FiTarget /></div>
                  <div className='info-text'>
                    <p>
                      Já inclui alguns ajustes, caso precise
                    </p>
                    <p style={{ fontSize: '0.7rem' }}>
                      Fale conosco para saber mais detalhes deste serviço
                    </p>
                  </div>
                </div>
              </div>

              <div className='space-column'>
                <div className='border-50 border-bottom'></div>
                <div className='border-50'></div>
              </div>

              <div className='context'>
                <div className='info-card-container'>
                  <div className='info-card-icon'><MdOutlineAttachEmail /> <FaPrint /></div>
                  <div className='info-text'>
                    <p>
                      Os arquivos vão em PDF em seu email pronto para impressão em A4 ou Plotter (+90cm)
                    </p>
                  </div>
                </div>
              </div>

              <div className='space-column'>
                <div className='border-50'></div>
                <div className='border-50 border-right border-top corner-top-right'></div>
              </div>
            </div>
          </div>
        }
        {/* <p>
          No serviço de modelagem sob encomenda fazemos a modelagem exclusiva para sua marca

          A modelagem é feita a partir de uma imagem nítida de referência que você envia pra gente,
          que pode ser um desenho ou uma foto, de frente e costas,
          com detalhes

          Toda modelagem sob encomenda acompanha uma ficha técnica simplificada

          a modelagem sempre é feita seguindo uma tabela de medidas industrial, e você pode comprar
          um tamanho só ou vários tamanhos (o orçamento é de acordo com a quantidade de tamanhos que queira)

          o preço da modelagem sob encomenda varia de acordo com as características da modelagem (detalhes que
          possível,técnicas necessárias para desenvolver o molde, tempo necessário para desenvolver o
          molde, etc).
          Preços a partir de R$60,00 - envie a imagem do modelo no WhatsApp para receber o orçamento

          antes de entregar a sua modelagem sob encomenda realizamos um teste em protótipo digital 3d
          (que imita um manequim de tamanho real) e/ou um teste em meia escala (manequim miniatura),
          para verificar os detalhes decaimento da modelagem e só depois te enviamos o molde digital

          Neste serviço está incluso o serviço de ajuste da modelagem que funciona assim: após você receber,
          imprimir e fazer a peça piloto do molde, se considerar que o molde precisa de algum ajuste como
          ficar mais largo, mais curto, etc, você envia uma foto ou vídeo do modelo no corpo e explica o que
          quer ajustar, nós fazemos os ajustes no molde digital e reenviamos os arquivos para você
          (não alteramos o modelo, apenas ajustamos o molde para ficar mais próximo do modelo de
          referencia/servir melhor);

          A modelagem é entregue em arquivo digital PDF, no seu e-mail, para você imprimir na sua cidade -
          enviamos em 2 formatos para impressão: uma para plotter com no mínimo 90cm de largura e outro
          formato pronto para imprimir em folhas A4 (tamanho de folha sulfite - impressora doméstica) -
          veja os detalhes em "como funciona a impressão"</p> */}

        <div>
          <div className='space-btn'></div>
          <div className='whats-container'>
            <div className='space-link'></div>
            <div className="whats-link-container">
              <a
                href="https://web.whatsapp.com/send?phone=5543999951135"
                target="_blank"
                rel="noopener noreferrer"
                className="whats-link"
              >
                Solicitar orçamento
              </a>
            </div>
            <div className='space-link'>
              {screenWidth > 790 ?
                <>
                  <div className='border-50 border-right border-bottom corner-bottom-right'></div>
                  <div className='border-50'></div>
                </>
                : null}
            </div>
          </div>
        </div>

      </div>
    </Wrapper >
  );
};

const Wrapper = styled.section`
.make-container {
  width: 90vw;
  max-width: 30rem;
  margin: 2rem auto;
  margin-top: 8rem;
}

.split-in-rows-container {
  width: 90%;
  margin: 0 auto;
  display: grid;
  grid-template-rows: 1fr 50px 1fr 50px 1fr 50px 1fr 50px 1fr 50px 1fr;
}

.card-row, .space-row {
  display: grid;
  grid-template-columns: 40px 1fr 40px;
}

.border-50 {
  height: 50%;
}

.border-100 {
  height: 100%;
}

.corner-top-left {
  border-radius: 15% 0 0 0;
}
.corner-top-right {
  border-radius: 0 15% 0 0;
}
.corner-bottom-right {
  border-radius: 0 0 15% 0;
}
.corner-bottom-left {
  border-radius: 0 0 0 15%;
}

.border-right {
  border-right: 2px dashed var(--primary-main);
}
.border-left {
  border-left: 2px dashed var(--primary-main);
}
.border-top {
  border-top: 2px dashed var(--primary-main);
}
.border-bottom {
  border-bottom: 2px dashed var(--primary-main);
}

.space-btn {
  width: 50%;
  height: 3rem;
  border-right: 2px dashed var(--primary-main);

}

.context {
  background: var(--grey-103);
  border-radius: 5%;
  padding: 1rem;
}

.context p {
  padding-top: 1rem;
  font-size: 0.9rem;
  text-align: center;
}

.whats-container {
  width: 80%;
  margin: 0 auto;
}

.whats-link-container {
  background: var(--grey-103);
  
  text-align: center;
  padding: 1rem;
  border-radius: 1rem;
}

.whats-link {
  font-size: 1rem;
  text-transform:uppercase;
  color: var(--grey-200);
  padding: 1rem;
}

@media screen and (min-width: 790px) {
  .make-container {
    width: 90vw;
    max-width: 1000px;
    margin: 2rem auto;
    margin-top: 8rem;
  }

  .split-in-rows-container-big {
    width: 90%;
    margin: 0 auto;
    display: grid;
    grid-template-rows: 1fr 50px 1fr 50px 1fr;
  }

  .space-row-big {
    width: 100%;
  }

  .row-card-container-big {
    display: grid;
    grid-template-columns: 40px 1fr 40px 1fr 40px;
  }

  .space-btn {
    width: 90%;
    margin: 0 auto;
  }
  
  .whats-container {
    display: grid;
    grid-template-columns: 25% 50% 25%;
    width: 90%;
    margin: 0 auto;
  }
  
}

`;

export default ServicesMake;
