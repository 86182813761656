import React, { useState, useEffect, useContext, useReducer } from 'react';
import reducer from '../reducers/global_reducer';

import {
  SHOW_ALERT,
  HIDE_ALERT,
  RESET_PASSWORD_PAYMENT_PAGE,
} from '../actions';

const initialState = {
  alert: {
    show: false,
    text: '',
    type: 'danger',
  },
  paymentPage: false,
};

const GlobalContext = React.createContext();

const GlobalProvider = ({ children }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [state, dispatch] = useReducer(reducer, initialState);

  const showAlert = ({ text, type = 'danger' }) => {
    dispatch({ type: SHOW_ALERT, payload: { text, type } });
  };

  const hideAlert = () => {
    dispatch({ type: HIDE_ALERT });
  };

  const resetPasswordInPaymentPage = (value) => {
    dispatch({ type: RESET_PASSWORD_PAYMENT_PAGE, payload: value });
  };

  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        ...state,
        showAlert,
        hideAlert,
        screenWidth,
        resetPasswordInPaymentPage,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(GlobalContext);
};

export { GlobalProvider };
