import styled from 'styled-components';
import Navbar from '../components/Navbar';
import { ProductRowList } from '../components';
import { useProductsContext } from '../context/products_context';
import { Link } from 'react-router-dom';
import { useState } from 'react';

const CheckOut = () => {
  const { bag } = useProductsContext();
  const [agreementNotDeliver, setAgreementNotDeliver] = useState(false);
  const [modal, setModal] = useState(true);

  return (
    < Wrapper >
      {modal ? <div className='modal-container'>
        <div className='modal-content'>
          <p className='text-warning'>Atenção, não entregamos moldes impressos. Após a compra, seu molde estará
            disponínel para download nos formatos A4 ou Ploter (pelo menos 90cm de largura) para você imprimir na sua
            cidade. Você pode testar nossos produtos adquirindo nossos moldes gratuitos.</p>
          <div className='checkbox-agree'>
            <label>
              <input type='checkbox' onChange={() => setAgreementNotDeliver(!agreementNotDeliver)} />
              Li e concordo.
            </label>
          </div>
          <div className='btn-container'>
            <button
              className="btn auth-btn"
              disabled={!agreementNotDeliver}
              onClick={() => setModal(false)}
            >Prosseguir</button>
          </div>
        </div>
      </div> : null}
      <Navbar />
      <div className="head">
        <h2>Minha sacola</h2>
        <div className='add-btn-container'>
          <Link to="/services/pronta" className="btn add-btn">
            Adicionar
          </Link>
        </div>
      </div>
      <div className="container">
        {bag.length === 0 ? (
          <div className="no-product-container">
            <p className="no-product-msg">Nenhum produto na sacola ainda</p>
          </div>
        ) :
          <div className="products-row">
            <ProductRowList />
          </div>
        }
      </div>

    </Wrapper >

  );
};

const Wrapper = styled.section`
width: 90vw;
max-width: 1300px;
min-height: calc(100vh - 14rem);
margin: 0 auto;
margin-top: 7rem;

.head {
  width:100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-btn {
  font-size: 1rem;
  padding: 0.5rem 0.8rem;
}

  .container {
    margin-bottom: 2rem;
  }

  .no-product-container {
    display: grid;
    place-items: center;
    height: calc(100vh - 18.6rem);
  }

  .no-product-msg {
    padding: 1rem;
    font-size: 1rem;
  }

  .modal-content{
    min-width: 30%;
    background: var(--grey-700);
    padding: 2.5rem;
    max-width: 20rem;
  }

  .text-warning {
    text-align: justify;
    text-justify: inter-word;
  }

  .checkbox-agree {
    padding: 1rem 0;
  }

  .checkbox-agree input {
    margin:0;
    margin-right: 0.5rem;
  }

  @media screen and (min-width: 550px) {
    .head {
      font-size: 1.2rem;
    }

    .add-btn {
      font-size: 1.1rem;
      padding: 0.5rem 1rem;
    }

  }
  
  @media screen and (min-width: 790px) {
    .head {
      font-size: 1.5rem;
    }
    
    .add-btn {
      font-size: 1.2rem;
      padding: 0.5rem 1.1rem;
    }

    .no-product-msg {
      font-size: 1.2rem;
    }
  }
`;
export default CheckOut;
