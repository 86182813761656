import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { formatPrice } from '../utils/functions'

const Card = ({ data }) => {
  const { heroUrl, productName, price, off, link, mark } = data

  return (
    <Wrapper>
      <Link to={link} className="card-link">
        <div className={mark ? "mark" : null}></div>
        <div className='card-content strip-parent'>
          {price === 0 || off !== 0 ?
            <div className={price !== 0 && off !== 0 ?
              'strip strip-featured strip-color-featured' : 'strip strip-free strip-color-free'}>
              {price !== 0 && off !== 0 ? <span>{off}% off</span> : <span>gratuito</span>}</div>
            : null}
          <img src={heroUrl} alt={productName} />
          <div className='card-text'>

            <p>{productName}</p>
            {price !== 0 ? <p>{off !== 0 ? <span>{formatPrice(price * (1 - off / 100))}<span className='old-price'>{formatPrice(price)}</span></span> : <span>{formatPrice(price)}</span>}</p> : null}
          </div>
        </div>

      </Link>
    </Wrapper>
  );
};

const Wrapper = styled.article`

  .card-link {
    display: block;
    padding-bottom: 0.8rem;
    transition: var(--transition);
    border-radius: var(--borderRadius);
    border: 1px solid var(--grey-500);
    display: inline-flex;
    align-items:center;
    justify-content: center;
    max-width: 230px;
    position: relative;
  }

  .card-link:hover {
    opacity: 0.8;
  }

  .card-content img {
    border-radius: var(--borderRadius);
    padding: 1rem;
    background: var(--grey-500);
  }
  
  .card-text {
    padding-top: 0.5rem;
  }

  .card-content p {
    padding-left: 0.8rem;
    font-size: 0.8rem;
    text-transform: capitalize;
    color: var(--grey-100);
  }
  
  .strip-color-free {
    background: green;
    color: white;
  }

  .strip-color-featured {
    background: var(--primary-main);
    color: white;
  }

  .old-price {
    font-size: 0.7rem;
    margin-left: 0.5rem;
    text-decoration: line-through;
  }

  .mark {
    position: absolute;
    left: 0;
    right: 0;
    bottom:0;
    top:0;
    z-index: 2;
    background: red;
    opacity: 0.1;
  }

`;

export default Card;
