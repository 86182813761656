import styled from 'styled-components';
import { Loading, Card, Filter } from '../components';
import { useEffect, useState } from 'react';
import { getAllProducts, fetchMyProducts } from '../utils/axiosRequests';
import { useAuthContext } from '../context/auth_context';
import { useProductsContext } from '../context/products_context';
import { FaAngleLeft, FaAngleRight, FaCheck, FaCity, FaDownload, FaFilePdf, FaHouseUser, FaPrint } from 'react-icons/fa';
import { BsPostcard, BsFiles, BsFile, BsThreeDots } from 'react-icons/bs';
import { ImPrinter } from 'react-icons/im';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

let categories = ['feminino', 'masculino', 'infantil'];
const maxPageSmallScreen = 16;
const maxPageBigScreen = 40;

const maxPerPage = window.screen.width < 790 ? maxPageSmallScreen : maxPageBigScreen;

const Products = () => {
  const { isOpen } = useProductsContext();
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [filtered, setFiltered] = useState([])
  const [filterState, setFilterState] = useState([])
  const [error, setError] = useState(null)
  const [page, setPage] = useState(1)
  const [pageButtons, setPageButtons] = useState([]);
  const { user } = useAuthContext();

  const fetchData = async () => {
    setLoading(true);
    const fetch = await getAllProducts();

    if (fetch.status === 'error') {
      setError(fetch.msg)
      setLoading(false)
      return
    }

    let marked = fetch.map(item => { return { ...item, mark: false } })

    if (user && user.role === 'user') {
      const myProductsResult = await fetchMyProducts(user.userId);


      if (myProductsResult.status === 'error') {
        console.log(myProductsResult.msg);
      }

      marked = fetch.map(item => {
        if (myProductsResult.myProducts.map(i => i._id).includes(item._id)) {
          return { ...item, mark: true }
        } else {
          return { ...item, mark: false }
        }
      })
    }

    const categorySubCategory = findCategorySubCategory(fetch);
    pagination(fetch)
    setFilterState(categorySubCategory)
    setProducts(marked);
    setFiltered(marked);
    setLoading(false);
  };

  const findCategorySubCategory = (products) => {
    const specials = {
      category: 'especiais', subCategory: [
        { sub: 'gratuitos', checked: false }, { sub: 'destaques', checked: false }
      ]
    }

    const catSub = categories.map(category => {
      const filterSub = products.filter(product => product.category === category)
        .map(item => item.subCategory);

      return {
        category, subCategory: [...new Set(filterSub)].map(item => { return { sub: item, checked: false } }
        )
      }
    })

    return [specials, ...catSub]
  }

  const handleCheckInput = (cat, sub) => {
    const changedState = filterState.map(item => {
      if (item.category === cat) {
        item.subCategory.map(subCat => {
          if (subCat.sub === sub) {
            subCat.checked = !subCat.checked
            return subCat
          }
          return subCat;
        })
      }
      return item;
    })

    setFilterState(changedState)
  }

  const pagination = (products) => {
    const maxButtons = Math.ceil(products.length / maxPerPage)

    const buttons = Array.from({ length: maxButtons }, (_, i) => i + 1)

    setPageButtons(buttons)
    setPage(1)
  }

  const handlePageButtons = () => {
    
    const btnMiddle = () => {
      if(pageButtons.length === 2) {
        return [pageButtons[0],pageButtons[1]]
      }
      if(pageButtons.length === 3) {
        return [pageButtons[0], pageButtons[1], pageButtons[2]]
      }

      if(pageButtons.length > 3) {
        if(page <= pageButtons.length-2 && page >1) {
          return [pageButtons[page-2],pageButtons[page-1],pageButtons[page]]
        }
        if(page === 1) {
          return [pageButtons[page-1],pageButtons[page],pageButtons[page+1]]
        }
        return [pageButtons[pageButtons.length-3],pageButtons[pageButtons.length-2],pageButtons[pageButtons.length-1]]
      }
    }

    return <div className='btn-page-control-container'>
      {page > 1 && 
        <button 
          type='button' 
          className='btn page-control'
          onClick={()=> setPage(page-1)}
        ><FaAngleLeft /></button>}

      {page > 2 && pageButtons.length > 3 ? <button
          type="button"
          className={`btn btn-page ${Number(page) === 1 && 'page-active'
            }`}
          onClick={() => setPage((1))}
        >
          {1}
        </button> : null}

      {pageButtons.length > 4 && page > 3 ? <BsThreeDots />: null}

      {pageButtons.length > 1 && btnMiddle().map((num) => (
        <button
          key={num}
          type="button"
          className={`btn btn-page ${num === Number(page) && 'page-active'
            }`}
          onClick={() => setPage((num))}
        >
          {num}
        </button>
      ))}

      {pageButtons.length > 4 && page < pageButtons.length-2 ? <BsThreeDots />: null}

      {page < pageButtons.length-1 && pageButtons.length > 3? <button
          type="button"
          className={`btn btn-page ${pageButtons.length === Number(page) && 'page-active'
            }`}
          onClick={() => setPage((pageButtons.length))}
        >
          {pageButtons.length}
        </button>: null}

        { page < pageButtons.length && 
        <button 
          type='button' 
          className='btn page-control'
          onClick={() => setPage(page+1)}
          ><FaAngleRight /></button>}
        </div>
  }


  useEffect(() => {
    setError(null)
    fetchData();

    window.scrollTo({ top: 0 })
  }, [user]);
  
  useEffect(()=> {
    window.scrollTo({ top: 0 })
  }, [page])

  useEffect(() => {
    let checkedCatSub = []

    filterState.forEach(item => {
      item.subCategory.forEach(subCat => {
        if (subCat.checked) { checkedCatSub.push({ category: item.category, subCategory: subCat.sub }) }
      })
    })

    if (checkedCatSub.length === 0) {
      setFiltered(products)

      if (products.length !== 0) {
        pagination(products)
      }

    } else {
      const filter = checkedCatSub.map(item => {
        if (item.category === 'especiais' && item.subCategory === 'gratuitos') {
          return products.filter(s => s.price === 0)
        }
        else if (item.category === 'especiais' && item.subCategory === 'destaques') {
          return products.filter(s => s.featured)
        }
        else {
          return products.filter(s => s.category === item.category && s.subCategory === item.subCategory)
        }
      })

      const filterById = [...new Set(filter.flat().map(i => i._id))]
      const itemsFiltered = products.filter(i => filterById.includes(i._id)
      )

      setFiltered(() => itemsFiltered)
      pagination(itemsFiltered)

    }
  }, [filterState])

  if (loading) {
    return <Loading />;
  }

  return (
    <Wrapper>

      <Helmet>
        <title>MOLDEE - Gestão Em Modelagem Digital - Pronta Entrega</title>
        <meta name="description" content="O serviço de pronta entrega da Moldee oferece moldes digitais para 
        roupas femininas, masculinas e infantil. Temos vários modelos como macacão, blusa, biquíni, top fitness,
        shorts fitness, pijama, calça, maiô, calça fitness, saia, vestido, shorts, bermuda, dentre outros. 
        Os moldes são disponibilizados para download após a confirmação do pagamento nos formatos A4 e plotter 
        para você imprimir na sua cidade. Não realizamos ajustes neste serviço e não entregamos moldes impressos."
          data-rh="true" />
        <link rel='canonical' href='/services/pronta' />
      </Helmet>

      {error ? <div className='error-container'><p>{error}</p></div> : <main >
        {page ===1 ? <section className='info-card-section'>
          <div className='info-card-main-container'>
            <div className='info-card-container'>
              <div className='info-card-icon'><FaDownload /><FaFilePdf /></div>
              <div className='info-text'>

                <p>Você baixa os arquivos já em pdf prontos para impressão</p>
              </div>
            </div>
            <div className='info-card-container'>
              <div className='info-card-icon'><FaHouseUser /><FaPrint /></div>
              <div className='info-text'>

                <p>A impressão pode ser feita no tamanho A4 (impressora doméstica)</p>
              </div>
            </div>
            <div className='info-card-container'>
              <div className='info-card-icon'><FaCity /><ImPrinter /></div>
              <div className='info-text'>
                <p>Você também pode imprimir numa gráfica em tamanho Plotter <br />(+90cm de largura)</p>
              </div>
            </div>
            <div className='info-card-container'>
              <div className='info-card-icon'><BsPostcard /><BsFiles /><BsFile /></div>
              <div className='info-text'>

                <ul>Inclui
                  <li><FaCheck /> Ficha técnica simplificada</li>
                  <li><FaCheck /> Um arquivo separado para cada tamanho</li>
                  <li><FaCheck /> Um arquivos com todos os tamanhos juntos</li>
                </ul>
              </div>
            </div>
          </div>

          <div className='text-warn'><p>Atenção, não realizamos ajustes ou alterações do modelo. Caso você queira uma modelagem que
            não está disponível no nosso catálogo, confira nosso serviço
            <Link to='/services/encomenda' className='warn-link'>Sob Encomenda</Link></p></div>
        </section>: <div className='space'></div>}

        <div className='main-container'>
          <div className={isOpen ? "sidebar-filter show-side" : "sidebar-filter"}>
            <Filter filterState={filterState} handleCheckInput={handleCheckInput} />
          </div>

          {filtered.length !== 0 ? (
            <div className="products-container">
              {filtered
              .filter((item, i) => i >= (page-1)*maxPerPage && i<(page)*maxPerPage)
              .map((product, index) => {
                const { _id: id, productName, price, imgKeyList, off, mark } = product;

                const heroUrl = imgKeyList.filter((img) => img.hero)[0].url;
                const link = `/services/pronta/${id}?mark=${mark}`;
                const data = { heroUrl, productName, price, off, link, mark }

                return <Card key={index} data={data} />

              })}
            </div>
          ) : (
            <div className="no-match">Desculpe, não encontramos nenhum molde!</div>
          )}

        </div>
      </main>}

      <div className="btn-pages-container">
        {products.length !== 0 && pageButtons.length !== 1
          ? handlePageButtons()
          : null} </div>

    </Wrapper>
  );
};


const Wrapper = styled.section`
.error-container{
  color: black;
}

.info-card-section {
  width: 90%;
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 7rem;
}

.space {
  margin-top: 5rem;
}

.info-card-main-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
}

.text-warn {
  border-radius: var(--borderRadius);
  margin-top: 1rem;
  //background: var(--grey-200);
  padding: 3px;
  text-align: center;
}

.warn-link {
  margin-left: 0.3rem;
}

.warn-link:visited,
.warn-link {
  color: var(--primary-main);
  font-weight: 700;
}

.text-warn p {
  font-size: 0.8rem;
  padding: 1rem 3rem;
  background: var(--grey-103);
  //color: var(--white);
}

.products-container {
  width:90vw;
  height: 100%;
  margin: 0 auto;
  padding-top: 2rem;
  margin-bottom: 2rem;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  }

.sidebar-filter {
  height: calc(100% - 10rem);
  padding: 1rem 1.5rem;
  z-index: 9;
  position:fixed;
  left: 0;
  top: 10rem;
  width: 60vw;
  transform: translateX(-70vw);
  overflow: scroll;
  transition: var(--transition);
}

.show-side {
  transform: translateX(0);
}

  .card {
    transition: var(--transition-1);
  }

  .card:hover {
    transform: translateY(-5px);
    cursor: pointer;
  }

  .card-have:hover {
    transform: translateY(0);
  }

  .no-match {
    text-align: center;
    padding-top: 3rem;
    font-size: 1.5rem;
  }

  .page-active {
    opacity: 0.8;
  }

  .btn-page-control-container {
    display: flex;
    align-items: center;
  }

  .page-control {
    display: grid;
    place-items: center;
    width: 30px;
    height: 30px;
    padding: 0;
  }

  @media screen and (min-width: 550px) {
    .products-container {
      grid-gap: 1rem;
      grid-template-columns: repeat(3, 1fr);
    }

    .info-card-main-container {
      grid-gap: 3rem;
    }    
  }

  @media screen and (min-width: 790px) {
    .main-container {
      display:flex;
      width: 100%;
      padding: 0 1rem;
    }

    .info-card-main-container {
      grid-template-columns: repeat(auto-fit, minmax(150px,  1fr));
      grid-gap: 1rem;
    }
    
    .sidebar-filter {
      transform: translateX(0);
      padding-top: 1.5rem;
      position: static;
      z-index: 0;
      margin-top: 3rem;
      width: 13rem;
      overflow: visible;
    }

    .products-container {
      width: 78vw;
      margin-top: 2rem;
    }
  }

  @media screen and (min-width: 990px) {
    .main-container {
      padding: 0 2rem;
    }
    
    .products-container {
      grid-gap: 1rem;
      grid-template-columns: repeat(4, 1fr);
    }

    .text-warn p {
      font-size: 1rem;
      padding: 1rem 10rem;
    }

    .info-card-main-container {
      grid-gap: 2rem;
    }
    
    ul,
    .info-card-container p {
      font-size: 1rem;
    }

    li,
    .info-card-container span {
      font-size: 0.7rem;
    }
  }

  @media screen and (min-width: 1300px) {
    .products-container {
      grid-gap: 1rem;
      grid-template-columns: repeat(5, 1fr);
    }
  }

`;

export default Products;
