import { Outlet, Navigate } from 'react-router-dom';
import { useAuthContext } from '../context/auth_context';

const SharedLayoutAdmin = () => {
  const { user } = useAuthContext();

  return (
    <>
      {user.role === 'superuser' ? (
        <Outlet />
      ) : (
        <Navigate to="/private/meusMoldes" />
      )}
    </>
  );
};

export default SharedLayoutAdmin;
