import styled from 'styled-components';
import image from '../../assets/imgAbout.jpg';
import { Link } from 'react-router-dom';

const About = () => {
  return (
    <Wrapper className="page-width page-height">
      <article className="title-container">
        <div className='title'>
          <h1>Sobre nós</h1>
        </div>
        <div>
          <Link to="/" className="btn back-btn">
            Voltar
          </Link>
        </div>
      </article>
      <div className="about-container">
        <div className='img-container'>

          <img src={image} alt="image" />
        </div>
        <p>
          Descrição em breve.
        </p>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
width: 90vw;
max-width: 1000px;
margin: 0 auto;
padding: 3rem 0;
  
  .about-container {
    padding-top: 2rem;
    text-align: justify;
  }

  .title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .back-btn {
    padding: 0.3rem 0.6rem;
    margin: 0;
  }

  .img-container {
    max-width: 20rem;
    width: 90%;
    margin: 0 auto;
    height: 65vh;
    box-shadow: 0 0 15px 5px white;
    margin-bottom: 1.5rem;
  }
  
  .img-container img {
    height: 100%;
  }

  @media screen and (min-width: 790px) {
    .about-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    .title h1 {
      font-size: 1.7rem;
    }

    .back-btn {
      padding: 0.6rem 0.9rem;
      font-size: 1rem;
    }
  }

`;

export default About;
