import styled from 'styled-components';
import { useAuthContext } from '../context/auth_context';
import { Link } from 'react-router-dom';
import { fetchSingleUser, updateUser } from '../utils/axiosRequests';
import { useState, useEffect } from 'react';
import { Loading } from '../components';

let userDefaultValues = {};
const UserConfig = () => {
  const { user, saveUser } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [inputControl, setInputControl] = useState(true);
  const [userFull, setUserFull] = useState({});

  const handleChange = (e) => {
    setUserFull((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleNews = () => {
    setUserFull((prev) => {
      return { ...prev, news: !userFull.news };
    });
  };

  const fetchUser = async () => {
    setLoading(true);
    const fetchSingleUserResult = await fetchSingleUser(user.userId);

    if (fetchSingleUserResult.status === 'error') {
      setLoading(false);
      return;
    }

    setLoading(false);
    userDefaultValues = fetchSingleUserResult;
    setUserFull(fetchSingleUserResult);
    return;
  };

  useEffect(async () => {
    await fetchUser();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const setUserResult = await updateUser(user.userId, {
      user: userFull,
    });

    if (setUserResult.status === 'error') {
      console.log(setUserResult.msg);
      // alert error
      return;
    }

    setUserFull(setUserResult.user);
    saveUser({ ...user, name: setUserResult.user.name });
    setInputControl(true);
    return;
  };

  return (
    <Wrapper>
      <div className='config-container'>
        <div className="nav-container">
          <h2>Configurações</h2>
          <div>
            <Link to={`/private/meusMoldes`} className="btn back-btn">
              Voltar
            </Link>
          </div>
        </div>

        {loading ? (
          <Loading />
        ) : (
          <main className="main-container">
            {userFull.name ? (
              <form>
                <div className='info-name'>
                  <label for='name'>
                    Nome:
                    <input
                      type="text"
                      name="name"
                      value={userFull.name}
                      disabled={inputControl}
                      className={!inputControl ? 'active-input' : null}
                      onChange={handleChange}
                    />
                  </label>
                </div>
                <div className='info-email'>
                  <label for='email'>
                    Email:
                    <input
                      type="email"
                      name="email"
                      value={userFull.email}
                      disabled={true}
                    // disabled={inputControl}
                    // className={!inputControl ? 'active-input' : null}
                    // onChange={handleChange}
                    />
                  </label>
                </div>
                <div className='info-news'>
                  <label for="news">
                    Quero receber novidades pelo email:
                    <input
                      className="info-news-input"
                      type="checkbox"
                      name="news"
                      checked={userFull.news}
                      disabled={inputControl}
                      onChange={handleNews}
                    />
                  </label>
                </div>

                <div className='btn-container'>
                  {inputControl ? (
                    <div className="edit-btn-container">
                      <button
                        type="button"
                        className="btn edit-btn"
                        onClick={() => {
                          setInputControl(false);
                        }}
                      >
                        Editar
                      </button>
                    </div>
                  ) : (
                    <div className="submit">
                      <button
                        type="button"
                        className="btn edit-btn"
                        onClick={() => {
                          setInputControl(true);
                          setUserFull(userDefaultValues);
                        }}
                      >
                        Cancelar
                      </button>
                      <button
                        type="submit"
                        className="btn edit-btn"
                        onClick={handleSubmit}
                      >
                        Alterar
                      </button>
                    </div>
                  )}
                </div>
              </form>
            ) : null}
          </main>
        )}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .config-container {
    width: 90vw;
    max-width: 1200px;
    height: calc(100vh - 7rem );
    margin: 0 auto;
  }

  .nav-container {
    display: flex;
    padding: 2rem 0;
    align-items: center;
    justify-content: space-between;
  }

  .nav-container h2 {
    font-size: 1.2rem;
  }

  .back-btn {
    font-size: 1rem;
    padding: 0.3rem 0.6rem;
  }

  .main-container {
    margin: 0 auto;
    margin-top: 3rem;
    width: 100%;
    max-width: 30rem;
    padding: 0.5rem;
    background: var(--grey-103);
  }

  .info-name, .info-email,
  .info-news {
    padding: 0.5rem 0;
    font-size: 0.9rem;
  }

  .info-news-input {
    width: 17px;
    height: 17px;
  }

  input {
    width: 16rem;
  }

  .btn-container {
    padding: 1rem 0;
  }

  .btn-control {
    display: flex;
    justify-content: space-between;
  }
  
  .edit-btn-container {
    display: grid;
    place-items: center;
  }

  .edit-btn {
    font-size: 1rem;
    padding: 0.75rem 1.5rem;
    margin: 0.7rem 0;
  }

  .submit {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  
  @media screen and (min-width: 550px) {
    .main-container {
      padding: 1rem 3rem;
    }

    input {
      width: 20rem;
    }

    .nav-container h2 {
      font-size: 1.5rem;
    }
  
    .back-btn {
      font-size: 1.2rem;
      padding: 0.6rem 1.2rem;
    }
  
  }
`;

export default UserConfig;
