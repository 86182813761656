import styled from 'styled-components';
import { FaTable } from 'react-icons/fa';

const FormFile = ({ onSelectFile, sizeTable, preview }) => {
  return (
    <Wrapper>
      <label htmlFor="file" className="form-label-file">
        {sizeTable.name || preview ? (
          <img src={preview} className="selected-file" />
        ) : (
          <FaTable className="table-icon" />
        )}
      </label>

      <input
        className="form-input-file"
        type="file"
        id="file"
        accept="image/*"
        onChange={onSelectFile}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .form-label-file {
    width: 10rem;
    height: 10rem;
    background: var(--primary-400);
    display: grid;
    place-items: center;
    font-size: 5rem;
    color: var(--grey-500);
    cursor: pointer;
  }

  .form-input-file {
    width: 135px;
    background: transparent;
    margin-left: 0.5rem;
  }

  .form-input-file:focus {
    background: transparent;
  }

  .selected-file {
    width: 10rem;
    height: 10rem;
    object-fit: cover;
  }

  .table-icon {
    color: var(--primary-300);
  }
`;

export default FormFile;
