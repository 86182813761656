import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../context/auth_context';
import { useEffect, useState } from 'react';
import { Loading } from '../components';
import { fetchUsers, fetchSingleProduct } from '../utils/axiosRequests';
import { FaChevronDown, FaChevronUp, FaSearch } from 'react-icons/fa';

const AdminUsers = () => {
  const { logoutUser } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [loadingLocal, setLoadingLocal] = useState(false);
  let navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [notificated, setNotificated] = useState(0);
  const [verified, setVerified] = useState(0);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filter, setFilter] = useState('');
  const [userProducts, setUserProduct] = useState([]);
  const [fetchControl, setFetchControl] = useState(false);
  const [msg, setMsg] = useState([]);

  const handleLogout = () => {
    logoutUser();
    navigate('/');
  };

  const handleToggle = (id) => {
    const newUserState = users.map((user) => {
      if (user._id === id) {
        user.detail = !user.detail;
        if (user.detail) {
          setUserProduct(user.myProducts);
          setFetchControl(!fetchControl);
        } else {
          setUserProduct([]);
        }
      }
      return user;
    });

    setUsers(newUserState);
  };

  const handleDate = (timestamp) => {
    const date = new Date(timestamp);

    const month = Number(date.getMonth()) + 1;
    const day = Number(date.getDay());

    const dayN = day < 10 ? '0' + day.toString() : day.toString();
    const monthN = month < 10 ? '0' + month : month;
    return `${dayN}/${monthN}/${date.getFullYear()}`;
  };

  const notificationVerifiedNumber = (users) => {
    let numVerified = 0;
    let numNotified = 0;

    users.forEach((user) => {
      if (user.verified) {
        numVerified++;
      }
      if (user.news) {
        numNotified++;
      }
    });

    return { numVerified, numNotified };
  };

  const fetchUsersAPI = async () => {
    setLoading(true);

    const usersFetchResult = await fetchUsers();

    if (usersFetchResult.status === 'error') {
      setLoading(false);
      setMsg(usersFetchResult.msg);
      return;
    }

    const usersMap = usersFetchResult.map((user) => {
      return { ...user, detail: false };
    });
    setUsers(usersMap);
    setFilteredUsers(usersMap);
    const { numVerified, numNotified } = notificationVerifiedNumber(
      usersFetchResult
    );
    setNotificated(numNotified);
    setVerified(numVerified);
    setLoading(false);
    return;
  };

  const fetchProductUser = async (userProducts) => {
    setLoadingLocal(true);
    let userProductsComplete = [];

    for (let product of userProducts) {
      const fetchResult = await fetchSingleProduct(product.productId);

      if (fetchResult.status === 'error') {
        userProductsComplete.push({ ...product, notFound: true })
      } else {
        userProductsComplete.push({ ...fetchResult, notFound: false });
      }
    }

    setLoadingLocal(false);
    setUserProduct(userProductsComplete);
    setLoadingLocal(false);
    return;
  }

  useEffect(() => {
    fetchUsersAPI();
    setMsg([]);
  }, []);

  useEffect(() => {
    if (userProducts.length !== 0) {
      fetchProductUser(userProducts);
    }
  }, [fetchControl]);

  useEffect(() => {
    const usersFiltered = users.filter(
      (user) => user.name.includes(filter) || user.email.includes(filter)
    );

    setFilteredUsers(usersFiltered);
  }, [filter]);

  if (loading) {
    return <Loading />;
  }

  return (
    <Wrapper>
      <header className="header-container">
        <h2>Clientes <span className='users-length-span'>({users.length || 0})</span></h2>

        <div className="info">
          <p className="notificated">
            Notificando: <span>{notificated}</span>
          </p>
          <p>
            Verificados: <span>{verified}</span>
          </p>
        </div>

        <div className="filter-container">
          <label htmlFor="filter" className="filter-label">
            <FaSearch />
          </label>
          <input
            type="text"
            className="filter-input"
            id="filter"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
        </div>
        <button
          className="btn logout-btn"
          type="button"
          onClick={() => handleLogout()}
        >
          Sair
        </button>
      </header>

      {msg && msg}

      <div className="container-users">
        {filteredUsers.map((user, i) => {
          const {
            _id: id,
            name,
            email,
            news,
            verified,
            myProducts,
            createdAt,
            detail,
          } = user;

          return (
            <article className="user-container-main" key={i}>
              <div className="user-description">
                <p>{name}</p>

                <p>{email}</p>

                <div className="news">
                  <p>Receber notificações</p>
                  <div className={news ? 'square green' : 'square'}></div>
                </div>
                <div className="verified">
                  <p>Conta verificada</p>
                  <div className={verified ? 'square green' : 'square'}></div>
                </div>
                <p className="boughts">
                  Comprados: <span>{myProducts.length}</span>
                </p>
                <div className="details" onClick={() => handleToggle(id)}>
                  {detail ? <FaChevronUp /> : <FaChevronDown />}
                </div>
              </div>
              {user.detail && (
                <div className="info-details">
                  <p>Registrado em: {handleDate(createdAt)}</p>
                  <h5>Moldes</h5>
                  <div>
                    {loadingLocal ? (
                      <div className="loadingLocal">Carregando...</div>
                    ) : (
                      <>
                        {userProducts.length > 0 ? (
                          userProducts.map((product, i) => {
                            const {
                              price,
                              approvedDate,
                              paymentId,
                              productId,
                            } = product;

                            if (product.notFound) {
                              return (
                                <div key={i} className="info-details-container">
                                  <p>Aprov.: {handleDate(approvedDate)}</p>
                                  <p>Id Pagamento: {paymentId}</p>
                                  <p>Id Produto: {productId}</p>
                                  <p>Preço: {price}</p>
                                  <p>Não foi possível obter mais informações.</p>
                                </div>
                              );
                            } else {
                              const { productName,
                                category,
                                subCategory, } = product;

                              return (
                                <div key={i} className="info-details-container">
                                  <p>Nome: {productName}</p>
                                  <p>Categoria: {category}</p>
                                  <p>Sub-categoria: {subCategory}</p>
                                  <p>Preço: {price}</p>
                                  <p>Aprov.: {handleDate(approvedDate)}</p>
                                  <p>Id Pagamento: {paymentId}</p>
                                  <p>Id Produto: {productId}</p>
                                </div>
                              );
                            }

                          })
                        ) : (
                          <p>Não comprou nenhum molde até o momento</p>
                        )}
                      </>
                    )}
                  </div>
                </div>
              )}
            </article>
          );
        })}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
width: 90vw;
max-width: 1300px;
margin: 2rem auto;
min-height: calc(100vh - 17.7rem);

  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logout-btn {
    height: 40px;
  }

  .info {
    display: flex;
    align-items: center;
  }

  .users-length-span {
    font-size: 1rem;
  }

  .notificated {
    padding-right: 3.5rem;
  }

  .container-users {
    font-size: 0.9rem;
    margin: 2rem 0;
  }

  .user-description {
    width: 100%;
    display: grid;
    grid-template-columns: 3fr 3fr 2fr 2fr 130px 40px;
    place-items: center;
    padding: 0.5rem;
  }

  .user-container-main:nth-child(even) {
    background: var(--primary-400);
  }
  .user-container-main:nth-child(odd) {
    background: var(--primary-800);
  }

  label {
    padding-right: 0.5rem;
  }

  input[type='checkbox'][disabled] {
    outline: 1px solid red;
  }
  input[type='checkbox'][disabled][checked] {
    outline: 1px solid green;
  }

  .details {
    display: grid;
    place-items: center;
    width: 30px;
    height: 30px;
    color: var(--primary-main);
  }

  .details:hover {
    cursor: pointer;
    color: var(--primary-100);
  }

  .square {
    width: 10px;
    height: 10px;
    background: var(--red-dark);
    margin-left: 0.5rem;
  }

  .green {
    background: var(--green-dark);
  }

  .verified,
  .news {
    display: flex;
    align-items: center;
  }

  .info-details {
    background: var(--grey-500);
    padding: 1rem 4rem;
  }

  .info-details-container {
    background: var(--grey-600);
    padding: 0.5rem;
    margin: 0.3rem 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr) 100px;
  }
  
  .filter-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .filter-label {
    display: grid;
    place-items: center;
    width: 15px;
    height: 15px;
    color: var(--primary-300);
    cursor: pointer;
  }

  .filter-input {
    background: inherit;
    margin-left: 0.5rem;
    border: none;
    border-bottom: 1px solid var(--primary-300);
    outline: none;
  }

  .loadingLocal {
    text-align: center;
    font-size: 1.5rem;
    margin: 2rem;
  }
`;
export default AdminUsers;
