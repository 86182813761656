import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { GlobalProvider } from './context/global_context';
import { AuthProvider } from './context/auth_context';

import { ProductsProvider } from './context/products_context';
import { HelmetProvider } from 'react-helmet-async';

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <GlobalProvider>
        <AuthProvider>
          <ProductsProvider>
            <App />
          </ProductsProvider>
        </AuthProvider>
      </GlobalProvider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
