import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

const SharedServicesLayout = () => {
  return (
    <Wrapper>
      <Outlet />
    </Wrapper>
  );
};

const Wrapper = styled.section``;

export default SharedServicesLayout;
