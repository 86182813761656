import styled from 'styled-components';
import { useState, useEffect } from 'react';
import MercadoPago from './MercadoPagoComponent';
import Pix from './Pix';
import { useAuthContext } from '../context/auth_context';
import { useProductsContext } from '../context/products_context';
import RegisterLoginModal from './RegisterLoginModal';
import { fetchMyProducts } from '../utils/axiosRequests';


const Payment = ({ total }) => {
  const [check, setCheck] = useState([false, false]);
  const [chooseMethod, setChooseMethod] = useState('');
  const [myProductsBag, setMyProductsBag] = useState([])
  const [duplicatePattern, setDuplicatePattern] = useState([]);
  const { user } = useAuthContext();
  const { bag } = useProductsContext();

  const paymentAcceptedList = ['cartão', 'pix'];

  const handleCheckboxChange = (method, i) => {
    setChooseMethod(method);
    let newState = [false, false];
    newState[i] = true;
    setCheck(newState);
  };

  const methods = () => {
    if (chooseMethod) {
      if (chooseMethod === 'pix') return <Pix total={total} />;
      else {
        const initialization = {
          amount: total,
        }
        return <div>
          <p className='text-small'>* Os dados do seu cartão estão protegidos!</p>
          <MercadoPago initialization={initialization} />
        </div>
      }
    }
  };

  const fetchMyProductsBag = async () => {
    const myProductsResult = await fetchMyProducts(user.userId);

    if (myProductsResult.status === 'error') {
      console.log(myProductsResult.msg)
    }
    setMyProductsBag(myProductsResult.myProducts);
  }

  const compareProducts = () => {
    if (bag.length !== 0) {
      const diference = myProductsBag.filter((item) =>
        bag.find((el) => el === item._id)
      );

      if (diference.length !== 0) {
        setDuplicatePattern(diference)
      }
    }
  }

  const displayComponent = () => {
    if (duplicatePattern.length !== 0) {
      return <p className="repeatedProduct">
        Você já tem os moldes:{' '}
        {duplicatePattern.map((item, i) => (
          <span key={i}>{item.productName} </span>
        ))}. Remova os moldes que você já tem e prossiga sua compra.
      </p>
    } else {
      return <>
        <div className="container">
          {/* <h4 className="title">Formas de pagamento</h4> */}
          <div className="check-box-container">
            {paymentAcceptedList.map((method, i) => {
              return (
                <div className="payment" key={i}>
                  <label htmlFor={method}>
                    <input
                      type="checkbox"
                      className="payment-input"
                      value={method}
                      checked={check[i]}
                      onChange={() => handleCheckboxChange(method, i)}
                    />{method}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
        <div className='payment-container'>
          <p className='text-small-1'>As transações são intermediadas pelo Mercado Pago.
            Você receberá um email da transação.</p>
          {methods()}
        </div>
      </>;
    }
  }

  useEffect(() => {
    setDuplicatePattern([]);
    if (user) {
      fetchMyProductsBag();
    }
  }, [bag]);

  useEffect(() => {
    if (myProductsBag.length !== 0) {
      compareProducts();
    }
  }, [myProductsBag]);

  return (
    <Wrapper>
      {user ?
        displayComponent()
        : (
          <div className="register-login">
            <RegisterLoginModal />
          </div>
        )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .check-box-container {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    text-transform: capitalize;   
  }

  .payment {
   // margin-top: 1.7rem;
    //padding-left: 2rem;
  }

  .payment-input {
    margin-right: 1rem;
  }

  .text-small {
    font-size: 0.8rem;
  }
  .text-small-1 {
margin-left: 0.5rem;
    font-size: 0.7rem;
  }

  .payment-container {
    width: 100%;
  }

  .register-login {
    width: 100%;
  }

  .repeatedProduct {
    font-size: 1.3rem;
  }
`;

export default Payment;
