import React, { useEffect, useContext, useReducer } from 'react';
import reducer from '../reducers/auth_reducer';
import { fetchUser, logoutUserAPI } from '../utils/axiosRequests';

import { SET_USER, USER_VERIFICATION, REMOVE_USER } from '../actions';

const initialState = {
  user: null,
  verification: false,
};

const AuthContext = React.createContext();

const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const saveUser = (user) => {
    dispatch({ type: SET_USER, payload: user });
  };

  const removeUser = () => {
    dispatch({ type: REMOVE_USER });
  };

  const getCurrentUser = async () => {
    const userResult = await fetchUser();

    if (userResult.status === 'error') {
      removeUser();
      return;
    }

    saveUser(userResult);
    return;
  };

  const logoutUser = async () => {
    const logoutResult = await logoutUserAPI();
    if (logoutResult.status === 'ok') return removeUser();
    else console.log(logoutResult.msg);
  };

  const setVerification = (value) => {
    dispatch({ type: USER_VERIFICATION, payload: value });
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        saveUser,
        logoutUser,
        setVerification,
        getCurrentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  return useContext(AuthContext);
};

export { AuthProvider };
