import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FaSearch, FaTimes } from 'react-icons/fa';
import { AdminProductDisplay, Loading } from './';
import {
  getAllProducts,
  deleteProduct,
  checkExistProduct,
  offProduct,
} from '../utils/axiosRequests';

const AdminProducts = () => {
  const [loading, setLoading] = useState(false);
  const [offLoading, setOffLoading] = useState(false);
  const [modalOff, setModalOff] = useState(false);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState('');
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [deleted, setDeleted] = useState(false);
  const [page, setPage] = useState(1);
  const [pageButtons, setPageButtons] = useState([1])
  const [subCategories, setSubCategories] = useState([]);
  const [offData, setDataOff] = useState({ category: 'all', subCategory: 'all', value: null })

  const fetchData = async () => {
    setLoading(true);

    const fetch = await getAllProducts();

    if (fetch.status === 'error') {
      setError(fetch.msg)
      setLoading(false)
      return;
    }

    const subCategoriesOnly = new Set(fetch.map(item => item.subCategory))

    setSubCategories([...subCategoriesOnly])
    setProducts(fetch);
    setSearch('');
    setPage(1);
    handlePagination(fetch, 1)

    setLoading(false);
  };

  useEffect(() => {
    setError(null)
    setPage(1);
    setSearch('')
    fetchData();
  }, [deleted]);

  useEffect(() => {
    let show = products;
    if (search) {
      show = products.filter(product => product.productName.includes(search))
    }

    handlePagination(show, page)
  }, [search, page])

  const handleProductDelete = async (id) => {
    setLoading(true);

    const checkResult = await checkExistProduct('', '', id, 'delete');
    if (checkResult.status === 'error') {
      setError(checkResult.msg)
      setLoading(false);
      return;
    }

    const result = await deleteProduct(id);
    console.log(result)
    setDeleted(!deleted);
    setLoading(false);
    return;
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handlePagination = (filtered, page) => {
    const total = filtered.length;
    let num = total / 10

    if (num < 10) {
      num = 10;
    }

    if (num > 50) {
      num = 50;
    }

    const maxPerPage = Math.floor(num);
    const totalPages = Math.ceil(total / maxPerPage);
    const btnValues = Array.from({ length: totalPages }, (_, i) => i + 1);

    const newFiltered = filtered.filter((item, i) => {
      if (i >= (page - 1) * maxPerPage && i < page * maxPerPage) { return item }
      else return;
    });

    setPageButtons(btnValues);
    setFilteredProducts(newFiltered)
    return;
  }

  const handleInputChange = (e) => {
    setDataOff((prev) => { return { ...prev, [e.target.name]: e.target.value.toLowerCase() } })
  }

  const handleOffSubmitClick = async () => {
    setOffLoading(true);

    if (offData.value) {
      const offProductResult = await offProduct(offData);
      console.log(offProductResult.msg)
    }

    setOffLoading(false);
    setModalOff(false);
    setDataOff({ category: 'all', subCategory: 'all', value: null })
    fetchData();
    return;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <Wrapper>
      {error ?
        <div className='error-fetch-products'><p>{error}</p></div> :
        <>
          {modalOff ? <div className='modal-container'>
            <div className='modal-content'>
              <div className='title-container'>
                <h2>Desconto</h2>
                <button className='btn btn-close-modal' type='button' onClick={() => {
                  setModalOff(false);
                  setDataOff({ category: 'all', subCategory: 'all', value: null });
                  return;
                }}><FaTimes /></button>
              </div>
              <form className='input-container'>
                <div className='input-section'>
                  <label>Categoria
                    <select name='category' onChange={handleInputChange}>
                      <option value='all'>todos</option>
                      <option value='feminino'>feminino</option>
                      <option value='masculino'>masculino</option>
                      <option value='infantil'>infantil</option>
                    </select>
                  </label>
                </div>
                <div className='input-section'>
                  <label>Subcategoria
                    <select name='subCategory' onChange={handleInputChange}>
                      <option value='all'>todos</option>
                      {subCategories.map((sub, i) => {
                        return <option key={i} value={sub}>{sub}</option>
                      })}
                    </select>
                  </label>
                </div>
                <div className='input-section'>
                  <label>Valor
                    <input type='number' min='0' max='100' name='value' onChange={handleInputChange} />%
                  </label>
                </div>
                <div className='submit-off'>
                  <button className='btn' type='button' onClick={handleOffSubmitClick} disabled={offLoading}>
                    {!offLoading ? 'Salvar' : '...Salvando'}
                  </button>
                </div>
              </form>
            </div>
          </div> : null}
          <article className="controller">
            <div className="search">
              <label htmlFor="search" className="search-label">
                <FaSearch />
              </label>

              <input
                className="search-input"
                id="search"
                type="text"
                name="search"
                autoFocus={search}
                value={search}
                onChange={handleSearch}
              />

              <div className='total-products-info'>
                <h2>Total: <span>{products.length}</span></h2>
              </div>
            </div>

            <div className='btn-container-admin'>
              <button type='button' className='btn btn-nav-admin' onClick={() => setModalOff(true)}>Desconto</button>

              <div>
                <Link to="/private/admin/molde/new" className="btn" style={{ display: 'block', padding: '0.3rem 1rem', fontSize: '1.1rem' }}>
                  Adicionar
                </Link>
              </div>
            </div>

          </article>

          <div className="products-container">
            {filteredProducts.length === 0 ? (
              <div className='no-products-container'>
                {search ? (
                  <p className="no-product-msg">
                    Nenhum produto encontrado com "{search}"
                  </p>
                ) : (
                  <p className="no-product-msg">Nenhum produto cadastrado ainda.</p>
                )}
              </div>
            ) : (
              <div className='products-display-container'>
                <AdminProductDisplay
                  products={filteredProducts}
                  handleProductDelete={handleProductDelete}
                />
              </div>
            )}
          </div>

          <div className="btn-pages-container">
            {pageButtons.map((num) => (
              <button
                key={num}
                type="button"
                className={`btn btn-page ${num === page && 'page-active'
                  }`}
                onClick={() => setPage(num)}
              >
                {num}
              </button>
            ))}
          </div>
        </>}
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .controller {
      width: 90vw;
      max-width: 1300px;
      margin: 2rem auto;
      display: flex;
      justify-content: space-between;
  }

      .search {
        width: 40%;
      display: flex;
      align-items: center;
  }

      .search-label {
        color: var(--primary-main);
      background: transparent;
      display: grid;
      place-items: center;
      font-size: 1.2rem;
      width: 30px;
      height: 30px;
      cursor: pointer;
      transition: var(--transition);
  }

      .search-label:hover {
        opacity: 1;
  }

      .search-input {
        width: 60%;
  }

  .total-products-info{
    margin-left: 1rem;
  }

  .btn-nav-admin {
    padding: 0.6rem 1rem;
    font-size: 1.1rem;
    letter-spacing: var(--letterSpacing);
    font-family: var(--bodyFont);
  }

  .btn-container-admin{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 18rem;
  }

  .modal-content {
    background: var(--grey-103);
    padding: 3rem;
    width: 30rem;
  }

  .btn-close-modal {
    padding: 0.3rem;
    display: grid;
    place-items: center;
  }

  .input-container {
    padding-top: 1rem;
  }

  .input-section {
    padding: 0.5rem 0;
  }

  .input-section select{
    width: 7rem;
  }

  .submit-off {
    padding: 2rem 0 1rem 0;
    width: 100%;
    display: grid;
    place-items: center;
  }

  .title-container {
    display: flex;
    align-items: center;
    justify-content: space-between; 
  }

      .products-container {
        width: 90vw;
      max-width: 1300px;
      margin: 0 auto;
      background: var(--grey-700);
  }

      .products-display-container{

      }

      .no-products-container,
      .error-fetch-products {
        display: grid;
      place-items:center;
  }

      .no-products-container {
        height:calc(100vh - 25.8rem);
  }

      .no-products-container p,
      .error-fetch-products p {
        font - size: 1.2rem;
  }

      .deleting,
      .deleting:hover {
        opacity: 0.2;
  }
      `;

export default AdminProducts;
