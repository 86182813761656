export const categories = [
  { category: 'feminino' },
  { category: 'masculino' },
  { category: 'infantil' },
]

export const categoriesList = [
  {
    category: 'feminino',
    subCategories: [
      {
        title: 'top',
        items: [
          { name: 'camiseta', ref: '101' },
          { name: 'cropped', ref: '102' },
          { name: 'top', ref: '103' },
          { name: 'camisa', ref: '104' },
          { name: 'blusinha', ref: '105' },
          { name: 'blusa manga longa', ref: '106' },
          { name: 'casacos', ref: '107' },
        ],
      },
      {
        title: 'bottom',
        items: [
          { name: 'calça', ref: '121' },
          { name: 'shorts', ref: '122' },
          { name: 'saia', ref: '123' },
        ],
      },
      {
        title: 'peça unica',
        items: [
          { name: 'vestrefo', ref: '141' },
          { name: 'macacão e jardineira', ref: '142' },
          { name: 'conjuntos', ref: '143' },
        ],
      },
      {
        title: 'intima',
        items: [
          { name: 'calcinha', ref: '161' },
          { name: 'sutiã', ref: '162' },
          { name: 'rob', ref: '163' },
          { name: 'conjunto pijama', ref: '164' },
          { name: 'camisola', ref: '165' },
        ],
      },
      {
        title: 'praia',
        items: [
          { name: 'biquini', ref: '181' },
          { name: 'maio', ref: '182' },
          { name: 'sarefa', ref: '183' },
        ],
      },
    ],
  },
  {
    category: 'masculino',
    subCategories: [
      {
        title: 'top',
        items: [
          { name: 'camiseta', ref: '301' },
          { name: 'regata', ref: '302' },
          { name: 'camisa', ref: '303' },
          { name: 'blusa manga longa', ref: '304' },
          { name: 'casaco', ref: '305' },
        ],
      },
      {
        title: 'bottom',
        items: [
          { name: 'calça', ref: '321' },
          { name: 'bermuda/shorts', ref: '322' },
        ],
      },
      {
        title: 'peça unica',
        items: [
          { name: 'macacão', ref: '341' },
          { name: 'conjunto', ref: '342' },
        ],
      },
      {
        title: 'intima',
        items: [
          { name: 'cueca', ref: '361' },
          { name: 'rob', ref: '362' },
          { name: 'conjuno pijama', ref: '363' },
        ],
      },
      {
        title: 'praia',
        items: [
          { name: 'sunga', ref: '381' },
          { name: 'shorts', ref: '382' },
          { name: 'sarefa', ref: '383' },
        ],
      },
    ],
  },
  {
    category: 'infantil',
    subCategories: [
      {
        title: 'top',
        items: [
          { name: 'camiseta', ref: '501' },
          { name: 'cropped', ref: '502' },
          { name: 'top', ref: '503' },
          { name: 'camisa', ref: '504' },
          { name: 'blusinha', ref: '505' },
          { name: 'regata', ref: '506' },
          { name: 'blusa manga longa', ref: '507' },
          { name: 'casaco', ref: '508' },
        ],
      },
      {
        title: 'bottom',
        items: [
          { name: 'calça', ref: '521' },
          { name: 'shots/bermuda', ref: '522' },
          { name: 'saia', ref: '523' },
        ],
      },
      {
        title: 'peça unica',
        items: [
          { name: 'vestrefo', ref: '541' },
          { name: 'macacão e jardineira', ref: '542' },
          { name: 'conjunto', ref: '543' },
        ],
      },
      {
        title: 'intima',
        items: [
          { name: 'calcinha', ref: '561' },
          { name: 'cueca', ref: '562' },
          { name: 'rob', ref: '563' },
          { name: 'conjunto pijama', ref: '564' },
          { name: 'camisola', ref: '565' },
        ],
      },
      {
        title: 'praia',
        items: [
          { name: 'biquini', ref: '581' },
          { name: 'maio', ref: '582' },
          { name: 'sarefa', ref: '583' },
          { name: 'shorts', ref: '584' },
          { name: 'sunga', ref: '585' },
        ],
      },
    ],
  },
];

// export const findCategoryByRef = (ref) => {
//   let obj = {};
//   categoriesList.forEach((value) => {
//     value.subCategories.forEach((item) => {
//       item.items.filter((itemRef) => {
//         if (itemRef.ref === ref.toString()) {
//           obj = {
//             category: value.category,
//             subCategory: item.title,
//             type: itemRef.name,
//             ref: itemRef.ref,
//           };
//         }
//       });
//     });
//   });
//   return obj;
// };
