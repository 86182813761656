import styled from 'styled-components';
import imgDefault from '../assets/productImageDefault.png';
import { FaCheckCircle } from 'react-icons/fa';

const ProductCard = ({ name, img, price, mark }) => {
  return (
    <Wrapper className={mark === 'have' && 'container-have'}>
      <div className="img-container">
        <img src={img ? img : imgDefault} alt={name} />
      </div>

      <div className={`product-name ${mark === 'have' && 'product-have'}`}>
        {mark === 'have' && <FaCheckCircle className="have" />}
        <h5>{name}</h5>
      </div>
      <div className="price">
        <p>preço: {price}</p>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.article`
  display: grid;
  grid-template-rows: 300px 30px 30px;
  box-shadow: var(--shadow-2);
  color: var(--primary-700);

  .img-container {
    width: 100%;
  }

  .img-container img {
    width: 100%;
    height: 95%;
    object-fit: cover;
  }

  .price,
  .product-name {
    font-size: 0.8rem;
    text-align: center;
  }

  .product-have {
    display: grid;
    grid-template-columns: 40px 4fr;
    place-items: center;
  }

  .product-name {
    padding-top: 0.3rem;
  }

  h5 {
    font-size: 1rem;
  }

  .have {
    background: transparent;
    color: var(--primary-main);
    width: 1.3rem;
    height: 1.3rem;
  }
`;

export default ProductCard;
